import { useEffect } from 'react'
import { Form, Input } from 'src/components/forms'
import { Button } from 'src/components/primitives/button'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'
import * as S from './candidate-emails-list.styled'

const editEmailSchema = z.object({
  emailAddress: z.string()
})

export type EditEmailSchema = z.infer<typeof editEmailSchema>

interface EditEmailProps {
  defaultValue?: string
  onUpdate: (emailAddress: string) => void
  onCancel: () => void
}

export const EditEmail = ({ defaultValue, onUpdate, onCancel }: EditEmailProps): JSX.Element => {
  const { submit, register, setValue } = useForm<EditEmailSchema>({
    schema: editEmailSchema
  })

  const handleSubmit = (formData: EditEmailSchema): void => {
    if (onUpdate) {
      onUpdate(formData.emailAddress)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setValue('emailAddress', defaultValue)
    }
  }, [defaultValue, setValue])

  return (
    <S.EditEmail>
      <Form onSubmit={submit(handleSubmit)}>
        <Input
          name="emailAddress"
          type="email"
          label="Email Address"
          hiddenLabel
          register={register}
          setFocus
        />
        <Button
          leadingIcon="check"
          $variant="ghost"
          $colorTheme="muted"
          type="submit"
          $height={16}
          $width={16}
          $fontSize={12}
        />
        <Button
          leadingIcon="x"
          $variant="ghost"
          $colorTheme="muted"
          type="button"
          $height={16}
          $width={16}
          $fontSize={12}
          onClick={onCancel}
        />
      </Form>
    </S.EditEmail>
  )
}
