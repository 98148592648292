import { Icons } from 'src/components/primitives/icon'
import * as S from './index.styled'
import { Button } from 'src/components/primitives'
import { CriteriaPill } from './criteria-pill'
import type { CriteriaKey, StandardCriteria } from 'src/libs/api/backend/candidate_search'
import { useCallback, useMemo } from 'react'
import { CriteriaProperties } from '../constants'
import { Dropdown } from 'src/components/primitives/dropdown'

interface MultiSelectProps {
  selectedItems: StandardCriteria[]
  onUpdate: (items: StandardCriteria[]) => void
  criteriaKey: CriteriaKey
  useNegative?: boolean
  useOptional?: boolean
  disabled: boolean
}

export const MultiSelect = ({
  selectedItems,
  onUpdate,
  criteriaKey,
  useNegative = true,
  useOptional = true,
  disabled = false
}: MultiSelectProps): JSX.Element => {
  const { options, type } = useMemo(() => {
    const properties = CriteriaProperties.get(criteriaKey)
    return {
      options: properties?.options as Map<string, string> | undefined,
      type: properties?.type
    }
  }, [criteriaKey])

  const selectedItemsSet = useMemo(() => new Set(selectedItems.map((item) => item.name)), [selectedItems])

  const availableOptions = useMemo(() => {
    const allOptions = Array.from(options?.entries() ?? [])
    return allOptions.filter(([value]) => !selectedItemsSet.has(value))
  }, [options, selectedItemsSet])

  const toggleItem = useCallback((name: string) => {
    if (selectedItemsSet.has(name)) {
      onUpdate(selectedItems.filter((item) => item.name !== name))
    } else {
      onUpdate([...selectedItems, { name, optional: false, negative: false }])
    }
  }, [onUpdate, selectedItems, selectedItemsSet])
  if (selectedItems.length === 0 && availableOptions.length > 0) {
    return (
      <S.MultiSelectWrapper $disabled={disabled}>
        <Dropdown
          $menuWidth='full'
          triggerFullWidth
          disabled={disabled}
          trigger={
            <Button
              nested
              $variant='outline'
              $colorTheme='normal'
              $height={32}
              $fontSize={12}
              $width='full'
              $fontWeight={400}
              trailingIcon={Icons.chevronsUpDownSmall}
              $align='space-between'
              disabled={disabled}
            >
              Select an option
            </Button>
          }
          items={availableOptions.map(([value, title]) => ({
            id: value,
            title,
            onSelect: () => {
              toggleItem(value)
            }
          }))}
        />
      </S.MultiSelectWrapper>
    )
  }
  return (
    <S.MultiSelectWrapper $disabled={disabled}>
      <S.MultiSelectInner>
        {selectedItems.map((item, index) => (
          <CriteriaPill
            key={index}
            value={{
              label: options?.get(item.name) ?? item.name,
              optional: item.optional ?? true,
              negative: item.negative ?? false
            }}
            type={type}
            useNegative={useNegative}
            useOptional={useOptional}
            onUpdate={(key: string, value: boolean | number | null) => {
              const updatedCriteriaValue = [...selectedItems]
              updatedCriteriaValue[index] = {
                ...updatedCriteriaValue[index],
                [key]: value
              }
              onUpdate(updatedCriteriaValue)
            }}
            onRemove={() => {
              const updatedCriteriaValue = selectedItems.filter((_, i) => i !== index)
              onUpdate(updatedCriteriaValue)
            }}
          />
        ))}
        {availableOptions.length > 0 && (
          <Dropdown
            disabled={disabled}
            trigger={
              <Button
                nested
                $variant='flat'
                $colorTheme='tint'
                $fontSize={12}
                $width={22}
                $height={22}
                $fontWeight={400}
                leadingIcon={Icons.plus}
                disabled={disabled}
              />
            }
            items={availableOptions.map(([value, title]) => ({
              id: value,
              title,
              onSelect: () => {
                toggleItem(value)
              }
            }))}
          />
        )}
      </S.MultiSelectInner>
    </S.MultiSelectWrapper>
  )
}
