export const formatPhoneNumber = (phoneNumber: string): string => {
  const digitsOnly = phoneNumber.replace(/\D/g, '')

  if (digitsOnly.length <= 10) {
    return digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')
  }
  const countryCode = digitsOnly.slice(0, -10)
  const phoneNumberOnly = digitsOnly.slice(-10)
  return `+${countryCode} ${phoneNumberOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')}`
}
