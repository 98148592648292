import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { EmailVariables, formatDateExample, formatVariableName } from '../editor/types'
import type { EmailVariableType } from '../editor/types'
import { useMemo } from 'react'

interface EmailVariableButtonProps {
  onAddingVariable: (variable: EmailVariableType) => void
  usePersonalizationInstructions?: boolean
}

export const EmailVariableButton = ({
  onAddingVariable,
  usePersonalizationInstructions = false
}: EmailVariableButtonProps): JSX.Element => {
  const candidateVariables: MenuItemProps[] = useMemo(() => (
    EmailVariables
      .filter((variable: EmailVariableType) => variable.includes('candidate_'))
      .map((variable: EmailVariableType) => ({
        id: variable,
        title: formatVariableName(variable),
        onSelect: () => {
          onAddingVariable(variable)
        }
      }))
  ), [onAddingVariable])

  const senderVariables: MenuItemProps[] = useMemo(() => (
    EmailVariables
      .filter((variable: EmailVariableType) => variable.includes('sender_'))
      .map((variable: EmailVariableType) => ({
        id: variable,
        title: formatVariableName(variable),
        onSelect: () => {
          onAddingVariable(variable)
        }
      }))
  ), [onAddingVariable])

  const dateVariables: MenuItemProps[] = useMemo(() => (
    EmailVariables
      .filter((variable: EmailVariableType) => variable.includes('_date') || variable.includes('day') || variable.includes('month'))
      .map((variable: EmailVariableType) => ({
        id: variable,
        title: formatVariableName(variable) + formatDateExample(variable),
        onSelect: () => {
          onAddingVariable(variable)
        }
      }))
  ), [onAddingVariable])

  const customVariables: MenuItemProps[] = useMemo(() => (
    EmailVariables
      .filter((variable: EmailVariableType) => variable.includes('ai_'))
      .map((variable: EmailVariableType) => ({
        id: variable,
        title: formatVariableName(variable),
        onSelect: () => {
          onAddingVariable(variable)
        },
        isDisabled: !usePersonalizationInstructions
      }))
  ), [onAddingVariable, usePersonalizationInstructions])

  const emailVariableMenuList = useMemo(() => [
    { type: 'sublabel', title: 'Candidate variables' },
    ...candidateVariables,
    { type: 'seperator', title: '' },
    { type: 'sublabel', title: 'Sender variables' },
    ...senderVariables,
    { type: 'seperator', title: '' },
    { type: 'sublabel', title: 'Date variables' },
    ...dateVariables,
    { type: 'seperator', title: '' },
    { type: 'sublabel', title: 'AI variables' },
    ...customVariables
  ], [candidateVariables, senderVariables, dateVariables, customVariables])

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          leadingIcon="variable-add"
          trailingIcon="chevron-down-small"
          $fontSize={12}
        >
          Add variable
        </Button>
      }
      menuPosition="start"
      size="small"
      items={emailVariableMenuList}
      onCloseAutoFocus={(event: Event) => {
        event.preventDefault()
      }}
    />
  )
}
