import { Flex } from 'src/components/primitives'
import { StatsCard } from '../stats-card'
import type { IconName } from 'src/components/primitives/icon'

interface DataGroup {
  icon?: IconName
  label: string
  value: number
  total?: number
}

interface SummaryProps {
  data: DataGroup[]
}

export const Summary = ({ data }: SummaryProps): JSX.Element => {
  const getPercentageValue = ({ value, total }: DataGroup): string => {
    if (total === undefined || total === 0) return '0%'
    return `${Math.round((value / total) * 100)}%`
  }

  return (
    <Flex $align="center" $justify="space-between" $gap={16}>
      {data?.map((item) => (
        <StatsCard
          key={`${item.label}-${item.value}`}
          icon={item.icon ?? 'pie-chart'}
          description={item.label}
          count={item.value}
          change={getPercentageValue(item)}
        />
      ))}
    </Flex>
  )

  // return (
  //   <S.Summary>
  //     {data?.map((item) => (
  //       <S.SummaryCard key={`${item.label}-${item.value}`}>
  //         <S.CardLabel>
  //           <Caption size="SM" $color="fgSecondary">
  //             {item.label}
  //           </Caption>
  //           {item.total !== undefined && (
  //             <S.Badge>
  //               <Caption size="SM" $color="fgSecondary">
  //                 {getPercentageValue(item)}
  //               </Caption>
  //             </S.Badge>
  //           )}
  //         </S.CardLabel>
  //         <S.CardValue>{item.value}</S.CardValue>
  //       </S.SummaryCard>
  //     ))}
  //   </S.Summary>
  // )
}
