import { isNil } from 'lodash'

export const EmailVariables = [
  'candidate_first_name',
  'candidate_name',
  'candidate_email',
  'candidate_current_company',
  'candidate_current_tenure',
  'sender_first_name',
  'sender_name',
  'full_date',
  'short_date',
  'numerical_date',
  'month',
  'weekday',
  'ai_personalized_sentence'
] as const

export type EmailVariableType = typeof EmailVariables[number]

export enum EmailVariable {
  CANDIDATE_FIRST_NAME = 'candidate_first_name',
  CANDIDATE_NAME = 'candidate_name',
  CANDIDATE_EMAIL = 'candidate_email',
  CANDIDATE_CURRENT_COMPANY = 'candidate_current_company',
  CANDIDATE_CURRENT_TENURE = 'candidate_current_tenure',
  SENDER_NAME = 'sender_name',
  SENDER_FIRST_NAME = 'sender_first_name',
  FULL_DATE = 'full_date',
  SHORT_DATE = 'short_date',
  NUMERICAL_DATE = 'numerical_date',
  MONTH = 'month',
  WEEKDAY = 'weekday',
  AI_PERSONALIZED_SENTENCE = 'ai_personalized_sentence'
}

export const MAX_INLINE_IMAGE_SIZE = 0.2 * Math.pow(1024, 2) // 200kb

export const formatVariableName = (variable: EmailVariableType): string => {
  if (variable === EmailVariable.AI_PERSONALIZED_SENTENCE) {
    return 'Custom sentence'
  }
  return variable.replace(/_/g, ' ').replace(/^\w/, (character) => character.toUpperCase())
}

const DATE_FORMAT_MAP: Record<string, string> = {
  full_date: new Date().toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' }),
  short_date: new Date().toLocaleDateString('en-us', { month: 'long', day: 'numeric' }),
  numerical_date: new Date().toLocaleDateString('en-us'),
  month: new Date().toLocaleDateString('en-us', { month: 'long' }),
  weekday: new Date().toLocaleDateString('en-us', { weekday: 'long' })
}

export const formatDateExample = (variable: EmailVariableType): string => {
  if (isNil(DATE_FORMAT_MAP[variable])) {
    return ''
  }

  return ` (${DATE_FORMAT_MAP[variable]})`
}
