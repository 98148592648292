import { CandidateActivityViewMode } from 'src/constants'
import { CandidateActivityFrom } from 'src/libs/api/backend/candidate_activities'
import type { Color } from 'src/styles/theme/types'
import styled, { css } from 'styled-components'

interface TimelineActivityProps {
  $activityViewMode: CandidateActivityViewMode
  $isSequenceMessage?: boolean
  $activityFrom: CandidateActivityFrom
}

interface TimelineActivityInnerProps {
  $hasCollidingContent: boolean
  $hasShadow: boolean
  $isSequenceMessage?: boolean
}

export const TimelineActivity = styled.div<TimelineActivityProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ $activityViewMode }) => `${$activityViewMode === CandidateActivityViewMode.DETAIL ? '95%' : '100%'}`};
  ${({ $activityViewMode, $activityFrom }) => $activityFrom === CandidateActivityFrom.RECRUITER && css`
    align-self: ${$activityViewMode === CandidateActivityViewMode.DETAIL ? 'flex-end' : 'flex-end'};
  `}
  ${({ $isSequenceMessage }) => $isSequenceMessage && css`
    border: solid 1px;
    border-color: ${({ theme }) => theme.colors.fgTranslucent5};
    border-radius: 6px;
  `}
`

export const TimelineActivityInner = styled.div<TimelineActivityInnerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ $hasCollidingContent }) => $hasCollidingContent && css`
    border-radius: 6px;
    overflow: hidden;
  `}
  ${({ $hasShadow }) => $hasShadow && css`
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  `}
  ${({ $isSequenceMessage }) => $isSequenceMessage && css`
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  `}
`

export const TimelineActivityHeader = styled.div<{ $hasCollidingContent: boolean, $bg: Color, $isSequenceMessage?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ theme, $bg }) => theme.colors[$bg ?? 'fgTranslucent5']};
  height: 2rem;
  padding: 0.25rem 0.375rem 0.25rem 0.7rem;
  border-radius: 0.375rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  ${({ $hasCollidingContent }) => $hasCollidingContent && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  ${({ $isSequenceMessage }) => $isSequenceMessage && css`
    background-color: ${({ theme }) => theme.colors.bgTranslucent10};
    border-color: ${({ theme }) => theme.colors.fgTranslucent5};
  `}
`

export const OutreachEmailHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.fgTranslucent5};
  padding: 0.5rem 0.375rem 0.5rem 0.7rem;
`

export const Content = styled.div<{ $columns: string }>`
  grid-column: ${({ $columns }) => $columns};
`

export const Indicator = styled.div`
  position: relative;
  width: 1rem;
  min-height: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 2;
`

const indicatorStyles = css`
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: ${({ theme }) => theme.colors.bgSecondary}; */
  padding: 0.4rem;
  transform: translateX(-0.1rem);
  z-index: 3;
`

export const IndicatorPrimary = styled.div`
  ${indicatorStyles}
  padding: 0;
  height: 16px;
`

export const IndicatorSecondary = styled.div`
  ${indicatorStyles}
  height: 1.4rem;
`

export const Timestamp = styled.div`
  display: flex;
  justify-content: flex-end;
  /* padding-right: 0.75rem; */
  * {
    font-size: ${({ theme }) => theme.fontSizes['10']};
    color: ${({ theme }) => theme.colors.fgTertiary};
    line-height: 1;
  }
`
