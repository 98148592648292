import { infiniteQueryOptions, keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import type { UseInfiniteQueryResult } from '@tanstack/react-query'
import { fetchCandidateSequenceStepMessageReviews } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import type { CandidateSequenceStepMessageReviewExtended } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  jobId: string
}

export interface CandidateSequenceStepMessageReviewQueryResult {
  pages: CandidateSequenceStepMessageReviewExtended[][]
  pageParams: number[]
}

export const candidateSequenceStepMessageReviewsQuery = (jobId: string) => infiniteQueryOptions({
  queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId],
  queryFn: async ({ pageParam }) => await fetchCandidateSequenceStepMessageReviews(jobId, { page: pageParam, limit: 25 }),
  initialPageParam: 0,
  getNextPageParam: (lastPage, _, lastPageParam) => {
    if (lastPage.length === 0) {
      return undefined
    }
    return lastPageParam + 1
  },
  staleTime: 60 * 1000,
  enabled: !!jobId,
  placeholderData: keepPreviousData
})

export const useCandidateSequenceStepMessageReviews = ({ jobId }: Args): UseInfiniteQueryResult => {
  return useInfiniteQuery(candidateSequenceStepMessageReviewsQuery(jobId))
}
