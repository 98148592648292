import { useSetAtom } from 'jotai'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { useTriggerPhoneNumberLookup } from 'src/hooks/mutations/use-trigger-phone-number-lookup'
import { useCanTriggerPhoneNumberLookup } from 'src/hooks/use-available-credits'
import { useSession } from 'src/hooks/use-session'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'

interface PhoneNumberLookupButtonProps {
  candidateJob: CandidateJobExpanded
  inProgress: boolean
  setInProgress: (inProgress: boolean) => void
}

export const PhoneNumberLookupButton = ({ candidateJob, inProgress, setInProgress }: PhoneNumberLookupButtonProps): JSX.Element => {
  const { featureFlags } = useSession()
  const openDialog = useSetAtom(openDialogAtom)
  const { triggerPhoneNumberLookup } = useTriggerPhoneNumberLookup()

  const canTriggerLookup = useCanTriggerPhoneNumberLookup()

  const lookupPhoneNumberEnabled = useMemo(() => {
    return (
      featureFlags?.includes(FeatureFlags.PHONE_NUMBER_LOOKUP) &&
      isNil(candidateJob?.candidate.phoneNumberLookupStage)
    )
  }, [featureFlags, candidateJob?.candidate.phoneNumberLookupStage])

  if (!lookupPhoneNumberEnabled || inProgress) {
    return <></>
  }
  return (
    <Button
      $variant="outline"
      $colorTheme='muted'
      $height={24}
      $width='fit-content'
      $fontSize={12}
      onClick={() => {
        if (canTriggerLookup) {
          setInProgress(true)
          triggerPhoneNumberLookup({
            candidateId: candidateJob.candidate.id,
            onError: () => {
              setInProgress(false)
            }
          })
        } else {
          openDialog({ id: DialogId.INSUFFICIENT_CREDITS })
        }
      }}
    >
      Lookup Phone Number
    </Button>
  )
}
