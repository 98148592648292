import { UserRole } from 'src/libs/api/backend/users'
import type { OrgUser } from 'src/libs/api/backend/users'
import * as OL from 'src/components/forms/options-list/options-list.styled'
import { Icons } from '../primitives/icon'
import { Tooltip } from '../primitives/tooltip'
import { Avatar } from 'src/components/primitives/avatar'
import { Button, Flex } from '../primitives'
import { Dropdown } from '../primitives/dropdown'
import { useMemo, useState } from 'react'
import { ArchiveUserConfirmation } from './archive-user-confirmation'
import { TransferArchiveUserConfirmation } from './transfer-archive-user-confirmation'
import type { DeleteUserArgs } from 'src/hooks/mutations/use-delete-user'
import { AnimatePresence } from 'framer-motion'

interface TeamMemberRowProps {
  orgUser: OrgUser
  orgUsers: OrgUser[]
  deleteUser: (args: DeleteUserArgs) => void
  restoreUser: ({ userId }: { userId: string }) => void
  updateUserRole: ({ userId, role }: { userId: string, role: UserRole }) => void
  currentUser: boolean
}

export const RoleOptions = [
  { title: 'Admin', value: UserRole.ADMIN },
  { title: 'Member', value: UserRole.MEMBER },
  { title: 'Viewer', value: UserRole.VIEWER }
]

export const TeamMemberRow = ({ orgUser, currentUser, deleteUser, restoreUser, orgUsers, updateUserRole }: TeamMemberRowProps): JSX.Element => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openTransferConfirmation, setOpenTransferConfirmation] = useState(false)
  const isUserUsedInJobIds = useMemo(() => {
    return orgUser.emailAccounts.some((emailAccount) => emailAccount.usedInJobIds.length > 0)
  }, [orgUser.emailAccounts])
  return (
    <OL.Option>
      <OL.OptionUserDetails>
        <OL.OptionAvatar>
          <Avatar $type="photo" initials={orgUser.name} photoUrl={orgUser.profilePhotoUrl} />
        </OL.OptionAvatar>
        <OL.OptionLabel>
          {orgUser.emailAccounts.length ? <p>{(orgUser.emailAccounts.find(account => account.isPrimary) ?? orgUser.emailAccounts[0]).email}</p> : null}
          <p>{orgUser.name}</p>
        </OL.OptionLabel>
        <Flex $align="center" $justify="flex-end" $gap={12}>
          {orgUser.active
            ? (
                <>
                  <Dropdown
                    trigger={
                      <Button
                        $variant="raised"
                        // $colorTheme="muted"
                        $height={24}
                        $fontSize={12}
                        trailingIcon="chevrons-up-down-small"
                      >
                        {RoleOptions.find(option => option.value === orgUser.role)?.title}
                      </Button>
                    }
                    items={RoleOptions.map((option) => ({
                      id: option.value,
                      title: option.title,
                      value: option.value,
                      onSelect: () => {
                        updateUserRole({
                          userId: orgUser.id,
                          role: option.value
                        })
                      }
                    }))}
                    selectedValue={orgUser.role}
                    size="small"
                  />
                  <Tooltip
                    triggerDisabled={currentUser}
                    trigger={
                      <span>
                        <Button
                          ariaLabel="Disable user"
                          leadingIcon={Icons.archive}
                          $variant={openConfirmation ? 'outline' : 'ghost'}
                          $colorTheme='muted'
                          $height={24}
                          $width={24}
                          $fontSize={12}
                          disabled={currentUser}
                          $disabled={currentUser}
                          onClick={() => {
                            if (isUserUsedInJobIds) {
                              setOpenTransferConfirmation(!openTransferConfirmation)
                            } else {
                              setOpenConfirmation(!openConfirmation)
                            }
                          }}
                        />
                      </span>
                    }
                  >
                    {currentUser ? 'You cannot disable yourself' : 'Disable user'}
                  </Tooltip>
                </>
              )
            : (
                <Tooltip
                  trigger={
                    <span>
                      <Button
                        ariaLabel="Restore user"
                        leadingIcon={Icons.archiveRestore}
                        $variant='ghost'
                        $colorTheme='muted'
                        $height={24}
                        $width={24}
                        $fontSize={12}
                        onClick={() => {
                          restoreUser({
                            userId: orgUser.id
                          })
                        }}
                      />
                    </span>
                  }
                >
                  Restore user
                </Tooltip>
              )
          }
        </Flex>
      </OL.OptionUserDetails>
      <AnimatePresence>
        {openConfirmation && (
          <ArchiveUserConfirmation
            user={orgUser.name}
            onClick={() => {
              deleteUser({
                userId: orgUser.id
              })
              setOpenConfirmation(false)
            }}
            onCancel={() => {
              setOpenConfirmation(false)
            }}
          />
        )}
        {openTransferConfirmation && (
          <TransferArchiveUserConfirmation
            onClick={(selectedEmailAccountId: string) => {
              deleteUser({
                userId: orgUser.id,
                updatedAccountId: selectedEmailAccountId
              })
              setOpenTransferConfirmation(false)
            }}
            onCancel={() => { setOpenTransferConfirmation(false) }}
            orgUser={orgUser}
            orgUsers={orgUsers}
          />
        )}
      </AnimatePresence>
    </OL.Option>
  )
}
