import { useMemo } from 'react'
import { CandidatesTable } from '../candidates-table'
import { RejectButton } from '../candidate-table-actions'
import { COLUMN } from '../candidate-table-cells/table-builder'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Caption } from 'src/components/primitives/typography'
import * as S from './candidates-sourced-table.styled'
import { isEmpty, isNil } from 'lodash'
import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { useAtom } from 'jotai'
import { isSourcingTableExpandedAtom } from 'src/stores/job-refinement'
interface CandidatesSourcedTableProps {
  candidateJobs: CandidateJobExpanded[]
  visibleColumns: COLUMN[]
  pageHeaderHeight?: number
}

const AVERAGE_CHARACTER_WIDTH = 5.1

const EXTENDED_MAX_TABLE_WIDTH = 1516

export const CandidatesSourcedTable = ({
  candidateJobs,
  visibleColumns,
  pageHeaderHeight
}: CandidatesSourcedTableProps): JSX.Element => {
  const [tableIsExpanded, setTableIsExpanded] = useAtom(isSourcingTableExpandedAtom)
  const criteriaMatches = useMemo(() => {
    return candidateJobs?.[0]?.sourcingScores?.criteria_matches_array ?? []
  }, [candidateJobs])

  const longestShortNameCharacterCount = useMemo(() => {
    return criteriaMatches.reduce((longest, current) => {
      const currentLength = current?.shortName?.length ?? 0
      const longestLength = longest.length
      return currentLength > longestLength
        ? { item: current, length: currentLength }
        : longest
    }, { item: criteriaMatches[0], length: criteriaMatches[0]?.shortName?.length ?? 0 })?.length ?? 20
  }, [criteriaMatches])

  const customColumnDefinitions = useMemo(() => {
    if (visibleColumns.includes(COLUMN.CRITERIA_EXPANDED)) {
      const hasCriteriaMatches = criteriaMatches.every(criteria => criteria.shortName)
      const height = longestShortNameCharacterCount * AVERAGE_CHARACTER_WIDTH + 40 // for top and bottom padding
      return [{
        columnId: COLUMN.CRITERIA,
        dependentExpandableColumnId: COLUMN.CRITERIA_EXPANDED
      }, {
        columnId: COLUMN.CRITERIA_EXPANDED,
        size: 'minmax(min-content, max-content)',
        customizedHeader: true,
        customizedCell: true,
        header: hasCriteriaMatches
          ? <S.CriteriaExpandedHeader
              colSpan={1}
              $height={hasCriteriaMatches ? `${height}px` : '100%'}
            >
              {criteriaMatches.map((criteria) => (
                <S.RotatedCaption key={criteria.description}>
                  <S.RotatedCaptionInner>
                  <Caption size="XS" $whiteSpace="nowrap">
                      {criteria.shortName}
                    </Caption>
                  </S.RotatedCaptionInner>
                </S.RotatedCaption>
              ))}
            </S.CriteriaExpandedHeader>
          : <S.CriteriaExpandedHeaderEmpty>&nbsp;</S.CriteriaExpandedHeaderEmpty>
      }]
    }
    return undefined
  }, [criteriaMatches, longestShortNameCharacterCount, visibleColumns])

  const anyColumnExpanded = useMemo(() => {
    if (!visibleColumns.includes(COLUMN.CRITERIA_EXPANDED)) {
      return false
    }
    if (isNil(tableIsExpanded) || isEmpty(tableIsExpanded)) {
      return true
    }
    return Object.values(tableIsExpanded).some(Boolean)
  }, [tableIsExpanded, visibleColumns])

  return (
    <div
      data-component="candidates-sourced-table"
      style={{
        width: anyColumnExpanded ? 'fit-content' : '100%',
        height: '100%',
        overflowX: 'auto',
        maxWidth: anyColumnExpanded ? `min(100%, ${EXTENDED_MAX_TABLE_WIDTH}px)` : CANDIDATES_PAGES_MAX_WIDTH,
        padding: '2px 2px 4px 2px'
      }}
    >
      <CandidatesTable
        pageHeaderHeight={pageHeaderHeight}
        visibleColumns={visibleColumns}
        candidateJobs={candidateJobs}
        emptyState={
          <></>
        }
        selectedRowsActions={
          <>
            <RejectButton />
          </>
        }
        customColumnDefinitions={customColumnDefinitions}
        onToggleExpandableColumn={(columnId: string) => {
          if (visibleColumns.includes(columnId as COLUMN)) {
            setTableIsExpanded(prev => ({
              ...prev,
              [columnId]: isNil(prev[columnId]) ? false : !prev[columnId] // Undefined means expanded by default --> set to false on toggle
            }))
          }
        }}
      />
    </div>
  )
}
