import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { LogoSymbol } from 'src/components/primitives/logo'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { sanitizeRefinementCriteria } from 'src/utils/refinement-criteria'
import * as S from './tweak-search-refinement-criteria-banner.styled'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { When } from '../when'

export interface TweakSearchRefinementCriteriaBannerStyledProps {
  $padding?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  $contentAlign?: 'flex-start' | 'center' | 'flex-end'
}

export interface TweakSearchRefinementProps {
  onUpdateSearchRefinementCriteria?: (criteria: Criteria) => void
}

interface TweakSearchRefinementCriteriaBannerProps extends TweakSearchRefinementProps, TweakSearchRefinementCriteriaBannerStyledProps {
  pin?: 'normal' | 'sad' | 'happy' | 'searching' | null
  heading?: string
  subheading?: string
  formheading?: string
}

export const TweakSearchRefinementCriteriaBanner = ({
  pin,
  heading = 'You reached the end of the list',
  subheading,
  formheading,
  onUpdateSearchRefinementCriteria,
  $padding = { top: 64, bottom: 64, left: 40, right: 120 },
  $contentAlign = 'flex-start'
}: TweakSearchRefinementCriteriaBannerProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: jobSearchRefinements } = useListJobSearchRefinementsQuery(jobId)
  const [selectedRadiusInMiles, setSelectedRadiusInMiles] = useState<number | null>(null)

  const currentCriteria = useMemo(() => {
    return sanitizeRefinementCriteria(jobSearchRefinements?.[0]?.searchCriteria)
  }, [jobSearchRefinements])

  const radiusInMilesOptions = [5, 10, 20, 50]

  const radiusInMilesDropdownItems: MenuItemProps[] = useMemo(() => {
    const currentRadius = currentCriteria.locations?.[0]?.radiusInMiles ?? 0
    return radiusInMilesOptions
      .filter(miles => miles > currentRadius)
      .map(miles => ({
        title: `Within ${miles} miles`,
        value: miles.toString(),
        onSelect: () => { setSelectedRadiusInMiles(miles) }
      }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCriteria.locations])

  const getNextAvailableRadius = (currentRadius: number): number | null => {
    const nextRadius = radiusInMilesOptions.find(radius => radius > currentRadius)
    return nextRadius ?? null
  }

  useEffect(() => {
    const currentRadius = currentCriteria.locations?.[0]?.radiusInMiles ?? 0
    const nextRadius = getNextAvailableRadius(currentRadius)
    setSelectedRadiusInMiles(nextRadius)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCriteria.locations])

  const handleCriteriaUpdate = (): void => {
    const updatedCriteria = {
      ...currentCriteria,
      locations: [
        {
          ...currentCriteria.locations?.[0],
          radiusInMiles: selectedRadiusInMiles
        }
      ]
    }
    const sanitizedCriteria = sanitizeRefinementCriteria(updatedCriteria)
    onUpdateSearchRefinementCriteria?.(sanitizedCriteria)
  }

  const showLocationCriteriaUpdateForm = useMemo(() => {
    const currentRadius = currentCriteria.locations?.[0]?.radiusInMiles ?? 0
    return Boolean(currentCriteria.locations) && currentRadius < 50
  }, [currentCriteria.locations])

  return (
    <S.Wrapper $columns={pin ? '40px 1fr' : '1fr'} $padding={$padding}>
      {pin && (
        <S.Icon>
          <LogoSymbol variant="normal" width={40} height={40} />
        </S.Icon>
      )}
      <S.Content>
        <Flex $width="100%" $direction="column" $gap={2} $align={$contentAlign}>
          <Caption as="h4" size="MD" $color="fgPrimary">{heading}</Caption>
          {subheading && <Paragraph size="SM" $color="fgSecondary">{subheading}</Paragraph>}
        </Flex>
        <When condition={showLocationCriteriaUpdateForm}>
          <Flex $direction="column" $gap={12} $width="100%" $align={$contentAlign}>
            {formheading && (
              <Caption size="SM" $color="fgPrimary" as="h5">
                {formheading}
              </Caption>
            )}
            <S.CriteriaBox>
              <Flex $align="flex-start" $width="auto">
                <Paragraph size="SM" $color="fgPrimary">Location</Paragraph>
              </Flex>
              <Flex $align="center" $gap={8}>
                <Icon name={Icons.arrowRight} size={12} color="fgSecondary" />
                {
                  radiusInMilesDropdownItems.length > 1
                    ? (
                      <Dropdown
                        $minWidth="12rem"
                        side="bottom"
                        trigger={
                          <Button $variant="ghost" $height={24} trailingIcon={Icons.chevronDownSmall}>
                            {selectedRadiusInMiles ? `+${selectedRadiusInMiles} miles` : '+ miles'}
                          </Button>
                        }
                        items={radiusInMilesDropdownItems}
                      />
                      )
                    : (
                        <Paragraph size="SM" $color="fgPrimary">{selectedRadiusInMiles ? `+${selectedRadiusInMiles} miles` : '+ miles'}</Paragraph>
                      )
                }
              </Flex>
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                $width={110}
                $align="center"
                onClick={handleCriteriaUpdate}
              >
                Update Criteria
              </Button>
            </S.CriteriaBox>
          </Flex>
        </When>
      </S.Content>
    </S.Wrapper>
  )
}
