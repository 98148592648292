import * as Dialog from 'src/components/primitives/dialog'
import { useAtomValue, useSetAtom } from 'jotai'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useMemo } from 'react'
import { useSession } from 'src/hooks/use-session'
import { Flex } from 'src/components/primitives/flex'
import { SubscriptionSettings } from 'src/components/blocks/subscription-settings'
import { Paragraph } from 'src/components/primitives/typography'
import { differenceInDays } from 'date-fns'

export const InsufficientCreditsDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.INSUFFICIENT_CREDITS), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { org, subscribed, trialActive } = useSession()

  return (
    <Dialog.Root
      id={DialogId.INSUFFICIENT_CREDITS}
      isOpen={isDialogOpen}
      onOpenChange={(value) => { controlDialog({ id: DialogId.INSUFFICIENT_CREDITS, newState: value }) }}
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Insufficient Credits"
          onClose={() => { controlDialog({ id: DialogId.INSUFFICIENT_CREDITS, newState: false }) }}
        />
        <Dialog.Content>
          <SubscriptionSettings
            notificationBanner={
              !subscribed && trialActive && org?.trialActiveUntil
                ? (
                    <Flex $direction='column'>
                      <Paragraph size='SM' $fontWeight={500}>You’re currently in the the free trial period with {differenceInDays(org.trialActiveUntil, new Date())} days left.</Paragraph>
                      <Paragraph size='SM'>Subscribe today to avoid disruption.</Paragraph>
                    </Flex>
                  )
                : null
            }
            insufficientCredits={subscribed ?? false}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
