import { EmptyState } from '../empty-state'
import RouteBuilder from 'src/libs/route-builder'
import { formatDate } from 'src/utils/format-date'
import { useJobQuery } from 'src/hooks/queries/use-job'

export const EmptyStateArchivedJob = (): JSX.Element => {
  const { data: job } = useJobQuery()

  console.log('job: ', job)

  return (
    <EmptyState
      heading="This job is archived"
      description={`This job was archived on ${formatDate(job?.deletedAt ?? '')}`}
      svg="archiveBox"
      actions={[
        {
          children: 'View archived candidates',
          href: RouteBuilder.build('JOBS_CANDIDATES_ALL_ARCHIVED', { jobId: job?.id ?? '' })
        }
      ]}
    />
  )
}
