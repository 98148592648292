import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { triggerPhoneNumberLookupApi } from 'src/libs/api/backend/candidate_jobs'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  candidateId: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  triggerPhoneNumberLookup: ({ candidateId, onSuccess, onError }: Args) => void
}

export const useTriggerPhoneNumberLookup = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const mutation = useMutation({
    mutationFn: async ({ candidateId }: Args) => await triggerPhoneNumberLookupApi(candidateId),
    onSuccess: (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJob, variables.candidateId]
      })
    },
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: 'An error occurred while looking up candidate phone number'
      })
      if (variables.onError) {
        variables.onError()
      }
    }
  })

  const triggerPhoneNumberLookup = ({ candidateId, onSuccess, onError }: Args): void => {
    mutation.mutate({ candidateId, onSuccess, onError })
  }

  return { triggerPhoneNumberLookup }
}
