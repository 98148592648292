import styled, { css } from 'styled-components'

export const ReadyToSendContainer = styled.div<{ $hasError: boolean }>`
  border-radius: ${({ theme }) => theme.spacing[6]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  margin-bottom: 18px;
  ${({ $hasError, theme }) => $hasError && css`
    border: 1px solid;
    border-color: ${theme.colors.negativeTranslucent25};
  `}
`

export const ReadyToSendHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[12]};
  align-self: stretch;
  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[10]}`};
  padding-right: ${({ theme }) => theme.spacing[16]};
`

export const ReadyToSendProfile = styled.div`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing[4]} 0`};
  flex: 1 1 0;
  display: flex;
  &:hover {
    cursor: pointer;
    &:after {
      opacity: 1;
    }
  }
  * {
    z-index: 2;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: calc(100% + 4px);
    height: 100%;
    transform: translateX(-4px);
    background-color: ${({ theme }) => theme.colors.fgTranslucent5};
    border-radius: ${({ theme }) => theme.spacing[6]};
    opacity: 0;
    z-index: 1;
  }
`

export const ReadyToSendSender = styled.div`
  display: grid;
  grid-template-columns: 3.25rem 1fr;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing[12]}`};
  min-height: 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.fgTranslucent5};
  border-bottom: 1px solid ${({ theme }) => theme.colors.fgTranslucent5};
  width: 100%;
`

export const ReadyToSendSubject = styled.div`
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[12]};
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.colors.fgTranslucent5};

  input {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    border: none;
    color: ${({ theme }) => theme.colors.fgPrimary};
    outline: none;
  }
`

export const ReadyToSendBody = styled.div`
  width: 100%;
  height: 100%;
`

export const ReadyToSendToolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  gap: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
`

export const ErrorHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[12]};
  align-self: stretch;
  padding: 0.5rem 0.75rem;
  min-height: 2rem;
  border-top-left-radius: ${({ theme }) => theme.spacing[6]};
  border-top-right-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.negativeTranslucent5};
`

export const Inner = styled.div`
  width: 100%;
`
