import queryClient from 'src/hooks/query-client'
import { listJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { redirect } from 'react-router-dom'
import RouteBuilder from '../route-builder'

export const sourcingLoader = async ({ params: { jobId, jobSearchRefinementId }, request }: { params: { jobId: string, jobSearchRefinementId?: string }, request: Request }): Promise<Response | null> => {
  const searchParams = new URL(request.url).searchParams
  const jobSearchRefinementIdParam = searchParams.get('jobSearchRefinementId')
  if (jobSearchRefinementIdParam) {
    return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinementIdParam }))
  }
  const jobSearchRefinements = await queryClient.ensureQueryData(listJobSearchRefinementsQuery(jobId))
  if (!jobSearchRefinementId) {
    if (jobSearchRefinements.length > 0) {
      return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinements[0].id }))
    } else {
      return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SEARCH', { jobId }))
    }
  }
  return null
}
