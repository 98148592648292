import { Flex, Spacer } from 'src/components/primitives'
import { Button } from 'src/components/primitives/button/button'
import * as Dialog from 'src/components/primitives/dialog'
import { useEffect, useMemo, useState } from 'react'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { Form } from 'src/components/forms/form'
import { Input } from 'src/components/forms/input'
import { useForm } from 'src/hooks/use-form'
import { Checkbox } from 'src/components/forms/checkbox'
import * as S from './export-candidates-options-dialog.styled'
import { useExportCandidatesToCsv } from 'src/hooks/use-export-candidates-to-csv'
import { CandidateExportFileType, exportCandidateJobsToCsvOptionsSchema } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded, ExportCandidateJobsToCsvOptions } from 'src/libs/api/backend/candidate_jobs'

interface ExportCandidatesOptionsDialogProps {
  candidateJobs?: CandidateJobExpanded[]
  fileType?: CandidateExportFileType
}

export const ExportCandidatesOptionsDialog = ({ candidateJobs, fileType = CandidateExportFileType.CSV }: ExportCandidatesOptionsDialogProps): JSX.Element => {
  const [isExporting, setIsExporting] = useState(false)
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.EXPORT_CANDIDATES_TO_FILE_OPTIONS), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { exportCandidates } = useExportCandidatesToCsv()

  const { submit, register, formData, setValue } = useForm<ExportCandidateJobsToCsvOptions>({
    schema: exportCandidateJobsToCsvOptionsSchema
  })

  const handleSubmit = async (): Promise<void> => {
    if (fileType === CandidateExportFileType.CSV) {
      setIsExporting(true)
      await exportCandidates()
      setIsExporting(false)
    }
  }

  useEffect(() => {
    setValue('numberOfCandidates', candidateJobs?.length ?? 40)
    setValue('includeEmailAddresses', true)
    setValue('includePhoneNumbers', false)
  }, [candidateJobs])

  const exportTo = fileType === CandidateExportFileType.CSV ? 'CSV' : 'PDF'

  return (
    <Dialog.Root
      id={DialogId.EXPORT_CANDIDATES_TO_FILE_OPTIONS}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.EXPORT_CANDIDATES_TO_FILE_OPTIONS, newState: value })
      }}
      $width="half"
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title={`Export candidates to ${exportTo}`}
          onClose={() => {
            controlDialog({ id: DialogId.EXPORT_CANDIDATES_TO_FILE_OPTIONS, newState: false })
          }}
        />
        <Dialog.Content>
          <Form onSubmit={submit(handleSubmit)}>
            <Input
              name="numberOfCandidates"
              type="number"
              max={candidateJobs?.length}
              label="How many candidates do you want to export from this search?"
              register={register}
            />
            <S.CheckboxCard $isChecked={Boolean(formData.includeEmailAddress)}>
              <Checkbox
                name="includeEmailAddresses"
                register={register}
                label="Include email addresses"
                description="Counts towards your email lookup limit."
                defaultChecked={true}
                $marginBottom={0}
              />
            </S.CheckboxCard>
            {/* <Spacer $size={12} />
            <S.CheckboxCard $isChecked={Boolean(formData.includePhoneNumber)}>
              <Checkbox
                name="includePhoneNumbers"
                register={register}
                label="Include phone numbers"
                description="Counts towards your phone lookup limit."
                defaultChecked={false}
                $marginBottom={0}
              />
            </S.CheckboxCard> */}
            <Spacer $size={24} />
            <Flex $gap={16}>
              <Button type="submit" $variant="fill" $colorTheme="tint" $height={40} loading={isExporting}>
                Export Candidates
              </Button>
              <Button
                $variant="outline"
                $colorTheme="normal"
                $height={40}
                onClick={() => {
                  controlDialog({ id: DialogId.EXPORT_CANDIDATES_TO_FILE_OPTIONS, newState: false })
                }}
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
