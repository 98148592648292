import { PageHeader } from 'src/components/blocks/page-header'
// import { Dropdown } from 'src/components/primitives/dropdown'
// import { Button } from 'src/components/primitives/button'
// import { BarChart } from 'src/components/charts/bar-chart'
import { AreaChart } from 'src/components/charts/area-chart'
import { ChartWrapper } from 'src/components/charts/chart-wrapper'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Summary } from 'src/components/charts/summary'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { useJobReportQuery } from 'src/hooks/queries/use-job-report'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { EmptyState } from 'src/components/blocks/empty-state'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { TileChart } from 'src/components/charts/tile-chart'
import { SEO } from 'src/components/primitives/seo'
import { ReportFeedbackBanner } from 'src/components/blocks/report-feedback-banner'
import * as S from './candidates.styled'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import type { SequenceStepEngagementMetrics } from 'src/libs/api/backend/reports'
import { Table } from 'src/components/primitives/table'
import { EmptyChart } from 'src/components/charts/empty-chart'
import { TableReportNumberCell, DefaultTableHeader } from './report-components'

const mapSubjects = (input: Array<{ subject?: string | null | undefined }>): string[] => {
  let lastSubject = ''
  let repeatCount = 0
  return input.map((item) => {
    if (item.subject) {
      lastSubject = item.subject
      repeatCount = 0
      return item.subject
    } else {
      let prefix = ''
      for (let i = 0; i <= repeatCount; i++) {
        prefix += 'Re: '
      }
      repeatCount++
      return prefix + lastSubject
    }
  })
}

interface SequenceStepEngagementMetricsWithId extends SequenceStepEngagementMetrics {
  id: string
}

const ReportsCandidatesPage = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: candidateJobCounts, isLoading } = useCandidateJobCountsQuery()
  const { emailStats, sequenceEmailsSentByOrg } = candidateJobCounts ?? {}
  const { openedSum, respondedSum, totalSum } = emailStats ?? {}

  const { data: jobReport } = useJobReportQuery()

  const { emailMessageCounts, sequenceStepEngagementMetrics, bestTimeToSend } = jobReport ?? {}

  const subjects = mapSubjects(sequenceStepEngagementMetrics ?? [])
  const tableData = useMemo(() => (sequenceStepEngagementMetrics ?? []).map((item, index) => ({
    ...item,
    id: index.toString()
  })), [sequenceStepEngagementMetrics])

  const sequenceTableColumns = useMemo((): Array<TableColumnDef<SequenceStepEngagementMetricsWithId>> => {
    const columnTotalCounts: Record<string, number> = {}
    tableData.forEach((row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (typeof value === 'number') {
          columnTotalCounts[key] = (columnTotalCounts[key] || 0) + value
        }
      })
    })

    const defaultProps = {
      visible: true,
      size: 'minmax(min-content, 1fr)'
    }
    return [{
      ...defaultProps,
      columnId: 'sequenceStep',
      size: 'minmax(min-content, 4fr)',
      customizedHeader: true,
      cell: (info: SequenceStepEngagementMetricsWithId, index: number) => (
        <Flex $direction="column" $gap={2} $justify="flex-start">
          <Caption size="XS">{subjects[index]}</Caption>
          <Paragraph size="XS" $color="fgTertiary">
            {info.email}
          </Paragraph>
        </Flex>
      ),
      header: <th><DefaultTableHeader title="By outreach step" $justify="flex-start"/></th>
    }, {
      ...defaultProps,
      columnId: 'sent',
      cell: (info: SequenceStepEngagementMetricsWithId) => (
        <TableReportNumberCell
          value={info.sent}
          percentage={columnTotalCounts.sent ? info.sent / columnTotalCounts.sent : 0}
        />
      ),
      header: <DefaultTableHeader title="Sent" />
    }, {
      ...defaultProps,
      columnId: 'opened',
      cell: (info: SequenceStepEngagementMetricsWithId) => (
        <TableReportNumberCell
          value={info.opened}
          percentage={columnTotalCounts.opened ? info.opened / columnTotalCounts.opened : 0}
        />
      ),
      header: <DefaultTableHeader title="Opened" />
    }, {
      ...defaultProps,
      columnId: 'replied',
      cell: (info: SequenceStepEngagementMetricsWithId) => (
        <TableReportNumberCell
          value={info.replied}
          percentage={columnTotalCounts.replied ? info.replied / columnTotalCounts.replied : 0}
        />
      ),
      header: <DefaultTableHeader title="Replied" />
    }]
  }, [subjects, tableData])

  const emailsPerDayData = useMemo(() => (
    emailMessageCounts?.map((email) => {
      return {
        label: email.friendlyDate,
        Sent: email.sent,
        Received: email.received
      }
    }) ?? []
  ), [emailMessageCounts])

  const isEmpty = useMemo(
    () =>
      !sequenceEmailsSentByOrg?.totalSum &&
      !totalSum &&
      !sequenceStepEngagementMetrics?.length &&
      !emailMessageCounts?.length &&
      (!bestTimeToSend?.data?.length || bestTimeToSend?.data.every((item) => item.count === 0)),
    [
      sequenceEmailsSentByOrg,
      totalSum,
      sequenceStepEngagementMetrics,
      emailMessageCounts,
      bestTimeToSend
    ]
  )

  const header = useMemo(
    () => (
      <PageHeader
        heading="Reports · Communications"
      />
    ),
    []
  )

  if (isLoading) {
    return (<LoadingSkeleton $variant="CandidateDetailsCard" />)
  }

  if (isEmpty && !isLoading) {
    return (
      <Flex $direction="column" $height="full">
        {header}
        <EmptyState
          heading="No data to report yet"
          description="As you start communicating with candidates we'll generate a report for you"
          svg="report"
          $borderRadius="6px"
          $padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          actions={[
            {
              href: `/jobs/${jobId}/candidates/sourcing`,
              children: 'Go to sourcing'
            }
          ]}
        />
      </Flex>
    )
  }

  return (
    <>
      <SEO title='Communications Reports' />
      {header}
      <S.ReportsWrapper>
        <ReportFeedbackBanner />
        <ChartWrapper $variant="solid">
          <Summary
            data={[
              {
                icon: 'mail-arrow-right',
                label: 'Emails sent',
                value: sequenceEmailsSentByOrg?.totalSum ?? 0
              },
              {
                icon: 'mail-open',
                label: 'Opened',
                value: openedSum ?? 0,
                total: totalSum ?? 0
              },
              // {
              //   label: 'Clicked',
              //   value: clickedEmails?.clickedSum ?? 0,
              //   total: clickedEmails?.totalSum ?? 0
              // },
              {
                icon: 'mail-arrow-left',
                label: 'Replied',
                value: respondedSum ?? 0,
                total: totalSum ?? 0
              }
            ]}
          />
        </ChartWrapper>
        <ChartWrapper>
          <Table<SequenceStepEngagementMetricsWithId>
            columns={sequenceTableColumns}
            tableData={tableData}
            emptyState={<EmptyChart $minHeight='80px' />}
          />
        </ChartWrapper>
        <ChartWrapper>
          <AreaChart chartTitle="Emails per day" data={emailsPerDayData} />
        </ChartWrapper>
        <ChartWrapper>
          <TileChart
            chartTitle="Email opens by time sent"
            data={bestTimeToSend?.data ?? []}
          />
        </ChartWrapper>
      </S.ReportsWrapper>
    </>
  )
}

export default ReportsCandidatesPage
