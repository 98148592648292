import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { BenefitsList } from 'src/components/blocks/benefits-list'
import { When } from 'src/components/blocks/when'
import { CONNECTED_ACCOUNTS_BENEFITS } from 'src/libs/data'
import CONFIG from 'src/config'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import type { Color } from 'src/styles/theme/types'
import { useSession } from 'src/hooks/use-session'
import type { EmailAccount } from 'src/libs/api/backend/session'
import * as S from './connected-email-accounts.styled'
import { useLocation } from 'react-router-dom'
import LoginError from '../login-error'
import { debounce, isNil } from 'lodash'
import { useDeleteEmailAccount } from 'src/hooks/mutations/use-delete-email-account'
import { StandaloneSwitch } from 'src/components/blocks/standalone-switch'
import { useSetEmailAccountTeamSendAsPermission, useSetPrimaryOrgEmailAccount } from 'src/hooks/mutations/use-set-email-account-team-send-as-permission'
import { useCallback, useMemo } from 'react'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { Dropdown } from '../../primitives/dropdown'
import { useSetAtom } from 'jotai'
import { openAlertAtom } from 'src/stores/dialogs'
import { notifyErrorAtom } from 'src/stores/notifications'
import { EmailSignatureEditor } from './email-signature-editor'
import { pluralize } from 'src/libs/pluralize'
import RouteBuilder from 'src/libs/route-builder'
import type { JobAndDepartment } from 'src/libs/api/backend/jobs'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useUpdateEmailSignature } from 'src/hooks/mutations/use-update-email-signature'

interface ConnectedAccountProps {
  emailAccount: EmailAccount
  onRemove: () => void
}

const AccountStatus = ({ emailAccount, onRemove }: ConnectedAccountProps): JSX.Element => {
  const { data: jobsByDepartment } = useJobByDepartmentsQuery()
  const { updateEmailSignature } = useUpdateEmailSignature()

  const getStatus = (): { color: Color, text: string } => {
    switch (emailAccount.hasAccessToken) {
      case true: {
        return {
          color: 'positiveBg' as Color,
          text: 'Connected'
        }
      }
      default: {
        return {
          color: 'negativeBg' as Color,
          text: 'Disconnected'
        }
      }
    }
  }

  const status = getStatus()

  const { refetchSession } = useSession()
  const notifyError = useSetAtom(notifyErrorAtom)

  const { setEmailAccountTeamSendAsPermission } = useSetEmailAccountTeamSendAsPermission()
  const { mutate: setPrimaryOrgEmailAccount } = useSetPrimaryOrgEmailAccount({
    onSuccess: () => {
      refetchSession()
    },
    onError: () => {
      notifyError({
        message: `An error occurred while setting ${emailAccount.email} as default. Please try again later.`
      })
    }
  })

  const reconnect = useCallback((): void => {
    if (!isNil(emailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(emailAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void queryClient.invalidateQueries({
            queryKey: [queryKeys.jobStats]
          })
          clearInterval(timer)
        }
      }, 500)
    }
  }, [emailAccount])

  const jobsUsedByEmailAccount = useMemo((): JobAndDepartment[] => {
    let allJobs: JobAndDepartment[] = []
    const jobIds = emailAccount?.usedInJobIds ?? []
    const departments = jobsByDepartment ?? {}

    Object.values(departments).forEach((department) => {
      Object.values(department).forEach((projectJobs) => {
        allJobs = allJobs.concat(projectJobs)
      })
    })

    return allJobs.filter((job) => jobIds.includes(job.id))
  }, [emailAccount, jobsByDepartment])

  const debouncedUpdateSignature = useMemo(() => debounce((signature: string): void => {
    updateEmailSignature({
      emailAccountId: emailAccount.emailAccountId,
      signature
    })
  }, 1000), [emailAccount.emailAccountId, updateEmailSignature])

  const handleUpdateEmailSignature = useCallback(
    (signature: string): void => {
      debouncedUpdateSignature(signature)
    },
    [debouncedUpdateSignature]
  )

  return (
    <S.ConnectedAccount>
      <S.ConnectedAccountTopHalf>
        <S.EmailAccountGrid>
          <S.StatusIndicator $color={status.color} />
          <Flex $gap={16} $align="center">
            <Caption size="SM">{emailAccount.email}</Caption>
            {emailAccount.isPrimary && <Caption size="SM" $color='fgTertiary'>Default</Caption>}
          </Flex>
          <Flex $gap={12} $align="center" $justify="flex-end">
            <Flex $flex="1" $justify="flex-end">
              <Dropdown
                trigger={
                  <Button
                    nested
                    leadingIcon='more-vertical'
                    $variant="ghost"
                    $colorTheme='muted'
                    ariaLabel='More options'
                    $height={24}
                    $width={24}
                    $fontSize={12}
                  />
                }
                items={[
                  {
                    id: '0',
                    title: emailAccount.isPrimary ? 'Current default' : 'Set as default',
                    isDisabled: emailAccount.isPrimary,
                    onSelect: () => {
                      setPrimaryOrgEmailAccount({
                        emailAccountId: emailAccount.emailAccountId
                      })
                    }
                  },
                  emailAccount.hasAccessToken
                    ? {
                        variant: 'negative',
                        id: '1',
                        title: 'Remove account',
                        onSelect: onRemove
                      }
                    : {
                        variant: 'tint',
                        id: '2',
                        title: 'Reconnect',
                        onSelect: reconnect
                      }
                ]}
                menuPosition='end'
                $minWidth="160px"
                $maxWidth="160px"
                size="small"
              />
            </Flex>
          </Flex>
        </S.EmailAccountGrid>
      </S.ConnectedAccountTopHalf>
        {
          emailAccount.emailAliases.length > 0
            ? (
                <S.ConnectedAccountsEmailAliases>
                  <Paragraph size="XS" $color="fgTertiary">Aliases</Paragraph>
                  <Flex $gap={8} $align="center" $wrap="wrap">
                    {emailAccount.emailAliases.map((alias) => (
                      <S.EmailAliasBadge key={alias.sendAsEmail}>{alias.sendAsEmail}</S.EmailAliasBadge>
                    ))}
                    </Flex>
                </S.ConnectedAccountsEmailAliases>
              )
            : null
        }
      <S.ConnectedAccountPermissions>
        <Flex $direction="row">
          <StandaloneSwitch name="HI" label="Allow team to send emails from this account" onChange={(switchState) => {
            setEmailAccountTeamSendAsPermission({ emailAccountId: emailAccount.emailAccountId, teamSendAsPermission: switchState })
          }}
            defaultChecked={emailAccount.teamSendAsPermission ?? false}
            $direction="row-reverse" $variant="ghost"
          />
        </Flex>
      </S.ConnectedAccountPermissions>
      <S.ConnectedAccountInUse>
        <When condition={jobsUsedByEmailAccount.length > 0}>
          <Paragraph size="XS" $color="fgSecondary">
            This account is used in outreach sequences for{' '}
            <Dropdown
              trigger={
                <S.JobsListTrigger>
                  {pluralize(emailAccount?.usedInJobIds?.length ?? 0, 'job position')}.
                </S.JobsListTrigger>
              }
              items={jobsUsedByEmailAccount?.map((job) => ({
                title: job.title,
                href: RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId: job.id })
              }))}
              size="small"
            />
          </Paragraph>
        </When>
        <When condition={!jobsUsedByEmailAccount.length}>
          <Paragraph size="XS" $color="fgSecondary">This account is not used in any outreach sequences.</Paragraph>
        </When>
      </S.ConnectedAccountInUse>
      <S.ConnectedAccountEmailSignature>
        <Caption size="XS" $color="fgSecondary">Email Signature</Caption>
        <EmailSignatureEditor
          onDataChanged={(data) => {
            handleUpdateEmailSignature(data)
          }}
          content={emailAccount?.signature}
        />
      </S.ConnectedAccountEmailSignature>
    </S.ConnectedAccount>
  )
}

export const ConnectedEmailAccounts = (): JSX.Element => {
  const openAlert = useSetAtom(openAlertAtom)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const error = params.get('error')

  const redirectPath = window.location.href
  const queryString = `?redirect=${redirectPath}&errorRedirect=${redirectPath}`
  const googleUrl = `${CONFIG.API_DOMAIN}/auth/google/consent${queryString}`
  const microsoftUrl = `${CONFIG.API_DOMAIN}/auth/microsoft/consent${queryString}`

  const location = useLocation()
  const isOnboarding = location.pathname.toLowerCase().includes('onboarding')

  const { emailAccountAccessTokens } = useSession()
  const hasConnectedAccounts = !isNil(emailAccountAccessTokens) && emailAccountAccessTokens.some((token) => token.hasAccessToken)

  const { deleteEmailAccount } = useDeleteEmailAccount()

  const handleDeleteEmailAccount = async (emailAccountId: string): Promise<void> => {
    deleteEmailAccount({ emailAccountId })
  }

  return (
    <>
      {error && (
        <>
          <LoginError error={error} />
          <Spacer $size={24} />
        </>
      )}
      <Flex $align="center" $gap={16}>
        <Button
          $variant="raised"
          $colorTheme="normal"
          $align="center"
          leadingIcon="google"
          $width="full"
          $height={40}
          href={googleUrl}
          hrefTarget='_self'
        >
          Connect New Google Account
        </Button>
        <Button
          $variant="raised"
          $colorTheme="normal"
          $align="center"
          leadingIcon="microsoft"
          $width="full"
          $height={40}
          hrefTarget='_self'
          href={microsoftUrl}
        >
          Connect New Microsoft Account
        </Button>
      </Flex>
      <Spacer $size={40} />
      <When condition={isOnboarding && !hasConnectedAccounts}>
        <BenefitsList benefits={CONNECTED_ACCOUNTS_BENEFITS} />
      </When>

      <When condition={!isOnboarding || hasConnectedAccounts}>
        <Caption size='SM' $color='fgSecondary'>Connected Accounts</Caption>
        <Spacer $size={12} />
        <Flex $direction="column" $gap={8}>
          {emailAccountAccessTokens?.map((emailAccount) => (
            <AccountStatus
              key={emailAccount.emailAccountId}
              emailAccount={emailAccount}
              onRemove={() => {
                openAlert({
                  message: 'Are you sure you want to disconnect?',
                  description: `This will disconnect ${emailAccount.email} from Pin which will disable syncing & sending emails and calendar events.`,
                  onConfirm: () => {
                    void handleDeleteEmailAccount(emailAccount.emailAccountId)
                  }
                })
              }}
            />
          ))}
        </Flex>
      </When>
    </>
  )
}
