import styled, { css } from 'styled-components'

export const InboxCommentEditor = styled.div<{ $isFocused: boolean, $variant: 'PLAIN' | 'GHOST' }>`
border-radius: 6px;
overflow: hidden;
${({ $variant, $isFocused, theme }) => $variant === 'PLAIN' && css`
    margin-top: 0.5rem;
    border: ${$isFocused ? 'solid 1px' : 'dashed 1px'};
    border-color: ${({ theme }) => theme.colors.borderTranslucent};
    box-shadow: ${$isFocused ? theme.boxShadows.md : 'none'};
  `}
`

export const EditorWrapper = styled.div`
  position: relative;
  min-height: 3rem;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`
