import { isNil } from 'lodash'
import { STRIPE_LOOKUP_KEY, PriceInterval } from 'src/libs/api/backend/billing'
import type { BillingProduct, BillingProductPrice, CustomerSubscription } from 'src/libs/api/backend/billing'

interface ProductDetailsParams {
  product: BillingProduct
  customersSubscriptionsByProductId: Record<string, CustomerSubscription>
  interval: PriceInterval
}

interface ProductDetails {
  monthlyPrice: BillingProductPrice | null
  productPrice: BillingProductPrice | null
  productCost: number
  productInterval: PriceInterval
  productSubscribed: boolean
  perUsage: string | null
}

export const getProductDetails = (
  { product, customersSubscriptionsByProductId, interval }: ProductDetailsParams
): ProductDetails => {
  const productSubscribed = customersSubscriptionsByProductId[product.id ?? '']
  const productPrice = product.prices.find((price) => price.interval === interval) as BillingProductPrice | null
  const monthlyPrice = product.prices.find((price) => price.interval === PriceInterval.month) as BillingProductPrice | null

  let perUsage = null
  if (isNil(productPrice)) {
    return {
      monthlyPrice,
      productPrice,
      productCost: 0,
      productInterval: PriceInterval.month,
      productSubscribed: !isNil(productSubscribed),
      perUsage
    }
  }

  switch (productPrice.lookupKey) {
    case STRIPE_LOOKUP_KEY.PROFESSIONAL_YEARLY:
    case STRIPE_LOOKUP_KEY.PROFESSIONAL_MONTHLY:
      perUsage = 'per user'
      break
    default:
      break
  }

  let productCost = productPrice?.unitAmount ?? 0
  let productInterval = PriceInterval.month // Always display monthly for now
  if (!isNil(productSubscribed)) {
    productCost = productSubscribed.amount ?? 0
    productInterval = productSubscribed.interval
  } else if (interval === PriceInterval.year) {
    productCost = productCost / 12
  }

  return {
    monthlyPrice,
    productPrice,
    productCost,
    productInterval,
    productSubscribed: !isNil(productSubscribed),
    perUsage
  }
}
