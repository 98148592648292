import styled from 'styled-components'
import type { Variant, BadgeStyleProps } from './badge'
import type { Theme } from 'src/styles/theme/types'

const getVariantStyles = (variant: Variant, theme: Theme): string | undefined => {
  switch (variant) {
    case 'plainTint': {
      return `
        background-color: ${theme.colors.bgPrimary};
        color: ${theme.colors.tintBg};
        border: solid 1px;
        border-color: ${theme.colors.borderTranslucent};
      `
    }
    case 'plain': {
      return `
        background-color: ${theme.colors.bgPrimary};
        color: ${theme.colors.fgPrimary};
        border: solid 1px;
        border-color: ${theme.colors.borderTranslucent};
      `
    }
    case 'ghost': {
      return `
        background-color: ${theme.colors.bgPrimary};
        color: ${theme.colors.fgSecondary};
        border: solid 1px;
        border-color: ${theme.colors.bgPrimary};
      `
    }
    case 'fgPrimary': {
      return `
        background-color: ${theme.colors.fgPrimary};
        color: ${theme.colors.bgPrimary};
      `
    }
    case 'fgSecondary': {
      return `
        background-color: ${theme.colors.fgSecondary};
        color: ${theme.colors.bgPrimary};
        border: solid 1px;
        border-color: ${theme.colors.fgSecondary};
      `
    }
    case 'fgTertiary': {
      return `
        background-color: ${theme.colors.fgTertiary};
        color: ${theme.colors.fgPrimary};
        border: solid 1px;
        border-color: ${theme.colors.fgTertiary};
      `
    }
    case 'fgTranslucent25': {
      return `
        background-color: ${theme.colors.fgTranslucent25};
        color: ${theme.colors.fgSecondary};
        border: solid 1px;
        border-color: transparent;
      `
    }
    case 'fgTranslucent10': {
      return `
        background-color: ${theme.colors.fgTranslucent10};
        color: ${theme.colors.fgTertiary};
        border: solid 1px;
        border-color: transparent;
      `
    }
    case 'bgTertiary': {
      return `
        background-color: ${theme.colors.bgTertiary};
        color: ${theme.colors.fgPrimary};
        border: solid 1px;
        border-color: ${theme.colors.bgTertiary};
      `
    }
    case 'bgPrimary': {
      return `
        background-color: ${theme.colors.bgPrimary};
        color: ${theme.colors.fgPrimary};
        border: solid 1px;
        border-color: ${theme.colors.bgPrimary};
      `
    }
    case 'tint': {
      return `
        background-color: ${theme.colors.tintBg};
        color: ${theme.colors.bgPrimary};
        border: solid 1px;
        border-color: ${theme.colors.tintBg};
      `
    }
    case 'tintLight': {
      return `
        background-color: ${theme.colors.tintFaded};
        color: ${theme.colors.tintBg};
        border: solid 1px;
        border-color: ${theme.colors.tintFaded};
      `
    }
    case 'aiSolidFg': {
      return `
        background-color: ${theme.colors.bgPrimary};
        color: ${theme.colors.aiSolidFg};
        border: solid 1px;
        border-color: ${theme.colors.borderTranslucent};
      `
    }
    case 'positive': {
      return `
        background-color: ${theme.colors.positiveBg};
        color: ${theme.colors.fgPrimary};
        border: solid 1px;
        border-color: ${theme.colors.positiveBg};
      `
    }
    case 'positiveLight': {
      return `
        background-color: ${theme.colors.positiveTranslucent5};
        color: ${theme.colors.positiveBg};
      `
    }
    case 'negative': {
      return `
        background-color: ${theme.colors.negativeBg};
        color: ${theme.colors.negativeContrast};
        border: solid 1px;
        border-color: ${theme.colors.negativeBg};
      `
    }
    case 'negativeLight': {
      return `
        background-color: ${theme.colors.negativeTranslucent5};
        color: ${theme.colors.negativeBg};
      `
    }
  }
}

export const Badge = styled.div<BadgeStyleProps>`
  width: ${({ $shape }) => ($shape === 'dot' ? '0.5rem' : 'auto')};
  height: ${({ $shape, theme, $height }) =>
    $shape === 'dot' ? '0.5rem' : theme.spacing[$height ?? 16]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border-radius: ${({ $shape }) =>
    $shape === 'rounded' ? '999px' : $shape === 'dot' ? '50%' : '4px'};
  padding: ${({ $shape, $padding }) => ($shape === 'dot' ? 0 : $padding === 'casual' ? '0 0.5rem' : '0 0.25rem')};
  ${({ $variant, theme }) => getVariantStyles($variant ?? 'fgSecondary', theme)}
  font-size: ${({ theme, $fontSize }) => theme.fontSizes[$fontSize ?? 10]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  text-transform: ${({ $transform }) => $transform};
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
