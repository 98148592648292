import { isNil } from 'lodash'
import { EmailVariable } from '../types'

export const COMPONENT_TAG = 'variable-component'

const emailVariableValues = Object.values(EmailVariable)

export const pasteRegex = new RegExp(`{{(${emailVariableValues.join('|')})}}`, 'gi')

// This regex matches the entire variable-component tag and captures the data value
const variableComponentRegexp = new RegExp(`<${COMPONENT_TAG} variable="([^'"]*)"[^>]*?></${COMPONENT_TAG}>`, 'gi')

// This regex matches any variable-component tag with variable="ai_personalized_sentence"
const aiPersonalizedSentenceExistsRegexp = new RegExp(`<${COMPONENT_TAG}[^>]*?variable="${EmailVariable.AI_PERSONALIZED_SENTENCE}"[^>]*?>`, 'i')

export const parseVariableToComponent = (html: string | null | undefined, personalizationInstructions: string | null | undefined = ''): string | null | undefined => {
  if (isNil(html)) {
    return html
  }
  return html.replace(pasteRegex, (_, variable) => {
    if (variable === EmailVariable.AI_PERSONALIZED_SENTENCE) {
      if (personalizationInstructions) {
        return `<${COMPONENT_TAG} variable="${variable}" aiinstructions="${personalizationInstructions}"></${COMPONENT_TAG}>`
      }
      return `<${COMPONENT_TAG} variable="${variable}" aiinstructions=""></${COMPONENT_TAG}>`
    }
    return `<${COMPONENT_TAG} variable="${variable}"></${COMPONENT_TAG}>`
  })
}

export const parseComponentToVariable = (html: string | null | undefined): string | null | undefined => {
  if (isNil(html)) {
    return html
  }
  return html.replace(variableComponentRegexp, (_, variable) => {
    return `{{${variable}}}`
  })
}

export const containsPersonalizationInstructions = (html: string | null | undefined): boolean => {
  if (isNil(html)) {
    return false
  }
  return aiPersonalizedSentenceExistsRegexp.test(html)
}

export const cleanHtmlTags = (html: string | null | undefined): string | null | undefined => {
  if (isNil(html)) {
    return html
  }
  return html.replace(/<[^>]*>/g, '')
}

export const convertSubjectToText = (subject: string | null | undefined): string | null | undefined => {
  if (isNil(subject)) {
    return subject
  }
  return cleanHtmlTags(parseComponentToVariable(subject))
}
