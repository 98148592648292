import { useEffect, useState } from 'react'
import Holidays from 'date-holidays'

interface Holiday {
  date: string
  start: Date
  end: Date
  name: string
  type: string
  rule: string
}

interface UseHolidaysArgs {
  country?: string
}

interface HolidaysReturnType {
  holidays: Holiday[]
  nextHoliday: Holiday | null
}

export const useHolidays = ({ country = 'US' }: UseHolidaysArgs): HolidaysReturnType => {
  const [holidays, setHolidays] = useState<Holiday[]>([])
  const [nextHoliday, setNextHoliday] = useState<Holiday | null>(null)

  useEffect(() => {
    const hd = new Holidays()
    hd.init(country)

    const today = new Date()
    const nextYear = new Date(today)
    nextYear.setFullYear(today.getFullYear() + 1)

    const currentYearHolidays = hd.getHolidays(today.toISOString(), `${today.getFullYear()}-12-31`)
    const nextYearHolidays = hd.getHolidays(`${today.getFullYear() + 1}-01-01`, nextYear.toISOString())

    const filteredHolidays = [...currentYearHolidays, ...nextYearHolidays]
      .filter((holiday) => new Date(holiday.date) >= today)
      .filter((holiday) => holiday.type === 'public' || holiday.type === 'bank') as Holiday[]

    setHolidays(filteredHolidays)
    setNextHoliday(filteredHolidays[0] || null)
  }, [country])

  return { holidays, nextHoliday }
}
