import { useCallback, useEffect } from 'react'
import { Form, Input } from 'src/components/forms'
import { Button } from 'src/components/primitives/button'
import { useForm } from 'src/hooks/use-form'
import * as S from './candidate-phone-numbers-list.styled'
import { Paragraph } from 'src/components/primitives/typography'
import { z } from 'zod'

interface EditPhoneNumberProps {
  defaultValue?: string
  onUpdate: (emailAddress: string) => void
  onCancel: () => void
}

const phoneNumberSchema = z.object({
  phoneNumber: z.string()
})

type PhoneNumberSchema = z.infer<typeof phoneNumberSchema>

export const EditPhoneNumber = ({ defaultValue, onUpdate, onCancel }: EditPhoneNumberProps): JSX.Element => {
  const { submit, register, setValue, errors, isValid } = useForm<PhoneNumberSchema>({
    schema: phoneNumberSchema
  })

  const handleSubmit = useCallback((formData: PhoneNumberSchema): void => {
    if (onUpdate && isValid) {
      onUpdate(formData.phoneNumber)
    }
  }, [onUpdate, isValid])

  useEffect(() => {
    if (defaultValue) {
      setValue('phoneNumber', defaultValue)
    }
  }, [defaultValue, setValue])

  return (
    <S.EditPhoneNumber>
      <Form onSubmit={submit(handleSubmit)}>
        <Input
          name="phoneNumber"
          label="Phone Number"
          type='tel'
          hiddenLabel
          register={register}
          setFocus
        />
        {errors.phoneNumber && <Paragraph as='span' size='2XS' $color='negativeBg'>{errors.phoneNumber}</Paragraph>}
        <Button
          leadingIcon="check"
          $variant="ghost"
          $colorTheme="muted"
          type="submit"
          $height={16}
          $width={16}
          $fontSize={12}
        />
        <Button
          leadingIcon="x"
          $variant="ghost"
          $colorTheme="muted"
          type="button"
          $height={16}
          $width={16}
          $fontSize={12}
          onClick={onCancel}
        />
      </Form>
    </S.EditPhoneNumber>
  )
}
