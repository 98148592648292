import { Navigate, useParams } from 'react-router-dom'
import { Sourcing } from 'src/components/blocks/sourcing/sourcing'
import RouteBuilder from 'src/libs/route-builder'

interface JobCandidatesSourcingPageProps {
  isManualSourceView?: boolean
}

const JobCandidatesSourcingPage = ({ isManualSourceView = false }: JobCandidatesSourcingPageProps): JSX.Element => {
  const { jobId, jobSearchRefinementId } = useParams()

  // const jobSearchRefinementSuggestedAdditions = useJobSearchRefinementSuggestedAdditions({
  //   jobId,
  //   jobSearchRefinementId
  // })

  // const { searchHistory } = useJobSearchRefinementSearchHistory({
  //   jobId: jobSearchRefinement?.jobId,
  //   jobSearchRefinementId: jobSearchRefinement?.id
  // })

  if (!jobId) {
    return <Navigate to={RouteBuilder.build('ROOT')} />
  }

  return (
    <Sourcing
      jobId={jobId}
      jobSearchRefinementId={jobSearchRefinementId}
      isManualSourceView={isManualSourceView}
    />
  )
}

export default JobCandidatesSourcingPage
