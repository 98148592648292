import * as S from './ready-to-send-card.styled'
import { CandidateProfile } from '../candidate-details'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { useMemo } from 'react'
import type { CandidateSequenceStepMessageReviewExtended } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import { When } from '../when'
import { SequenceStatusDropdown } from '../candidate-job-status'
import { CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'

export interface ReadyToSendCardProps {
  candidateSequenceStepMessageReview: CandidateSequenceStepMessageReviewExtended
  handleToggleFavoriteStatus: (candidateJobId: string, newStatus: boolean) => void
  openCandidateDialog: (candidateJobId: string) => void
}

interface ReadyToSendCardComponentProps extends ReadyToSendCardProps {
  children: React.ReactNode
}

export const ReadyToSendCard = ({
  candidateSequenceStepMessageReview,
  handleToggleFavoriteStatus,
  openCandidateDialog,
  children
}: ReadyToSendCardComponentProps): JSX.Element => {
  const { candidateJob } = candidateSequenceStepMessageReview

  const candidateHasEmailError = useMemo(() => {
    const status = candidateSequenceStepMessageReview.candidateJob.statusDisplay?.status
    return status === CandidateJobStatus.SEARCHING_FOR_EMAIL ||
      status === CandidateJobStatus.EMAIL_NOT_FOUND ||
      status === CandidateJobStatus.MISSING_EMAIL
  }, [candidateSequenceStepMessageReview.candidateJob.statusDisplay])

  return (
    <S.ReadyToSendContainer $hasError={candidateHasEmailError}>
      <When condition={candidateHasEmailError}>
        <S.ErrorHeader>
          <Caption size="XS" $color='negativeFg'>{candidateSequenceStepMessageReview.candidateJob.statusDisplay?.title}</Caption>
        </S.ErrorHeader>
      </When>
      <S.ReadyToSendHeader>
        <S.ReadyToSendProfile onClick={() => { openCandidateDialog(candidateJob.id) }}>
          <CandidateProfile
            avatarSize={32}
            headingSize='SM'
            horizontalGap={8}
            verticalGap={2}
            candidate={candidateJob.candidate}
            includeLatestExperienceTimeline={true}
          />
        </S.ReadyToSendProfile>
        <Flex $align='center' $width='auto'>
          <Flex $align='center' $gap={8} $width='auto'>
            <Button
              ariaLabel="Toggle favorite status"
              $variant="outline"
              $colorTheme={candidateJob.favorite ? 'warning' : 'muted'}
              $fontSize={12}
              $height={24}
              leadingIcon={candidateJob.favorite ? Icons.starFill : Icons.star}
              onClick={() => {
                handleToggleFavoriteStatus(candidateJob.id, !candidateJob.favorite)
              }}
              tooltip={{
                text: candidateJob.favorite ? 'Remove candidate from shortlist' : 'Add candidate to shortlist',
                position: 'top'
              }}
            >
            </Button>
            <SequenceStatusDropdown
              candidateJob={candidateJob}
              $variant='outline'
              enabledSendEmail={false}
            />
          </Flex>
        </Flex>
      </S.ReadyToSendHeader>
      <S.Inner>
        {children}
      </S.Inner>
    </S.ReadyToSendContainer>
  )
}
