import * as Dialog from 'src/components/primitives/dialog'
import { Sidebar } from 'src/components/blocks/sidebar'
import { When } from 'src/components/blocks/when'
import { useQueryParams } from 'src/hooks/use-query-params'
import { UserProfileForm } from 'src/components/blocks/user-profile-form'
import { ConnectedEmailAccounts } from 'src/components/blocks/connected-email-accounts'
import { PageHeader } from 'src/components/blocks/page-header'
import * as S from './default-settings-dialog.styled'
import { OrgDetailsSettings } from './org-details-settings'
import { TeamMembersSettings } from './team-members-settings'
import { AtsSettings } from 'src/components/blocks/ats-settings'
import { Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { useCallback, useMemo, useRef } from 'react'
import { NotificationPreferences } from 'src/components/blocks/notification-preferences'
import { useSession } from 'src/hooks/use-session'
import { HiringForSettings } from './hiring-for-settings'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useSearchParams } from 'react-router-dom'
import { SettingParams } from './type'
import { SubscriptionDialog } from './subscription-dialog'
import { SEO } from 'src/components/primitives/seo'
import { ProjectSettings } from './project-settings'
import { closeDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { ConnectedLinkedInAccount } from 'src/components/blocks/connected-linkedin-account'
import { Spacer } from 'src/components/primitives/spacer'
import { isNil } from 'lodash'
import { usePinExtensionVersion } from 'src/hooks/use-pin-extension'
import { useMeLinkedInAccounts } from 'src/hooks/use-me-linked-in-accounts'

const FULL_WIDTH_SETTINGS = [SettingParams.SUBSCRIPTION, SettingParams.ATS]

export const DefaultSettingsDialog = (): JSX.Element => {
  const { featureFlags, subscribed } = useSession()
  const { getParam, resetParam } = useQueryParams()
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.DEFAULT_SETTINGS), []))
  const closeDialog = useSetAtom(closeDialogAtom)
  const [searchParams] = useSearchParams()
  const extensionVersion = usePinExtensionVersion()
  const { linkedInAccounts } = useMeLinkedInAccounts()

  const dialogRef = useRef<Dialog.DialogPortalForwardedRefs>(null)

  const makeSettingParam = useCallback((param: SettingParams): string => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString())
    updatedSearchParams.set('settings', param)
    return `?${updatedSearchParams.toString()}`
  }, [searchParams])

  const currParam = useMemo(() => getParam('settings') as SettingParams, [getParam])

  interface SettingsLinkInfo {
    title: string
    href: string
    icon: IconName
  }

  const menuTabs = useMemo(() => {
    const topGroup = {
      heading: 'Account',
      links: [
        {
          title: 'Profile',
          href: makeSettingParam(SettingParams.PROFILE),
          icon: Icons.userCircle
        },
        {
          title: 'Connected Accounts',
          href: makeSettingParam(SettingParams.CONNECTED_ACCOUNTS),
          icon: Icons.plug
        },
        {
          title: 'Notification Preferences',
          href: makeSettingParam(SettingParams.NOTIFICATION_PREFERENCES),
          icon: Icons.bell
        }
      ] as SettingsLinkInfo[]
    }

    const bottomGroup = {
      heading: 'Company',
      links: [
        {
          title: 'Company Details',
          href: makeSettingParam(SettingParams.ORG_DETAILS),
          icon: Icons.building
        },
        {
          title: 'Hiring For Companies',
          href: makeSettingParam(SettingParams.HIRING_FOR),
          icon: Icons.clipboard
        },
        ...(featureFlags?.includes(FeatureFlags.PROJECTS)
          ? [{
              title: 'Projects',
              href: makeSettingParam(SettingParams.PROJECTS),
              icon: Icons.squareGanttChart
            }]
          : []),
        {
          title: 'Members',
          href: makeSettingParam(SettingParams.TEAM_MEMBERS),
          icon: Icons.users
        },
        {
          title: subscribed ? 'Billing & Plan' : 'Subscribe',
          icon: Icons.wallet,
          // href: '?subscribe=1'
          href: makeSettingParam(SettingParams.SUBSCRIPTION)
        }
      ] as SettingsLinkInfo[]
    }

    if (featureFlags?.includes(FeatureFlags.ATS_INTEGRATION)) {
      bottomGroup.links.push({
        title: 'ATS Integration',
        href: makeSettingParam(SettingParams.ATS),
        icon: Icons.plug
      })
    }
    const groups = [{
      items: [
        topGroup,
        bottomGroup
      ]
    }]

    return groups
  }, [featureFlags, makeSettingParam, subscribed])

  return (
    <Dialog.Root
      id={DialogId.DEFAULT_SETTINGS}
      isOpen={isDialogOpen}
      onOpenChange={() => {}}
      $width="100%"
      $maxWidth="1248px"
      $height="half"
      $minHeight="712px"
      $innerPadding={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
      modal={false}
    >
      <Dialog.Portal
        ref={dialogRef}
        onCancel={() => {
          resetParam('settings')
          closeDialog(DialogId.DEFAULT_SETTINGS)
        }}
      >
        <SEO title="Settings" />
        <Dialog.Header
          title="Settings"
          onClose={() => {
            resetParam('settings')
            closeDialog(DialogId.DEFAULT_SETTINGS)
          }}
        />
        <Dialog.Content>
          <S.Wrapper>
            <S.Sidebar>
              <Sidebar
                collapsable={false}
                groups={menuTabs}
              />
            </S.Sidebar>
            <S.Content>
              <S.SettingsContent
                $maxWidth={FULL_WIDTH_SETTINGS.includes(currParam) ? '100%' : '672px'}
              >
                <When condition={currParam === SettingParams.PROFILE}>
                  <PageHeader heading="Your Profile" subheading="Manage your personal profile" />
                  <UserProfileForm />
                </When>
                <When condition={currParam === SettingParams.CONNECTED_ACCOUNTS}>
                  <PageHeader
                    heading="Connected Email Accounts"
                    subheading="Email candidates, sync contacts and scheduled meetings"
                  />
                  <ConnectedEmailAccounts />
                  <Spacer $size={36} />
                  <When condition={featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION)}>
                    <PageHeader
                      heading={linkedInAccounts.length > 0 ? 'Connected LinkedIn Accounts' : 'Connect LinkedIn Account'}
                      subheading={linkedInAccounts.length > 0
                        ? undefined
                        : isNil(extensionVersion)
                          ? 'Connecting your LinkedIn account requires installing the Chrome Extension.'
                          : 'Connect to your LinkedIn account to send InMail through Pin.'}
                    />
                    <ConnectedLinkedInAccount />
                  </When>
                </When>
                <When condition={currParam === SettingParams.NOTIFICATION_PREFERENCES}>
                  <PageHeader
                    heading="Email Notification Preferences"
                    subheading="Choose which notifications you'd like to receive via email."
                  />
                  <NotificationPreferences />
                </When>
                <When condition={currParam === SettingParams.ORG_DETAILS}>
                  <PageHeader heading="Company Details" subheading="Manage your team-wide settings" />
                  <OrgDetailsSettings />
                </When>
                <When condition={currParam === SettingParams.HIRING_FOR}>
                  <PageHeader heading="Hiring for" />
                  <HiringForSettings />
                </When>
                <When condition={currParam === SettingParams.PROJECTS}>
                  <PageHeader heading="Projects" />
                  <ProjectSettings />
                </When>
                <When condition={currParam === SettingParams.TEAM_MEMBERS}>
                  <TeamMembersSettings />
                </When>
                <When condition={currParam === SettingParams.SUBSCRIPTION}>
                  <SubscriptionDialog />
                </When>
                <When condition={currParam === SettingParams.ATS}>
                  <AtsSettings />
                </When>
              </S.SettingsContent>
            </S.Content>
          </S.Wrapper>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
