import styled from 'styled-components'

export const Inner = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-top: ${({ theme }) => theme.spacing[24]};
  padding-bottom: ${({ theme }) => theme.spacing[24]};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
`
