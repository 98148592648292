import { useSetAtom } from 'jotai'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { CandidateExportFileType } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { sleep } from 'src/libs/sleep'
import { notifyAtom } from 'src/stores/notifications'
import { Spinner } from 'src/components/primitives/spinner'
import CONFIG from 'src/config'
import { useState } from 'react'
import { useExportCandidatesToCsv } from 'src/hooks/use-export-candidates-to-csv'

interface ExportCandidatesDropdownProps {
  exportOptions?: CandidateExportFileType[]
  candidateJobs?: CandidateJobExpanded[]
  exportToPdfPrintUrl?: string
}

const onLoadIFrame = async (iframe: HTMLIFrameElement): Promise<void> => {
  const closePrint = (): void => {
    document.body.removeChild(iframe)
  }
  if (iframe.contentWindow) {
    iframe.contentWindow.onbeforeunload = closePrint
    iframe.contentWindow.onafterprint = closePrint
    await sleep(10000)
    iframe.contentWindow.print()
  }
}

export const ExportCandidatesDropdown = ({
  exportToPdfPrintUrl,
  exportOptions = [CandidateExportFileType.PDF, CandidateExportFileType.CSV]
}: ExportCandidatesDropdownProps): JSX.Element => {
  const { exportCandidates } = useExportCandidatesToCsv()
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)
  const notify = useSetAtom(notifyAtom)

  const handlePdfExport = async (): Promise<void> => {
    setIsGeneratingPdf(true)
    notify({
      type: 'toast',
      variant: 'positive',
      position: 'bottom-right',
      iconNode: <Spinner />,
      message: 'Generating PDF…',
      closeDelay: 12000
    })

    const hideFrame = document.createElement('iframe')
    hideFrame.onload = async () => {
      await onLoadIFrame(hideFrame)
      setIsGeneratingPdf(false)
    }
    hideFrame.style.display = 'none'
    hideFrame.src = `${CONFIG.APP_DOMAIN}${exportToPdfPrintUrl}`
    document.body.appendChild(hideFrame)
  }

  const handleCsvExport = async (): Promise<void> => {
    await exportCandidates()
  }

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="outline"
          $colorTheme="muted"
          $height={24}
          $fontSize={12}
          trailingIcon={Icons.chevronDown}
        >
          Export
        </Button>
      }
      items={[
        ...(exportOptions.includes(CandidateExportFileType.PDF)
          ? [{
              title: 'Export to PDF',
              icon: Icons.printer,
              isDisabled: isGeneratingPdf,
              onSelect: handlePdfExport
            }]
          : []),
        ...(exportOptions.includes(CandidateExportFileType.CSV)
          ? [{
              title: 'Export to CSV',
              icon: Icons.fileSpreadsheet,
              onSelect: handleCsvExport
            }]
          : [])
      ]}
      size="small"
      menuPosition="end"
      $menuWidth={180}
      $minWidth="180px"
      $maxWidth="180px"
    />
  )
}
