import { NodeViewWrapper } from '@tiptap/react'
import type { NodeViewWrapperProps } from '@tiptap/react'
import { formatVariableName, EmailVariables, EmailVariable } from '../types'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useEffect, useState } from 'react'
import * as RadixPopover from '@radix-ui/react-popover'
import styled from 'styled-components'
import { Caption } from 'src/components/primitives/typography'
import { Form, Textarea } from 'src/components/forms'
import { Button, Flex } from 'src/components/primitives'
import { z } from 'zod'
import { useForm } from 'src/hooks/use-form'

const VariablePopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  padding: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.spacing[4]};
  gap: ${({ theme }) => theme.spacing[8]};
  width: 32.5rem;
  textarea {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    outline: none;
  }
  div:first-child {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }
  z-index: ${({ theme }) => theme.zindeces.outerspace};
`

const VariablePopoverTrigger = styled(RadixPopover.Trigger)`
  &[data-state='open'] {
    box-shadow: ${({ theme }) => theme.boxShadows['light-active']};
    border-radius: 4px;
  }
`

const PersonalizedText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  margin-left: 4px;
  margin-right: 4px;
`

const PersonalizedTextContainer = styled.span`
  cursor: pointer;
  white-space: nowrap !important;
  border-radius: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[0]} ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.aiSolidTranslucent10};
  border: solid 1px ${({ theme }) => theme.colors.borderTranslucent};
  color: ${({ theme }) => theme.colors.aiSolidFg};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.aiSolidTranslucent25};
    }
  }
`

const PersonalizedItemContainer = styled.span`
  vertical-align: middle;
`

const specialInstructionsSchema = z.object({
  specialInstructions: z.string().optional()
})

export type SpecialInstructionsSchema = z.infer<typeof specialInstructionsSchema>

const AiPersonalizedVariableComponent = ({
  personalizationInstructions,
  setPersonalizationInstructions
}: {
  personalizationInstructions: string
  setPersonalizationInstructions: (instructions: string | undefined) => void
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { submit, register, setValue, formData } = useForm<SpecialInstructionsSchema>({
    schema: specialInstructionsSchema
  })

  const [initialPersonalizationInstructions, setInitialPersonalizationInstructions] = useState<string | undefined>(personalizationInstructions)

  const handleSubmit = (formData: SpecialInstructionsSchema): void => {
    setPersonalizationInstructions(formData.specialInstructions)
    setInitialPersonalizationInstructions(formData.specialInstructions)
    setIsOpen(false)
  }

  useEffect(() => {
    setValue('specialInstructions', personalizationInstructions)
  }, [personalizationInstructions, setValue])

  return (
    <RadixPopover.Root open={isOpen} onOpenChange={setIsOpen}>
      <VariablePopoverTrigger asChild>
        <PersonalizedTextContainer
          contentEditable={false}
        >
          <PersonalizedItemContainer>
            <Icon name={Icons.sparklesSolid} size={12} color='aiSolidFg' />
          </PersonalizedItemContainer>
          <PersonalizedText>{formatVariableName(EmailVariable.AI_PERSONALIZED_SENTENCE)}</PersonalizedText>
          <PersonalizedItemContainer>
            <Icon name={Icons.chevronDown} size={12} color='aiSolidBg' />
          </PersonalizedItemContainer>
        </PersonalizedTextContainer>
      </VariablePopoverTrigger>
      <RadixPopover.Portal>
        <RadixPopover.Content asChild side='bottom' align='start' sideOffset={4}>
          <VariablePopoverContent>
            <Caption size='XS' $color='fgSecondary'>Special instructions</Caption>
            <Form onSubmit={submit(handleSubmit)}>
              <Textarea
                name="specialInstructions"
                label="Special instructions"
                placeholder="Eg: Mention and praise their experience working at a company related to this company or position."
                hiddenLabel
                register={register}
                autoFocus
                rows={3}
              />
              <Flex $direction='row' $gap={8} $align='center'>
                <Button
                  $variant="raised"
                  $colorTheme="tint"
                  type="submit"
                  $height={24}
                  $fontSize={12}
                  disabled={!formData.specialInstructions || formData.specialInstructions === personalizationInstructions}
                >
                  Save
                </Button>
                <Button
                  $variant="outline"
                  $colorTheme="muted"
                  type="button"
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    setValue('specialInstructions', initialPersonalizationInstructions)
                    setIsOpen(false)
                  }}
                >
                  Cancel
                </Button>
              </Flex>
              </Form>
          </VariablePopoverContent>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  )
}

export const VariableComponent = (props: NodeViewWrapperProps): JSX.Element | null => {
  const { variable, aiinstructions } = props.node.attrs

  if (!variable || !EmailVariables.includes(variable as EmailVariable)) {
    return null
  }
  if (variable === EmailVariable.AI_PERSONALIZED_SENTENCE) {
    return (
      <NodeViewWrapper className='editor-variable-block' as='span'>
        <AiPersonalizedVariableComponent
          personalizationInstructions={aiinstructions}
          setPersonalizationInstructions={(aiinstructions: string | undefined) => {
            props.updateAttributes({ aiinstructions })
          }}
        />
      </NodeViewWrapper>
    )
  }
  return (
    <NodeViewWrapper className='editor-variable-block' as='span'>
      <span
        contentEditable={false}
        className='editor-variable'
      >
        {formatVariableName(variable as EmailVariable)}
      </span>
    </NodeViewWrapper>
  )
}
