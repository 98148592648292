import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from 'src/components/primitives/table'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Flex } from 'src/components/primitives/flex'
import { useGlobalError } from 'src/hooks/use-global-error'
import { buildTableColumns } from '../candidate-table-cells/table-builder'
import type { COLUMN, TableColumnDef, TableRowDef } from '../candidate-table-cells/table-builder'
import { CONTENT_PADDING, ERROR_BLOCK_HEIGHT, HEADER_PADDING } from 'src/styles/constants'
import * as S from './candidates-table.styled'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
interface CandidatesTableProps {
  isLoading?: boolean
  pageHeaderHeight?: number
  candidateJobs?: CandidateJobExpanded[]
  emptyState: React.ReactNode
  selectedRowsActions?: React.ReactNode
  visibleColumns: COLUMN[]
  customColumnDefinitions?: Array<Partial<TableColumnDef<TableRowDef>>>
  onToggleExpandableColumn?: (columnId: string) => void
}

export const CandidatesTable = ({
  isLoading,
  candidateJobs,
  emptyState,
  pageHeaderHeight = 64,
  selectedRowsActions,
  visibleColumns,
  onToggleExpandableColumn,
  customColumnDefinitions
}: CandidatesTableProps): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { isGlobalErrorOpen } = useGlobalError()
  const [columns, setColumns] = useState<Array<TableColumnDef<TableRowDef>>>([])

  useEffect(() => {
    if (!!isLoading || columns.length > 0) {
      return
    }
    setColumns(buildTableColumns({ visibleColumns, customColumnDefinitions }))
  }, [isLoading, visibleColumns, customColumnDefinitions, columns.length])

  useEffect(() => {
    // Reset columns on unmount
    return () => {
      setColumns([])
    }
  }, [])

  const maxHeight = useMemo(() => {
    const errorBlockHeight = isGlobalErrorOpen ? ERROR_BLOCK_HEIGHT : '0px'
    if (pageHeaderHeight > 0) {
      return `calc(100vh - ${pageHeaderHeight}px - ${CONTENT_PADDING} - ${errorBlockHeight})`
    }
    return `calc(100vh - 62px - ${HEADER_PADDING}*3 - ${CONTENT_PADDING} - ${errorBlockHeight})`
    // return `calc(100vh - ${HEADER_PADDING}*3 - ${CONTENT_PADDING} - ${errorBlockHeight})`
  }, [isGlobalErrorOpen, pageHeaderHeight])

  const handleRowClick = useCallback((row: TableRowDef): void => {
    openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: row.id })
  }, [openDialog])

  if (!!isLoading || columns.length === 0) {
    return <LoadingSkeleton $variant="CandidatesTable" delay={100} />
  }

  return (
    <S.TableWrapper
      $maxHeight={maxHeight ?? 'auto'}
    >
      <Table<TableRowDef>
        columns={columns}
        setColumns={setColumns}
        tableData={candidateJobs ?? []}
        onRowClick={(row) => {
          handleRowClick(row)
        }}
        onToggleExpandableColumn={onToggleExpandableColumn}
        emptyState={<div style={{ height: maxHeight ?? 'auto' }}>{emptyState}</div>}
        selectionActions={<Flex $gap={6}>{selectedRowsActions}</Flex>}
      />
    </S.TableWrapper>
  )
}
