import styled from 'styled-components'

export const ConnectedAccount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  overflow: hidden;
`

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const AccountDetails = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 0.65rem;
  align-items: center;
  height: 48px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
`

export const LinkedInAvatar = styled.div`
  position: relative;
  width: 1.15rem;
  height: 1.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  /* overflow: hidden; */
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 999px;
    border: solid 1px;
    border-color: ${({ theme }) => theme.colors.positiveFg};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 999px;
  }
`

export const AccountSettings = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const AccountSettingsSection = styled.div`
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const ConnectedAccountPermissions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;
  gap: 0.5rem;
`

export const ConnectedAccountInUse = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const JobsListTrigger = styled.button`
  all: unset;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  transform: translateY(-0.02rem);
`