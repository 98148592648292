import * as OL from 'src/components/forms/options-list/options-list.styled'
import { Caption } from '../primitives/typography'
import { Button, Flex } from '../primitives'
import { Icons } from '../primitives/icon'

interface ArchiveUserConfirmationProps {
  user?: string
  onClick: () => void
  onCancel?: () => void
}

export const ArchiveUserConfirmation = ({ onClick, user, onCancel }: ArchiveUserConfirmationProps): JSX.Element => {
  return (
    <OL.OptionConfirmation
      initial={{ scale: 0.98, y: 1, opacity: 0 }}
      animate={{ scale: 1, y: 0, opacity: 1 }}
      exit={{ scale: 0.98, y: 1, opacity: 0 }}
      transition={{ type: 'spring', duration: 0.25 }}
    >
      <OL.OptionConfirmationInner>
        <Caption size='XS' $whiteSpace="nowrap">Are you sure you want to disable { user ?? 'this user'}?</Caption>
        <Flex $gap={12} $align="center" $justify="flex-end">
          <Button
            $variant='raised-light'
            $colorTheme='negative'
            $height={24}
            $fontSize={12}
            onClick={() => {
              onClick()
            }}
          >
            Disable user
          </Button>
          <Button
            ariaLabel="Cancel"
            leadingIcon={Icons.x}
            $variant='ghost'
            $colorTheme='muted'
            $height={24}
            $width={24}
            $fontSize={12}
            onClick={onCancel}
          />
        </Flex>
      </OL.OptionConfirmationInner>
    </OL.OptionConfirmation>
  )
}
