import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { upsertJobSequence as upsertJobSequenceApi } from 'src/libs/api/backend/jobs'
import type { EmailSearchType, Job, SequenceWithSteps } from 'src/libs/api/backend/jobs'
import type { EmailSequenceStep } from 'src/models/sequence'
import { assign } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  jobId: Job['id']
  sequenceSteps?: EmailSequenceStep[]
  active?: boolean
  autoArchiveAfterDays?: number
  dailyEmailLimit?: number
  enableEmailOpenTracking?: boolean
  enableEmailClickTracking?: boolean
  emailSearch?: EmailSearchType
  onSuccess?: () => void
  onError?: () => void
  toastMessage?: string
}

interface Res {
  upsertJobSequence: (args: Args) => void
  isPending: boolean
}

export const useUpsertJobSequence = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const { jobId } = useParams()
  const mutation = useMutation({
    mutationFn: async ({
      jobId,
      onSuccess: _onSuccess,
      onError: _onError,
      toastMessage: _toastMessage,
      ...args
    }: Args) => {
      await upsertJobSequenceApi(jobId, args)
    },
    onMutate: async (sequenceWithSteps) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.jobSequence, jobId] })
      const snapshot = queryClient.getQueryData([queryKeys.jobSequence, jobId])
      await queryClient.setQueryData([queryKeys.jobSequence, jobId], (old: SequenceWithSteps) => {
        return assign(old, sequenceWithSteps)
      })
      return { snapshot }
    },
    onError: (err, variables, context) => {
      console.error('use-upsert-job-sequence: ', err)
      if (context?.snapshot) {
        queryClient.setQueryData([queryKeys.jobSequence, jobId], context.snapshot)
      }
      notifyError({
        message: `An error occured when updating your outreach sequence: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },

    onSuccess: async (sequenceWithSteps, variables) => {
      const isExistingSequence = variables.sequenceSteps?.some(
        (step) => step.generationState === null
      )
      await queryClient.setQueryData([queryKeys.jobSequence, jobId], (old: SequenceWithSteps) => {
        return assign(old, sequenceWithSteps)
      })

      const message = variables.toastMessage ?? (isExistingSequence ? 'Successfully updated outreach sequence' : 'Successfully created outreach sequence')

      notifySuccess({
        message
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.jobSequence, jobId]
      })
    }
  })

  const upsertJobSequence = (args: Args): void => {
    mutation.mutate(args)
  }

  return {
    upsertJobSequence,
    isPending: mutation.isPending
  }
}
