import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { INVALID_CANDIDATE_JOB_MESSAGEABLE_STATUSES } from 'src/libs/api/backend/candidate_jobs'
import { Caption } from 'src/components/primitives/typography'
import { pluralize } from 'src/libs/pluralize'
import { Badge } from 'src/components/primitives/badge'
import * as S from './message-recipients.styled'
import { Flex } from 'src/components/primitives/flex'
import { AvatarsList } from '../avatars-list'
import { isNil } from 'lodash'

interface MessageRecipientsProps {
  recipientsType?: 'EMAIL' | 'LINKEDIN'
  candidateJobs: CandidateJobExpanded[]
}

interface RecipientsAccumulator {
  validRecipients: CandidateJobExpanded[]
  invalidRecipients: CandidateJobExpanded[]
}

interface AvatarDisplay {
  showPlus: boolean
  count: number
}

export const MessageRecipients = ({ candidateJobs, recipientsType = 'EMAIL' }: MessageRecipientsProps): JSX.Element => {
  const invalidArguments = INVALID_CANDIDATE_JOB_MESSAGEABLE_STATUSES
  const { validRecipients, invalidRecipients } = candidateJobs.reduce<RecipientsAccumulator>(
    (acc, candidate) => {
      if (
        recipientsType === 'EMAIL' &&
        candidate.statusDisplay?.status &&
        invalidArguments.includes(candidate.statusDisplay?.status)
      ) {
        acc.invalidRecipients.push(candidate)
      } else {
        acc.validRecipients.push(candidate)
      }
      return acc
    },
    { validRecipients: [], invalidRecipients: [] }
  )

  let avatarDisplay: AvatarDisplay = { showPlus: false, count: validRecipients.length }

  if (validRecipients.length >= 7) {
    avatarDisplay = {
      showPlus: true,
      count: !invalidRecipients.length ? 5 : 3
    }
  }

  const recipientEmailAddress = validRecipients[0]?.candidate.emails?.at(0)

  return (
    <S.MessageRecipients>
      <Flex $gap={8} $align="center">
        <AvatarsList
          avatars={validRecipients.map(r => ({
            id: r.id,
            name: r.candidate.name,
            profilePhotoUrl: r.candidate.profilePhotoUrl
          }))}
          // $border={false}
          $border
          $size={20}
          $overlap={2}
          avatarDisplay={avatarDisplay}
        />
        {validRecipients?.length >= 2 && (
          <Caption size="XS" $color="fgSecondary">
            {pluralize(validRecipients.length, 'candidate')}, sent separately
          </Caption>
        )}
        {validRecipients?.length === 1 && (
          <Caption size="XS" $color="fgSecondary">
            {validRecipients[0]?.candidate.name}

            {/* We do not always render the recipient email adderss
              * because Custom Outreach messages don't know the email
              * address before sending
              */
            recipientsType === 'EMAIL' && !isNil(recipientEmailAddress) && recipientEmailAddress !== '' && ` (${recipientEmailAddress})`
            }
          </Caption>
        )}
      </Flex>
      {invalidRecipients?.length >= 1 && (
        <Badge
          $variant="negativeLight"
          leadingIcon="alert-triangle"
          $transform="none"
          $fontSize={12}
          $height={24}
        >
          {pluralize(invalidRecipients?.length, 'candidate')} removed due to missing email
        </Badge>
      )}
    </S.MessageRecipients>
  )
}
