import { isNil } from 'lodash'
import { AvatarsList } from 'src/components/blocks/avatars-list'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { Flex } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar/avatar'
import { Button } from 'src/components/primitives/button/button'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { useExportCandidateToAts } from 'src/hooks/mutations/use-export-candidate-to-ats'
import { useForm } from 'src/hooks/use-form'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { z } from 'zod'
import * as S from './export-to-ats-dialog.styled'
import type { Job } from 'src/libs/api/backend/jobs'
import { useGetMergeJobs } from 'src/hooks/queries/use-get-merge-jobs'
import { useEffect, useMemo } from 'react'
import { useLinkJobWithMergeJob } from 'src/hooks/mutations/use-link-job-with-merge-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { When } from 'src/components/blocks/when'
import { useSession } from 'src/hooks/use-session'
import { Banner } from 'src/components/blocks/banner'
import { useQueryParams } from 'src/hooks/use-query-params'
import { SettingParams } from '../default-settings-dialog'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'

const exportToAtsScchema = z.object({
  mergeJobId: z.string().nullish()
})

export type ExportToAtsSchema = z.infer<typeof exportToAtsScchema>

interface ExportToAtsDialogProps {
  job?: Job
  candidateJobs: CandidateJobExpanded[]
}

export const ExportToAtsDialog = ({
  job = undefined,
  candidateJobs
}: ExportToAtsDialogProps): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.EXPORT_CANDIDATES), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { exportCandidates } = useExportCandidateToAts()
  const { linkJobWithMergeAtsJob } = useLinkJobWithMergeJob()
  const { org } = useSession()
  const { setParam, getParam } = useQueryParams()
  const settingsDialogIsOpen = getParam('settings')

  const { data: jobData, refetch: refetchJob } = useJobQuery({ enabled: isNil(job) })
  if (isNil(job)) {
    job = jobData
  }

  const { data: mergeJobs = [], isFetching, isRefetching, refetch } = useGetMergeJobs()

  useEffect(() => {
    if (!settingsDialogIsOpen) {
      void refetch()
    }
  }, [refetch, settingsDialogIsOpen])

  const mergeJobsMap = useMemo(() => {
    const mergeJobMap = new Map<string, SelectItem>()
    for (const mergeJob of mergeJobs) {
      mergeJobMap.set(mergeJob.id, {
        value: mergeJob.id,
        title: mergeJob.name
      })
    }

    return mergeJobMap
  }, [mergeJobs])

  const mergeJobsList = useMemo(() => {
    return [...mergeJobsMap.values()]
  }, [mergeJobsMap])

  const defaultSelectedJob = useMemo(() => {
    return !isNil(job) && !isNil(job.mergeAtsJobId)
      ? mergeJobsMap.get(job.mergeAtsJobId)?.value
      : undefined
  }, [mergeJobsMap, job])

  const { register, formData } = useForm<ExportToAtsSchema>({
    schema: exportToAtsScchema
  })

  const candidateIds = candidateJobs.map((candidateJob) => candidateJob.candidate.id)
  const isPinJobLinkedToAts = !isNil(job?.mergeAtsJobId)

  const renderCandidates = (): JSX.Element => {
    return (
      <>
        <When condition={candidateJobs.length === 1}>
          <Flex
            $direction='row'
            $align='center'
            $gap={16}
          >
            <Avatar
              $size={48}
              $border={false}
              $type='photo'
              photoUrl={candidateJobs[0].candidate.profilePhotoUrl}
            />
            <Flex
              $gap={4}
              $direction='column'
            >
              <Caption $fontSize={32} $lineHeight={1.5}>{candidateJobs[0].candidate.name}</Caption>
              <Paragraph>
                {candidateJobs[0].candidate.experiences?.at(0)?.title} at {candidateJobs[0].candidate.experiences?.at(0)?.company}
              </Paragraph>
            </Flex>
          </Flex>
        </When>
        <When condition={candidateJobs.length > 1}>
          <Flex
            $direction='row'
            $justify='flex-start'
            $align='center'
            $gap={16}
          >
            <Flex
              $direction='row'
              $align='flex-start'
              $justify='flex-start'
              $width='none'
            >
              <AvatarsList
                avatars={candidateJobs.map((candidateJob) => ({
                  id: candidateJob.candidate.id,
                  name: candidateJob.candidate.name,
                  profilePhotoUrl: candidateJob.candidate.profilePhotoUrl
                }))}
                $border
                $size={20}
                $overlap={2}
                avatarDisplay={{ showPlus: false, count: candidateJobs.length }}
              />
            </Flex>
            <Paragraph>{candidateJobs.length} candidates selected</Paragraph>
          </Flex>
        </When>
      </>
    )
  }

  return (
    <Dialog.Root
      id={DialogId.EXPORT_CANDIDATES}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.EXPORT_CANDIDATES, newState: value })
      }}
      $width='half'
      $maxWidth="640px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Export candidate to ATS"
          onClose={() => {
            controlDialog({ id: DialogId.EXPORT_CANDIDATES, newState: false })
          }}
        />
        <Dialog.Content>
          <Flex
            $width='100%'
            $direction="column"
            $gap={24}
          >
            {renderCandidates()}
            {
              isNil(org?.mergeAtsIntegration)
                ? (
                  <Banner $variant="negative" icon="alert-triangle">
                    <Flex $align="center" $justify="space-between">
                      <Paragraph>You need to connect your ATS before exporting candidates.</Paragraph>
                      <Button
                        $variant="raised"
                        $height={24}
                        leadingIcon="plug"
                        $fontSize={12}
                        onClick={() => {
                          setParam('settings', SettingParams.ATS)
                          controlDialog({ id: DialogId.DEFAULT_SETTINGS, newState: true })
                        }}
                      >
                        Connect
                      </Button>
                    </Flex>
                  </Banner>
                  )
                : (
                    <S.SelectiionForm>
                      <Select
                        label='ATS job position'
                        name="mergeJobId"
                        placeholder="Select a job"
                        $marginBottom={0}
                        defaultValue={defaultSelectedJob}
                        items={mergeJobsList}
                        register={register}
                        loading={isFetching || isRefetching}
                      />
                    </S.SelectiionForm>
                  )
            }
          </Flex>
        </Dialog.Content>
        <Dialog.Actions
          $justify={!isPinJobLinkedToAts ? 'space-between' : 'flex-start'}
          divider={!isPinJobLinkedToAts}
        >
          <Flex $justify="space-between">
            <Button
              $variant="raised"
              $colorTheme="normal"
              $height={32}
              onClick={() => {
                controlDialog({ id: DialogId.EXPORT_CANDIDATES, newState: false })
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isNil(formData.mergeJobId) || isNil(job)}
              $variant="fill"
              $colorTheme="tint"
              $height={32}
              onClick={() => {
                if (isNil(job)) {
                  return
                }

                linkJobWithMergeAtsJob({
                  jobId: job.id,
                  mergeAtsJobId: formData.mergeJobId as string,
                  onSuccess: () => {
                    if (isNil(job)) {
                      return
                    }
                    exportCandidates({
                      jobId: job.id,
                      candidateIds,
                      onSuccess: () => {
                        void refetchJob()
                        controlDialog({ id: DialogId.EXPORT_CANDIDATES, newState: false })
                      }
                    })
                  }
                })
              }}
            >
              Export
            </Button>
          </Flex>
        </Dialog.Actions>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
