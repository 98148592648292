import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { setUserRole } from 'src/libs/api/backend/users'
import type { OrgUser, UserRole } from 'src/libs/api/backend/users'
import { isNil } from 'lodash'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'

interface SetUserRoleArgs {
  userId: string
  role: UserRole
  onSuccess?: () => void
  onError?: () => void
}

interface SetUserRoleHookResult {
  updateUserRole: (args: SetUserRoleArgs) => void
}

export const useSetUserRole = (): SetUserRoleHookResult => {
  const queryClient = useQueryClient()
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ userId, role }: SetUserRoleArgs) => {
      await setUserRole(userId, role)
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when updating the user role: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData<OrgUser[]>([queryKeys.orgUsers], (oldOrgUsers) => {
        if (isNil(oldOrgUsers)) {
          return
        }

        return oldOrgUsers.map((user) => {
          if (user.id === variables.userId) {
            return {
              ...user,
              role: variables.role
            }
          }
          return user
        })
      })

      notifySuccess({
        message: 'User role updated successfully.'
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const updateUserRole = (args: SetUserRoleArgs): void => {
    mutation.mutate(args)
  }

  return { updateUserRole }
}
