import { Button } from 'src/components/primitives/button'
import type { EmailSequenceStep } from 'src/models/sequence'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { WaitDays } from './waitdays'
import { Paragraph } from 'src/components/primitives/typography'
import * as S from './sequence-editor.styled'
import { SequenceReply, SequenceStepGenerationState, SequenceStepType } from 'src/libs/api/backend/sequences'
import type { SequenceReplyType } from 'src/libs/api/backend/sequences'
import { Dropdown } from 'src/components/primitives/dropdown'
import { isNil } from 'lodash'
import { MessageComposer } from '../message-composer'
import { SequenceStepTypeSelector } from './sequence-step-type-selector'
import { Icons } from 'src/components/primitives/icon'
import { useCallback, useMemo, useState } from 'react'
import { Form } from 'src/components/forms/form'
// import { Input } from 'src/components/forms/input'
import { TagInput } from 'src/components/primitives/tag-input'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId, openAlertAtom } from 'src/stores/dialogs'

interface SequenceStepProps {
  initialStepBody?: string
  step: EmailSequenceStep
  steps?: EmailSequenceStep[]
  totalSteps: number
  isGenerating?: boolean
  onReplyTypeUpdate?: (replyType: SequenceReplyType) => void
  onRemoveStep: () => void
  onReorder: (newPosition: number) => void
  onDataChanged: (updated: EmailSequenceStep) => void
  onSendTestEmail?: (step: EmailSequenceStep, recipients: string[]) => void
  isSendingTestEmail?: boolean
  onCancelGeneratingEmail?: () => void
  getStepSubjectAndPlaceholder: (stepPosition: number) => {
    subject: string | null
    placeholder: string | null
  }
  forceEditorFocus: boolean
  emailSuggestionFooter?: JSX.Element | null
}

const previewEmailSchema = z.object({
  emailAddresses: z.string().nullish()
})

type PreviewEmailForm = z.infer<typeof previewEmailSchema>

export const SequenceStep = ({
  initialStepBody,
  step,
  steps,
  // totalSteps,
  // isGenerating = false,
  onDataChanged,
  onSendTestEmail,
  isSendingTestEmail = false,
  onReplyTypeUpdate,
  onRemoveStep,
  // onReorder,
  onCancelGeneratingEmail,
  getStepSubjectAndPlaceholder,
  forceEditorFocus,
  emailSuggestionFooter,
  ...emailComposerProps
}: SequenceStepProps): JSX.Element => {
  const closeDialog = useSetAtom(closeDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)

  const { subject, placeholder: subjectPlaceholder } = getStepSubjectAndPlaceholder(step.position)
  const [showPreviewInput, setShowPreviewInput] = useState(false)
  const [testEmailRecipients, setTestEmailRecipients] = useState<string[] | null>(null)

  const initialStepsAreGenerating = useMemo(() => {
    return !isNil(steps) && steps.every((step) => step.generationState === SequenceStepGenerationState.IN_PROGRESS)
  }, [steps])

  const { submit } = useForm<PreviewEmailForm>({
    schema: previewEmailSchema
  })

  const handleSendTestEmail = useCallback((): void => {
    if (isNil(testEmailRecipients) || testEmailRecipients.length <= 0) {
      return
    }
    onSendTestEmail?.(step, testEmailRecipients)
    setShowPreviewInput(false)
  }, [step, testEmailRecipients, onSendTestEmail])

  const currentData = useMemo(() => {
    return {
      ...step,
      subject,
      subjectPlaceholder: subjectPlaceholder === '' ? '(No Subject)' : subjectPlaceholder,
      recipients: undefined,
      body: step.body?.replace(/\n/g, '<br />'),
      sendingEmailAccountId: step.sendingEmailAccountId,
      sendingEmailAlias: step.sendingEmailAlias,
      sendingUserId: step.sendingUserId,
      sendingLinkedInAccountId: step.sendingLinkedInAccountId
    }
  }, [step, subject, subjectPlaceholder])

  const currentStepIsFirstOfTypeEmail = useMemo(() => {
    return steps?.filter(s =>
      s.type === SequenceStepType.AUTOMATED_EMAIL ||
      s.type === SequenceStepType.MANUAL_EMAIL
    )[0]?.position === step.position
  }, [steps, step.position])

  if (step.position === 0) {
    // Do nothing
  }

  return (
    <>
      <S.SequenceStep id={`step-${step.position}`}>
        <S.StepHeader>
          <When condition={step.position === 0}>
            <Paragraph size="XS" $whiteSpace="nowrap">
              When added to outreach
            </Paragraph>
          </When>
          <Flex $width="auto" $align="center" $gap={10} $justify="flex-start">
            <When condition={step.position !== 0}>
              <WaitDays
                waitDays={step.waitDays ?? 0}
                onWaitDaysUpdate={(waitDays: number) => {
                  const updatedStep = {
                    ...step,
                    waitDays
                  }
                  onDataChanged(updatedStep)
                }}
                isGenerating={initialStepsAreGenerating}
              />
            </When>
            <SequenceStepTypeSelector
              type={step.type ?? SequenceStepType.AUTOMATED_EMAIL}
              isGenerating={initialStepsAreGenerating}
              onSequenceStepTypeUpdate={(type) => {
                const updatedStep = {
                  ...step,
                  type
                }
                onDataChanged(updatedStep)
              }}
            />
            <When condition={step.position !== 0 && step.type !== SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE && step.type !== SequenceStepType.MANUAL_TASK}>
              <Dropdown
                disabled={initialStepsAreGenerating || currentStepIsFirstOfTypeEmail}
                trigger={
                  <Button
                    nested
                    trailingIcon={Icons.chevronsUpDownSmall}
                    $height={24}
                    $fontSize={12}
                    $variant="raised"
                    $colorTheme="normal"
                    disabled={initialStepsAreGenerating || currentStepIsFirstOfTypeEmail}
                  >
                    {isNil(step.subject) ? 'Reply on previous thread' : 'Start new thread'}
                  </Button>
                }
                selectedValue={step.subject === null ? SequenceReply.REPLY_TO_PREVIOUS_THREAD : SequenceReply.NEW_THREAD}
                items={[
                  {
                    id: SequenceReply.NEW_THREAD,
                    title: 'Start new thread',
                    onSelect: () => {
                      onReplyTypeUpdate?.(SequenceReply.NEW_THREAD)
                    }
                  },
                  {
                    id: SequenceReply.REPLY_TO_PREVIOUS_THREAD,
                    title: 'Reply on previous thread',
                    onSelect: () => {
                      onReplyTypeUpdate?.(SequenceReply.REPLY_TO_PREVIOUS_THREAD)
                    }
                  }
                ]}
                menuPosition="end"
                size="small"
              />
            </When>
          </Flex>
          <Flex $gap={4} $justify="flex-end">
            <When condition={step.waitDays !== 0}>
              <>
                {/*
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="arrow-up"
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  disabled={step.position === 1}
                  onClick={() => {
                    onReorder(step.position - 1)
                  }}
                />
                <Button
                  $variant="ghost"
                  $colorTheme="muted"
                  leadingIcon="arrow-down"
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  disabled={step.position >= totalSteps}
                  onClick={() => {
                    onReorder(step.position + 1)
                  }}
                />
                */}
                <Button
                  $variant="ghost"
                  $colorTheme="negative"
                  leadingIcon={Icons.trash}
                  $width={24}
                  $height={24}
                  $fontSize={12}
                  onClick={() => {
                    openAlert({
                      message: 'Delete outreach step',
                      description: 'Any scheduled candidates will be moved to the next available step.',
                      confirmText: 'Delete step',
                      onConfirm: () => {
                        onRemoveStep()
                        closeDialog(DialogId.ALERT)
                      }
                    })
                  }}
                />
              </>
            </When>
          </Flex>
        </S.StepHeader>
        <S.StepContent>
          <When condition={showPreviewInput}>
            <S.SendPreviewEmailInputPopup>
              <Paragraph size="XS" $color="fgSecondary">To</Paragraph>
              <Form onSubmit={submit(handleSendTestEmail)}>
                <TagInput
                  placeholder='Email addresses'
                  onTagsChange={(tags) => { setTestEmailRecipients(tags) }}
                  type='email'
                  $height={32}
                  limit={3}
                  hiddenError
                />
                <Flex $gap={8} $align="center" $justify="flex-end" $width="auto">
                  <Button
                    type="submit"
                    $variant="raised"
                    $colorTheme="tint"
                    $height={32}
                    $fontSize={12}
                    leadingIcon="send"
                  >
                    Send preview
                  </Button>
                  <Button
                    type="button"
                    ariaLabel="Close"
                    $variant="ghost"
                    $colorTheme="muted"
                    $fontSize={12}
                    leadingIcon="x"
                    $height={32}
                    $width={32}
                    onClick={() => { setShowPreviewInput(false) }}
                  />
                </Flex>
              </Form>
            </S.SendPreviewEmailInputPopup>
          </When>
          <MessageComposer
            key={`message-composer-${step.position}`}
            forceEditorFocus={forceEditorFocus}
            initialEmailBody={initialStepBody}
            emailSuggestionFooter={emailSuggestionFooter}
            currentData={currentData}
            onDataChanged={(updated) => {
              onDataChanged({
                ...step,
                subject: updated.subject,
                body: updated.body,
                sendingEmailAccountId: updated.sendingEmailAccountId,
                sendingEmailAlias: updated.sendingEmailAlias ?? null,
                sendingUserId: updated.sendingUserId,
                sendingLinkedInAccountId: updated.sendingLinkedInAccountId ?? null,
                type: updated.type,
                personalizationInstructions: updated.personalizationInstructions
              })
            }}
            onCancelGeneratingEmail={onCancelGeneratingEmail}
            useVariables={true}
            usePersonalizationInstructions={true}
            trailingToolbarActions={
              <When condition={currentData.type === SequenceStepType.MANUAL_EMAIL || currentData.type === SequenceStepType.AUTOMATED_EMAIL}>
                <Button
                  $height={24}
                  $variant="flat"
                  $colorTheme="tint"
                  onClick={() => { setShowPreviewInput(true) }}
                  disabled={isSendingTestEmail}
                  loading={isSendingTestEmail}
                >
                  Preview
                </Button>
              </When>
            }
            {...emailComposerProps}
            isGenerating={initialStepsAreGenerating}
          />
        </S.StepContent>
      </S.SequenceStep>
    </>
  )
}
