import type { Color } from 'src/styles/theme/types'
import styled, { css } from 'styled-components'

export const UsageBar = styled.div`
  width: 25rem;
  height: 1rem;
  display: flex;
  align-items: center;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const UsageBarProgress = styled.div<{ $percentage: number, $color: Color, $opacity: number }>`
  width: ${({ $percentage }) => `${$percentage}%`};
  height: 100%;
  background-color: ${({ theme, $color }) => theme.colors[$color]};
  opacity: ${({ $opacity }) => $opacity};
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  ${({ $percentage }) => $percentage >= 100 && css`
    border-radius: 100px;
  `}
`

export const CreditPurchaseInfo = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing[12]};
  gap: ${({ theme }) => theme.spacing[24]};
  width: 100%;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing[6]};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
`

export const SetCreditQuantityButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
  background-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[4]};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  button {
    box-shadow: none;
    &:first-child {
      border-top-left-radius: ${({ theme }) => theme.spacing[4]};
      border-bottom-left-radius: ${({ theme }) => theme.spacing[4]};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-right-radius: ${({ theme }) => theme.spacing[4]};
      border-bottom-right-radius: ${({ theme }) => theme.spacing[4]};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`

export const AmountInput = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  text-align: center;
  outline: none;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const CreditTotal = styled.div`
  display: flex;
  align-items: center;
  min-width: 80px;
  justify-content: flex-end;
`
