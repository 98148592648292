import styled from 'styled-components'

export const OrgJobsDropdown = styled.div<{ $isCollapsed: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    ${({ $isCollapsed }) => !$isCollapsed && `
      width: 100%;
    `
  }
`

export const NoJobsFound = styled.div`
  width: 100%;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`
