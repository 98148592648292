import { useMutation, useQueryClient } from '@tanstack/react-query'
import { sendManualMessage as sendManualMessageApi, EditorMessageType } from 'src/libs/api/backend/sequences'
import type { SendManualMessage } from 'src/libs/api/backend/sequences'
import { pluralize } from 'src/libs/pluralize'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  manualMessage: SendManualMessage
  messageType?: EditorMessageType
  onSuccess?: () => void
}

interface Res {
  sendManualMessage: ({ manualMessage, onSuccess }: Args) => void
}

export const useSendManualMessage = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ manualMessage, messageType }: Args) => {
      return await sendManualMessageApi({ manualMessage, messageType })
    },
    onError: (err) => {
      console.log('err', err.message)
      notifyError({
        message: `An error occurred when sending message(s): ${err.message}`
      })
    },
    onSuccess: async (data, variables) => {
      // Invalidate queries to get fresh data from server
      await Promise.all(
        variables.manualMessage.candidateIds.map(async (candidateId) => {
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.candidateActivities, candidateId]
          })
        })
      )

      if (variables.onSuccess) {
        variables.onSuccess()
      } else {
        closeDialog(DialogId.WRITE_MESSAGE)
        notifySuccess({
          icon: Icons.mails,
          message: `Successfully sent ${pluralize(data.length, variables.messageType === EditorMessageType.LINKEDIN_MAIL ? 'LinkedIn message' : 'email')}`
        })
      }
    }
  })

  const sendManualMessage = (args: Args): void => {
    mutation.mutate(args)
  }

  return { sendManualMessage }
}
