import styled from 'styled-components'
import type { CollapsedVariant } from '.'

export const CandidateDetailsCard = styled.div<{ $isCollapsed: boolean, $isPrintView?: boolean }>`
  width: 100%;
  border-radius: 8px;
  border: solid 1px;
  border-color: ${({ theme, $isCollapsed }) => theme.colors[$isCollapsed ? 'borderTranslucent' : 'bgPrimary']};
  box-shadow: ${({ $isCollapsed }) => !$isCollapsed
    ? '0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02)'
    : 'none'
  };
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  overflow: hidden;
  margin-bottom: 1rem;
  padding-bottom: ${({ $isCollapsed }) => ($isCollapsed ? '0' : '1rem')};
  box-sizing: border-box;
  @media print {
    width: 100%;
    border: none;
    margin-bottom: 0;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    padding: 0;
  }
`

export const Header = styled.header<{ $isPrintView?: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${({ $isPrintView }) => ($isPrintView ? '0' : '1.5rem 1rem 0 1.5rem')};
  gap: 1rem;
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
  padding-top: 8px;
`

export const Content = styled.div<{ $isPrintView?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $isPrintView }) => ($isPrintView ? '0.8rem 0' : '1.5rem')};
`

export const Collapsed = styled.div<{ $variant: CollapsedVariant }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem 1.5rem 1.5rem;

  ${({ $variant, theme }) =>
    $variant === 'loading' &&
    `
      background-color: ${theme.colors.bgTertiary};
    `}
`

export const Loading = styled.div`
  padding: 0.5rem;
`
