import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesAllTable } from 'src/components/tables/candidates-all-table/candidates-all-table'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { When } from 'src/components/blocks/when'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { SEO } from '../../../components/primitives/seo'
import { useSetAtom } from 'jotai'
import { openDialogAtom, DialogId } from 'src/stores/dialogs'
import { ExportCandidatesDropdown } from 'src/components/blocks/export-candidates-dropdown'
import { CandidateExportFileType } from 'src/libs/api/backend/candidate_jobs'
// import { CandidatesInSequenceStats } from 'src/components/blocks/candidates-in-sequence-stats'

interface Props {
  heading?: string
}

const JobCandidatesOverviewPage = ({ heading = 'All Candidates' }: Props): JSX.Element => {
  const openDialog = useSetAtom(openDialogAtom)
  const { data: job } = useJobQuery()

  return (
    <>
      <SEO title="All Candidates" />
      <CandidatesTablePagesContentInner>
        <CandidatesPageHeader
          heading={heading}
          actions={[
            {
              children: 'Add Candidate',
              icon: 'plus',
              // $variant: 'outline',
              $colorTheme: 'tint',
              onClick: () => {
                openDialog({ id: DialogId.ADD_CANDIDATE })
              }
            }
          ]}
          context={
            <>
              <ExportCandidatesDropdown
                exportOptions={[CandidateExportFileType.CSV]}
              />
              {/* <Dropdown
                trigger={
                  <Button
                    nested
                    leadingIcon="more-vertical"
                    $height={24}
                    $width={24}
                    $fontSize={12}
                    $variant="ghost"
                    $colorTheme="muted"
                  />
                }
              items={
                [
                  {
                    id: 'exportToCsv',
                    title: 'Export candidates to CSV',
                    variant: 'neutral',
                    icon: 'file-down',
                    onSelect: () => {
                      if (job?.id) {
                        void exportCandidates({})
                      }
                    }
                  }
                ]
              }
              menuPosition="end"
              size="small"
            /> */}
            </>
          }
        />
        {/* <CandidatesInSequenceStats isFilterable={false} /> */}
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
        <When condition={!job?.deleted}>
          <CandidatesAllTable job={job}/>
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesOverviewPage
