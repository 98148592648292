import { z } from 'zod'
import Api from '..'

const billingCheckoutParser = z.object({
  pricingTableId: z.string(),
  stripePublishableKey: z.string(),
  customerSessionClientSecret: z.string()
})

export type BillingCheckout = z.infer<typeof billingCheckoutParser>

export async function fetchBillingCheckout (): Promise<BillingCheckout> {
  const { data } = await Api.get('/billing/checkout')
  return billingCheckoutParser.parse(data)
}

export enum PriceInterval {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export enum STRIPE_LOOKUP_KEY {
  PROFESSIONAL_MONTHLY = 'professional_monthly',
  PROFESSIONAL_YEARLY = 'professional_yearly',
  CREDIT_MONTHLY = 'credits_monthly',
}

export enum STRIPE_PRODUCT_KEY {
  PROFESSIONAL = 'professional',
  CREDIT = 'credit'
}

export const STRIPE_PROFESSIONAL_KEYS = [
  STRIPE_LOOKUP_KEY.PROFESSIONAL_MONTHLY,
  STRIPE_LOOKUP_KEY.PROFESSIONAL_YEARLY
]

const billingProductPriceParser = z.object({
  id: z.string().nullable(),
  interval: z.nativeEnum(PriceInterval).default(PriceInterval.month),
  unitAmount: z.number().nullable(),
  lookupKey: z.nativeEnum(STRIPE_LOOKUP_KEY).nullable()
})

export type BillingProductPrice = z.infer<typeof billingProductPriceParser>

const billingProductParser = z.object({
  id: z.string().nullable(),
  name: z.string(),
  prices: z.array(billingProductPriceParser),
  description: z.string(),
  features: z.array(z.string())
})

export type BillingProduct = z.infer<typeof billingProductParser>

const creditUsageParser = z.object({
  seatCredits: z.number(),
  purchasedCredits: z.number(),
  rolloverCredits: z.number(),
  creditsUsed: z.number()
})

export type CreditUsage = z.infer<typeof creditUsageParser>

export async function fetchPricing (): Promise<BillingProduct[]> {
  const { data } = await Api.get('/billing/pricing')
  return z.array(billingProductParser).parse(data)
}

const customerSubscriptionsParser = z.object({
  priceId: z.string(),
  productId: z.string(),
  interval: z.nativeEnum(PriceInterval),
  intervalCount: z.number(),
  amount: z.number().nullable(),
  quantity: z.number().optional()
})

export type CustomerSubscription = z.infer<typeof customerSubscriptionsParser>

const orgBillingInfoParser = z.object({
  subscriptionActiveUntil: z.coerce.date().nullish(),
  subscriptions: z.array(customerSubscriptionsParser),
  credit: creditUsageParser,
  orgUsers: z.object({
    activeUsersCount: z.number()
  })
})

export enum CreditUsageType {
  EMAIL_LOOKUP = 'email_lookup',
  PHONE_LOOKUP = 'phone_lookup'
}

export const CREDIT_USAGE_TYPE_COST = {
  [CreditUsageType.EMAIL_LOOKUP]: 2,
  [CreditUsageType.PHONE_LOOKUP]: 4
}

export type OrgBillingInfo = z.infer<typeof orgBillingInfoParser>

export async function fetchOrgBillingInfo (): Promise<OrgBillingInfo> {
  const { data } = await Api.get('/billing/customer_subscriptions')
  return orgBillingInfoParser.parse(data)
}

const stripePricesParser = z.object({
  priceId: z.string(),
  productKey: z.nativeEnum(STRIPE_PRODUCT_KEY),
  lookupKey: z.string().nullable(),
  active: z.boolean(),
  price: z.number().nullable(),
  interval: z.nativeEnum(PriceInterval).nullish(),
  description: z.string().nullable()
})

export type StripePrice = z.infer<typeof stripePricesParser>

export async function fetchStripePricesApi (productKeys: string[]): Promise<StripePrice[]> {
  const { data } = await Api.get('/billing/stripe_prices', { productKeys })
  return z.array(stripePricesParser).parse(data)
}

const checkoutSessionUrlParser = z.object({
  url: z.string()
})

export type CheckoutSessionUrl = z.infer<typeof checkoutSessionUrlParser>

export async function getCheckoutSessionUrlApi (items: Array<{ price: string, quantity: number }>, mode: 'payment' | 'subscription'): Promise<CheckoutSessionUrl> {
  const { data } = await Api.get('/billing/customer_checkout', { items, mode })
  return checkoutSessionUrlParser.parse(data)
}
