import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchOrgBillingInfo } from 'src/libs/api/backend/billing'
import type { OrgBillingInfo } from 'src/libs/api/backend/billing'
import { queryKeys } from 'src/libs/query-keys'

export const useCustomerSubscriptionsQuery = (): UseQueryResult<OrgBillingInfo> => {
  return useQuery({
    queryKey: [queryKeys.customerSubscriptions],
    queryFn: async () => await fetchOrgBillingInfo(),
    staleTime: 60 * 1000
  })
}
