import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import {
  ArchiveDotsSvg,
  ArchivedCandidatesSvg,
  EmailSequenceSvg,
  MessagesSvg,
  ProfileCardSvg,
  ReportChartSvg,
  ScheduleCardsSvg,
  ShortlistedCandidatesSvg,
  UserCardsSvg,
  ArchiveBoxSvg
} from 'src/components/primitives/svgs'
import { When } from 'src/components/blocks/when'
import * as S from './empty-state.styled'
import type { Spacing } from 'src/styles/theme/types'
import { useEffect, useState } from 'react'

type Action = ButtonProps

export interface EmptyStateStylingProps {
  $height?: string
  $padding?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
  $background?: string
  $borderRadius?: string
}

export type Svg =
  | 'userCards'
  | 'scheduleCards'
  | 'messages'
  | 'profileCard'
  | 'emailSequence'
  | 'archiveDots'
  | 'archivedCandidates'
  | 'shortlistedCandidates'
  | 'report'
  | 'archiveBox'

interface EmptyStateProps extends EmptyStateStylingProps {
  svg?: Svg
  heading: string
  description?: string
  actions?: Action[]
  delay?: number
}

export const EmptyState = ({
  svg = 'userCards',
  heading,
  description,
  actions,
  $height = 'full-with-header',
  $padding = { top: 0, left: 0, right: 0 },
  $background = 'inherit',
  $borderRadius = 'auto',
  delay = 0
}: EmptyStateProps): JSX.Element => {
  const [loadingDelay, setLoadingDelay] = useState(delay > 0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingDelay(false)
    }, delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  if (loadingDelay) {
    return <></>
  }

  return (
    <S.EmptyState
      $height={$height}
      $padding={$padding}
      $background={$background}
      $borderRadius={$borderRadius}
    >
      <S.EmptyStateInner>
        <S.Media>
          <When condition={svg === 'userCards'}>
            <UserCardsSvg />
          </When>
          <When condition={svg === 'scheduleCards'}>
            <ScheduleCardsSvg />
          </When>
          <When condition={svg === 'messages'}>
            <MessagesSvg />
          </When>
          <When condition={svg === 'profileCard'}>
            <ProfileCardSvg />
          </When>
          <When condition={svg === 'emailSequence'}>
            <EmailSequenceSvg />
          </When>
          <When condition={svg === 'archiveDots'}>
            <ArchiveDotsSvg />
          </When>
          <When condition={svg === 'archivedCandidates'}>
            <ArchivedCandidatesSvg />
          </When>
          <When condition={svg === 'shortlistedCandidates'}>
            <ShortlistedCandidatesSvg />
          </When>
          <When condition={svg === 'report'}>
            <ReportChartSvg />
          </When>
          <When condition={svg === 'archiveBox'}>
            <ArchiveBoxSvg />
          </When>
        </S.Media>
        <S.Text>
          <Caption as="h2" size="LG" $color="fgSecondary">
            {heading}
          </Caption>
          {description && (
            <Paragraph size="SM" $color="fgSecondary">
              {description}
            </Paragraph>
          )}
          {actions && (
            <S.Actions>
              {actions?.map(({ children, $variant, $colorTheme, ...action }) => (
                <Button
                  key={`${action.ariaLabel}-${action.href}`}
                  $variant={$variant ?? 'raised'}
                  $colorTheme={$colorTheme ?? 'tint'}
                  $height={40}
                  {...action}
                >
                  {children}
                </Button>
              ))}
            </S.Actions>
          )}
        </S.Text>
      </S.EmptyStateInner>
    </S.EmptyState>
  )
}
