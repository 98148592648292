import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { fetchCandidateJob } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { queryKeys } from 'src/libs/query-keys'

interface CandidateJobQueryProps {
  candidateJobId?: string
}

export const useCandidateJobQuery = ({ candidateJobId }: CandidateJobQueryProps = {}): UseQueryResult<CandidateJobExpanded> => {
  return useQuery({
    queryKey: [queryKeys.candidateJob, candidateJobId],
    queryFn: async () => {
      return await fetchCandidateJob(candidateJobId ?? '')
    },
    staleTime: 60 * 1000,
    enabled: !!candidateJobId
  })
}
