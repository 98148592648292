import type { Attachment } from 'src/libs/api/backend/candidate_activities'
import * as S from './attachments-list.styled'
import { getFileIcon } from 'src/utils/file-icon'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Paragraph } from 'src/components/primitives/typography'
import { AttachmentUploadStatus } from './types'
import { Button, Flex } from 'src/components/primitives'
import { isNil } from 'lodash'
import { Tooltip } from 'src/components/primitives/tooltip'

export interface AttachmentUpload extends Attachment {
  status?: AttachmentUploadStatus
  progress?: number
}

interface AttachmentListProps {
  attachments: AttachmentUpload[]
  onDelete?: (attachmentId: string) => void
  onTriggerDownload?: (attachment: Attachment) => Promise<void>
  error?: string | null
}

export const AttachmentList = ({ attachments, onDelete, onTriggerDownload, error }: AttachmentListProps): JSX.Element | null => {
  if (!attachments.length) {
    return null
  }
  return (
    <Flex $direction='column' $gap={6}>
      <S.Attachments>
        {attachments.map((attachment) => {
          const progress =
            attachment.status === AttachmentUploadStatus.UPLOADING
              ? attachment.progress ?? 0
              : attachment.status === AttachmentUploadStatus.PENDING
                ? 0
                : undefined
          return (
            <Tooltip
              key={attachment.id}
              position='top'
              disabled={isNil(progress)}
              trigger={
                <S.Attachment
                  onClick={() => {
                    void onTriggerDownload?.(attachment)
                  }}
                  $error={attachment.status === AttachmentUploadStatus.ERROR}
                  $progress={progress}
                >
                  {!isNil(progress) && (
                    <S.Progress $progress={progress} />
                  )}
                  <Icon name={getFileIcon(attachment.contentType)} size={12} color='fgSecondary' />
                  <Paragraph size='XS' $fontWeight={500}>{attachment.fileName}</Paragraph>
                  {onDelete && (
                    <Button
                      leadingIcon={Icons.x}
                      $width={12}
                      $height={12}
                      $fontSize={12}
                      $colorTheme='muted'
                      $variant='ghost'
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onDelete(attachment.s3Key)
                      }}
                    />
                  )}
                </S.Attachment>
              }
            >
              Uploading
            </Tooltip>

          )
        })}
      </S.Attachments>
      {error && <Paragraph size='XS' $color='negativeFg'>{error}</Paragraph>}
    </Flex>
  )
}
