import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { useSession } from 'src/hooks/use-session'
import { SequenceStepType } from 'src/libs/api/backend/sequences'
import { FeatureFlags } from 'src/libs/api/backend/session'
import * as S from './sequence-step-type-selector.styled'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'

interface EmailSequenceStepTypeProps {
  type: SequenceStepType
  onSequenceStepTypeUpdate: (update: SequenceStepType) => void
  isGenerating: boolean
}

const getTitle = (type: SequenceStepType): string => {
  switch (type) {
    case SequenceStepType.AUTOMATED_EMAIL:
      return 'Send email automatically'
    case SequenceStepType.MANUAL_EMAIL:
      return 'Send email manually from queue'
    case SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE:
      return 'Send LinkedIn Message automatically'
    case SequenceStepType.MANUAL_LINKEDIN_MESSAGE:
      return 'Send LinkedIn Message manually from queue'
    case SequenceStepType.MANUAL_TASK:
      return 'Manual task'
    default:
      return ''
  }
}

const getIcon = (type: SequenceStepType): React.ReactNode => {
  if (type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE || type === SequenceStepType.MANUAL_LINKEDIN_MESSAGE) {
    return <S.BrandIconWrapper><BrandIcon name='linkedinOriginal' color="original" size={12} /></S.BrandIconWrapper>
  }
  if (type === SequenceStepType.MANUAL_TASK) {
    return Icons.penLine
  }
  return Icons.mail
}

export const SequenceStepTypeSelector = (
  { type, onSequenceStepTypeUpdate, isGenerating }: EmailSequenceStepTypeProps
): JSX.Element | null => {
  const { featureFlags } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()

  const linkedInAccounts = useMemo(() => {
    return orgUsers?.flatMap((user) => user.linkedInAccounts) ?? []
  }, [orgUsers])

  const selectableSequenceStepTypes = useMemo(() => {
    let types = [SequenceStepType.AUTOMATED_EMAIL, SequenceStepType.MANUAL_EMAIL]

    if (!!featureFlags && featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION)) {
      types = [...types, SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE, SequenceStepType.MANUAL_LINKEDIN_MESSAGE]
    }

    if (!!featureFlags && featureFlags?.includes(FeatureFlags.MANUAL_TASKS)) {
      types = [...types, SequenceStepType.MANUAL_TASK]
    }

    return types
  }, [featureFlags])

  const DEFAULT_SEQUENCE_STEP_TYPES_OPTIONS = useMemo(() => (
    selectableSequenceStepTypes.map((type) => ({
      id: type,
      type: 'item',
      // icon: type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE || type === SequenceStepType.MANUAL_LINKEDIN_MESSAGE ? <S.BrandIconWrapper><BrandIcon name='linkedinOriginal' color="original" size={12} /></S.BrandIconWrapper> : Icons.mail,
      icon: getIcon(type),
      title: getTitle(type),
      isDisabled: (type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE || type === SequenceStepType.MANUAL_LINKEDIN_MESSAGE) && linkedInAccounts.length === 0,
      itemTooltip: {
        text: !linkedInAccounts?.length ? 'To send messages connect a LinkedIn account in your settings' : undefined,
        position: 'right' as const
      },
      onSelect: () => { onSequenceStepTypeUpdate(type) }
    }))
  ), [onSequenceStepTypeUpdate, selectableSequenceStepTypes, linkedInAccounts])

  if (!featureFlags?.includes(FeatureFlags.MANUAL_OUTREACH)) {
    return null
  }

  return (
    <Dropdown
      disabled={isGenerating}
      trigger={
        <Button
          nested
          disabled={isGenerating}
          $disabled={isGenerating}
          $variant='raised'
          $colorTheme='normal'
          trailingIcon={Icons.chevronsUpDownSmall}
          $height={24}
          $fontSize={12}
        >
          {getTitle(type)}
        </Button>
      }
      menuPosition='end'
      size='small'
      selectedValue={type}
      items={DEFAULT_SEQUENCE_STEP_TYPES_OPTIONS}
    />
  )
}
