import { Flex } from 'src/components/primitives'
import type { BooleanCriteria, CriteriaKey } from 'src/libs/api/backend/candidate_search'
import { CriteriaSelection } from './criteria-selection'
import { RemoveCriteria } from './remove-criteria'
import { CriteriaProperties } from '../constants'
import { useMemo } from 'react'

interface BooleanRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: BooleanCriteria
  onUpdate: (criteriaKey: CriteriaKey, criteriaValue: BooleanCriteria) => void
  onRemove: () => void
  disabled: boolean
}

export const BooleanRow = ({ criteriaKey, criteriaValue, onUpdate, onRemove, disabled }: BooleanRowProps): JSX.Element => {
  const type = useMemo(() => CriteriaProperties.get(criteriaKey)?.type, [criteriaKey])
  if (type === 'boolean-value') {
    return (
      <Flex $gap={8} $align='center'>
        <CriteriaSelection
          value={criteriaValue.optional ?? true}
          onUpdate={(key: string, value: boolean) => {
            onUpdate(criteriaKey, { ...criteriaValue, [key]: value })
          }}
          disabled={disabled}
        />
        <Flex $align='center' $flex='1 1 0'>
          <CriteriaSelection
            value={criteriaValue.value}
            onUpdate={(key: string, value: boolean) => {
              onUpdate(criteriaKey, { ...criteriaValue, [key]: value })
            }}
            disabled={disabled}
            type={type}
            fullWidth
          />
        </Flex>
        <RemoveCriteria onRemove={onRemove} disabled={disabled} />
      </Flex>
    )
  }
  return (
    <Flex $gap={8} $align='center'>
      <Flex $align='center' $flex='1 1 0'>
        <CriteriaSelection
          value={criteriaValue.optional ?? true}
          onUpdate={(key: string, value: boolean) => {
            onUpdate(criteriaKey, { ...criteriaValue, [key]: value })
          }}
          disabled={disabled}
          type={type}
          fullWidth
        />
      </Flex>
      <RemoveCriteria onRemove={onRemove} disabled={disabled} />
    </Flex>
  )
}
