import { DIALOG_HEADER_HEIGHT_LG } from 'src/styles/constants'
import styled from 'styled-components'

export const DialogHeader = styled.header`
  width: 100%;
  height: ${DIALOG_HEADER_HEIGHT_LG};
  padding: 0.75rem 1.5rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 1.5rem;
  overflow: auto;
`

export const ContactInfoContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`
