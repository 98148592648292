import { DivAsButton } from 'src/components/primitives/div-as-button'
import * as S from './search-candidates-dialog.styled'
import type {
  CandidateJobExpanded,
  CandidateJobStatusDisplay
} from 'src/libs/api/backend/candidate_jobs'
import { CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'
import { Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import type { Color } from 'src/styles/theme/types'
import { DepartmentLogo } from 'src/components/blocks/department-logo'
import { Avatar } from 'src/components/primitives/avatar'
import type { Session } from 'src/libs/api/backend/session'
import { useMemo } from 'react'
import { Icon, Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'

interface ListItemProps {
  candidateJob: CandidateJobExpanded
  candidateIndex: number
  isSelected: boolean
  session?: Session | null
  onClick: (candidateJobId: string, targetJobId: string) => void
}

interface GetCandidateStatusReturnType {
  title: string
  color: Color
  icon: IconName
}

export const ListItem = ({ candidateJob, isSelected, session, onClick }: ListItemProps): JSX.Element => {
  const { org, logoUrl: orgLogoUrl } = session ?? {}

  const statusPayload = useMemo((): GetCandidateStatusReturnType => {
    const status: CandidateJobStatusDisplay | null = candidateJob.statusDisplay
    switch (status?.status) {
      case CandidateJobStatus.MISSING_EMAIL:
      case CandidateJobStatus.MISSING_LINKEDIN:
      case CandidateJobStatus.EMAIL_NOT_FOUND:
      case CandidateJobStatus.REJECTED: {
        return {
          title: status.title,
          color: 'negativeFg',
          icon: Icons.mailX
        }
      }
      case CandidateJobStatus.SHORTLISTED: {
        return {
          title: status.title,
          color: 'fgPrimary',
          icon: Icons.star
        }
      }
      case CandidateJobStatus.IN_SEQUENCE: {
        return {
          title: status.title,
          color: 'fgPrimary',
          icon: Icons.mailArrowRight
        }
      }
      default: {
        return {
          title: status?.title ?? 'Sourced',
          color: 'fgPrimary',
          icon: Icons.binoculars
        }
      }
    }
  }, [candidateJob.statusDisplay])

  const department = useMemo(() => candidateJob.job?.department, [candidateJob.job?.department])

  return (
    <S.ResultListItem data-selected={isSelected} role="button" tabIndex={-1}>
      <DivAsButton
        ariaLabel="Go to candidate"
        dataName="inner"
        onClick={() => {
          onClick(candidateJob.id, candidateJob.jobId)
        }}
      >
        <Flex $gap={6} $align="center" $flex='1 1 auto'>
          {department?.id
            ? <DepartmentLogo departmentId={department?.id} $size={16} />
            : <Avatar
                $size={16}
                initials={org?.name}
                photoUrl={orgLogoUrl}
                $border={false}
                $shape="soft"
                $type="logo"
              />
            }
          <Caption size="XS" $color="fgSecondary">
            {department?.name ?? org?.name } - {candidateJob.job?.title}
          </Caption>
        </Flex>
        <S.Status>
          <Icon name={statusPayload.icon} size={12} color={statusPayload.color} />
          <Paragraph size="XS" $color={statusPayload.color} $fontWeight={500} $ellipsis $whiteSpace='nowrap'>
            {statusPayload.title}
          </Paragraph>
        </S.Status>
        <Icon name={Icons.chevronRight} size={12} color="fgSecondary" />
      </DivAsButton>
    </S.ResultListItem>
  )
}
