import styled from 'styled-components'
import { motion } from 'framer-motion'

export const TimelineEntryTitle = styled.div<{ $isCollapsed: boolean, $isCollapsible: boolean }>`
  position: relative;
  width: 100%;
  padding: 0.125rem 0.75rem;
  border-radius: 8px;
  user-select: none;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  &:after {
    content: '';
    position: absolute;
    // top: -4px;
    left: 0;
    width: calc(100% + 1px);
    height: calc(100% + 4px);
    // border-radius: 8px;
    background-color: transparent;
    opacity: 0;
    // transition: all 0.08s ease-in-out;
    z-index: 1;
  }
  &:before {
    content: '';
    display: ${({ $isCollapsed }) => $isCollapsed ? 'none' : 'block'};
    position: absolute;
    width: 2px;
    /* 2.5rem is the height of the avatar  */
    /* 1rem is the safe area to "reach" the dot of the first timeline entry */
    height: calc(100% - 2.5rem + 20px);
    background-color: ${({ theme }) => theme.colors.borderOpaque};
    top: calc(2.5rem + 2px);
    left: -30px;
  }
  ${({ $isCollapsed, $isCollapsible, theme }) =>
    $isCollapsible &&
    $isCollapsed &&
    `
      @media (hover: hover) {
        &:hover {
          // background-color: ${theme.colors.borderTranslucent};
        }
      }
  `}
  ${({ theme, $isCollapsed }) =>
    !$isCollapsed &&
    `
      // padding-bottom: 0.5rem;
      margin-bottom: 0.75rem;
      &:after {
        background-color: transparent;
        border-bottom: 1px solid ${theme.colors.borderOpaque};
        // background-color: ${theme.colors.bgSecondary};
        // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
        opacity: 1;
      }
    `
  }
`

export const HiddenContentFooter = styled.div`
  margin-bottom: 0.5rem;
`

export const Inner = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`

export const VisibleContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
  height: 20px;
`

export const EntryAttributes = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
  width: fit-content;
  flex: 1 0 auto;
`

export const EntryTitle = styled.div`
  min-width: 0;
  width: auto;
  flex: 0 1 auto;
`

export const HiddenContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
`

export const QuickFacts = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  // padding-top: 0.25rem;
  p {
    position: relative;
    &:not(:first-child) {
      &:after {
        content: '';
        position: absolute;
        top: 6px;
        left: -0.55rem;
        width: 2px;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.fgSecondary};
        border-radius: 999px;
      }
    }
  }
`

export const TimelineEntrySocials = styled.div`
  display: none;
`

export const OverlappingEmployees = styled.div`
  position: relative;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderOpaque};
  padding: 2px 4px;
  border-radius: 4px;
  display: flex;
  gap: 3px;
  font-size: 10px;
  // color: ${({ theme }) => theme.colors.fgPrimary};
  text-wrap: nowrap;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  span {
    font-size: inherit;
    color: inherit;
    transform: translateY(-1px);
  }
`

export const OverlappingEmployeesAvatars = styled.div`
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  transform: translate(-2px, -5px);
`
