import styled from 'styled-components'

export const TimelineEntrySubtitle = styled.div<{ $isCollapsed?: boolean }>`
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  padding: 0 0.75rem;
  user-select: none;
  width: 100%;
  padding-bottom: 0.25rem;
  margin-top: ${({ $isCollapsed }) => $isCollapsed ? '-4px' : '0px'};
`

export const DetailsList = styled.ul<{ $isCollapsed?: boolean }>`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $isCollapsed }) => theme.spacing[$isCollapsed ? 4 : 8]};
`

export const ListItemHeading = styled.div`
  width: 100%;
  max-width: 100%;
  display: inline-block;
  align-items: center;
  gap: 0.75rem;
`

export const ListItemHeadingTimeSpan = styled.span`
  margin-left: ${({ theme }) => theme.spacing[12]};
`

export const ListItemTimespan = styled.div`
  border: solid 1px red;
`

export const ListItemDescription = styled.div`
  // max-width: 792px;
  padding-left: ${({ theme }) => theme.spacing[8]};
`

export const DetailsListItem = styled.li<{ $isCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: ${({ $isCollapsed }) => $isCollapsed ? '8px' : '6px'};
    left: -44px;
    width: 6px;
    height: 6px;
    border-radius: 99px;
    background-color: ${({ theme }) => theme.colors.fgTertiary};
    z-index: 2;
  }
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: -42px;
    width: 2px;
    height: calc(100% + 0.4rem + 18px);
    background-color: ${({ theme }) => theme.colors.borderOpaque};
    z-index: 1;
  }
  &:first-child {
    &:after {
      top: ${({ $isCollapsed }) => $isCollapsed ? '4px' : '-10px'};
      height: ${({ $isCollapsed }) => $isCollapsed ? '8px' : 'calc(100% + 0.4rem + 18px)'};
    }
    &:before {
      top: -12px;
      display: ${({ $isCollapsed }) => $isCollapsed ? 'none' : 'block'};
    }
    ${ListItemDescription} {
      margin-top: 0.15rem;
    }
  }
  &:not(:first-child) {
    &:after {
      display: block;
    }
  }
  &:nth-child(2) {
    &:after {
      ${({ $isCollapsed }) => !$isCollapsed && `
        top: 0px;
        height: calc(100% + 0.4rem + 8px);
      `}
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
  &:not(:last-child) {
    ${ListItemDescription} {
      margin-bottom: 0.4rem;
    }
  }
`
