import styled, { css } from 'styled-components'

export const PhoneNumber = styled.li<{ $variant?: 'LIST' | 'BUTTON' }>`
display: flex;
align-items: center;
${({ $variant }) => $variant === 'LIST' && css`
  gap: ${({ theme }) => theme.spacing[12]};
  border-radius: 0.25rem;
  padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[4]}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgTertiary};
  justify-content: space-between;
  width: 100%;
`}
${({ $variant }) => $variant === 'BUTTON' && css`
  gap: 0.375rem;
  padding: 0.625rem 0.375rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  color: ${({ theme }) => theme.colors.fgSecondary};
  * {
    line-height: 1;
  }
  svg {
    stroke: ${({ theme }) => theme.colors.fgSecondary};
  }
`}
`

export const ContactTitle = styled.div`
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  align-items: center;
`

export const EditPhoneNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  form {
    width: 100%;
    display: flex;
    align-items: center;
    div {
      margin-bottom: 0;
    }
    span {
      margin-left: ${({ theme }) => theme.spacing[4]};
      margin-right: ${({ theme }) => theme.spacing[4]};
    }
    input {
      flex: 1 1 1px;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      line-height: ${({ theme }) => theme.lineHeights['1.2']};
      padding: 0;
      margin: 0;
      height: auto;
      font-weight: 400;
    }
    p {
      display: none !important;
    }
  }
`
