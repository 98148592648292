import { Flex } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar'
import { Caption } from 'src/components/primitives/typography'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import type { OrgUser } from 'src/libs/api/backend/users'
import { TableReportNumberCell, DefaultTableHeader, TxtWithArrow, TableReportTextCell } from './report-components'

interface TeamActivityCount {
  userName: string
  userId: string
  opened?: number
  responded?: number
  count?: number
}

interface TeamActivityChartData {
  userName: string
  sourced: number
  rejected: number
  read: number
  replied: number
}

export interface TeamActivityData {
  id: string
  name: string
  photoUrl: string | null | undefined
  sourced: number
  rejected: number
  read: number
  replied: number
}

export const getTeamActivityChartData = (
  teamActivityEmailCounts: TeamActivityCount[],
  teamActivityRejectedCounts: TeamActivityCount[],
  teamActivitySourcedCounts: TeamActivityCount[],
  orgUsers: OrgUser[]
): TeamActivityData[] => {
  // Map to hold the combined data
  const dataMap = new Map<string, TeamActivityChartData>()

  // userId -> email address
  const emailMap = new Map<string, string>()
  const avatarMap = new Map<string, string | null | undefined>()
  orgUsers.forEach((orgUser) => {
    const { id, emailAccounts, profilePhotoUrl } = orgUser
    const email = emailAccounts?.[0]?.email ?? ''
    emailMap.set(id, email)
    avatarMap.set(id, profilePhotoUrl)
  })

  // Process sourced counts
  teamActivitySourcedCounts.forEach(({ userId, userName, count }) => {
    const existing = dataMap.get(userId) ?? {
      userName,
      sourced: 0,
      rejected: 0,
      read: 0,
      replied: 0
    }
    existing.sourced = count ?? 0
    dataMap.set(userId, existing)
  })

  // Process rejected counts
  teamActivityRejectedCounts.forEach(({ userId, userName, count }) => {
    const existing = dataMap.get(userId) ?? {
      userName,
      sourced: 0,
      rejected: 0,
      read: 0,
      replied: 0
    }
    existing.rejected = count ?? 0
    dataMap.set(userId, existing)
  })

  // Process email counts
  teamActivityEmailCounts.forEach(({ userId, userName, opened, responded }) => {
    const existing = dataMap.get(userId) ?? {
      userName,
      sourced: 0,
      rejected: 0,
      read: 0,
      replied: 0
    }
    existing.read = opened ?? 0
    existing.replied = responded ?? 0
    dataMap.set(userId, existing)
  })

  // Transform the map into the desired output structure
  return Array.from(dataMap.entries()).map(
    ([userId, { userName, sourced, rejected, read, replied }]) => ({
      id: userId,
      name: userName,
      photoUrl: avatarMap.get(userId),
      sourced,
      rejected,
      read,
      replied
    })
  )
}

const defaultColumnDef = {
  visible: true,
  size: 'minmax(min-content, 1fr)'
}

export const TeamActivityColumns: Array<TableColumnDef<TeamActivityData>> = [{
  ...defaultColumnDef,
  columnId: 'name',
  size: 'minmax(min-content, 3fr)',
  cell: (info: TeamActivityData) => (
    <Flex $gap={8} $align="center">
      <Avatar
        $type="photo"
        $size={24}
        initials={info.name.charAt(0)}
        photoUrl={info.photoUrl} />
      <Flex $direction="column" $gap={2}>
        <Caption size="XS">{info.name}</Caption>
      </Flex>
    </Flex>
  ),
  customizedHeader: true,
  header: <th><DefaultTableHeader title='Team activity' $justify='flex-start' /></th>
}, {
  ...defaultColumnDef,
  columnId: 'sourced',
  header: <DefaultTableHeader title='Sourced' />,
  cell: (info: TeamActivityData) => (
    <TableReportNumberCell value={info.sourced} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'archived',
  header: <DefaultTableHeader title='Archived' />,
  cell: (info: TeamActivityData) => (
    <TableReportNumberCell value={info.rejected} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'read',
  header: <DefaultTableHeader title='Read' />,
  cell: (info: TeamActivityData) => (
    <TableReportNumberCell value={info.read} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'replied',
  header: <DefaultTableHeader title='Replied' />,
  cell: (info: TeamActivityData) => (
    <TableReportNumberCell value={info.replied} />
  )
}]

export interface TeamActivitySourcedByData {
  id: string
  sourced: number
  source: string
  communicating: number
  sequenced: number
  seqTheme: 'positive' | 'warning' | 'neutral'
  seqTxt: string
  commTheme: 'positive' | 'warning' | 'neutral'
  commTxt: string
}

export const TeamActivitySourcedByColumns: Array<TableColumnDef<TeamActivitySourcedByData>> = [{
  ...defaultColumnDef,
  columnId: 'source',
  size: 'minmax(min-content, 2fr)',
  header: <th><DefaultTableHeader title='Sourced By' $justify='flex-start' /></th>,
  customizedHeader: true,
  cell: (info: TeamActivitySourcedByData) => (
    <TableReportTextCell value={info.source} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'sourced',
  header: <DefaultTableHeader title='Total' />,
  cell: (info: TeamActivitySourcedByData) => (
    <TableReportNumberCell value={info.sourced} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'sequencedPct',
  header: null,
  cell: (info: TeamActivitySourcedByData) => (
    <TxtWithArrow theme={info.seqTheme} txt={info.seqTxt} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'sequenced',
  header: <DefaultTableHeader title='Reached Out' />,
  cell: (info: TeamActivitySourcedByData) => (
    <TableReportNumberCell value={info.sequenced} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'communicatingPct',
  header: null,
  cell: (info: TeamActivitySourcedByData) => (
    <TxtWithArrow theme={info.commTheme} txt={info.commTxt} />
  )
}, {
  ...defaultColumnDef,
  columnId: 'communicating',
  header: <DefaultTableHeader title='Responded' />,
  cell: (info: TeamActivitySourcedByData) => (
    <TableReportNumberCell value={info.communicating} />
  )
}]
