export const REGEX_EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i

export const REGEX_DOMAIN =
  /^(?:https?:\/\/)?(?:www\.)?([^:/\n?]+)\.([a-z.]{2,6})(?:[/\w .-]*)*\/?$/i

export const REGEX_URL = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9][-a-zA-Z0-9@:%._+~#=]{0,253}[a-zA-Z0-9])\.(?!js$)[a-z]{2,}(?:\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?$/i

export const REGEX_VALIDATE_PHONE_NUMBER = /^[+]?[0-9]{0,3}\W?\+[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i

export const REGEX_EMAIL_TAG = /<([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*?)>/gi
