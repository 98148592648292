import { Button, Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import type { BillingProduct, CreditUsage } from 'src/libs/api/backend/billing'
import * as S from './billing-usage.styled'
import { useCallback, useMemo } from 'react'
import { formatCurrency } from 'src/libs/currency'
import { Icons } from 'src/components/primitives/icon'

interface PercentageBarProps {
  percentage: number
}

const PercentageBar = ({ percentage }: PercentageBarProps): JSX.Element => {
  const isFilled = percentage === 100
  return (
    <S.UsageBar>
      <S.UsageBarProgress
        $percentage={percentage < 2 ? 2 : percentage}
        $color={isFilled ? 'negativeBg' : 'warningBg'}
        $opacity={isFilled ? 0.5 : 0.75}
      />
    </S.UsageBar>
  )
}

interface BillingUsageProps {
  creditProduct: BillingProduct
  credit: CreditUsage
  subscriptionActiveUntil?: Date | null
  subscribed?: boolean
  items: Map<string, { price: string, quantity: number }>
  setItems: (items: Map<string, { price: string, quantity: number }>) => void
  minQuantity?: number
  checkoutItems: () => Promise<void>
}

export const BillingUsage = ({ creditProduct, credit, items, setItems, subscribed, checkoutItems, minQuantity = 0 }: BillingUsageProps): JSX.Element | null => {
  const { seatCredits = 0, purchasedCredits = 0, rolloverCredits = 0, creditsUsed = 0 } = credit ?? {}
  const usedSeatCredits = useMemo(() => {
    return Math.min(creditsUsed, seatCredits)
  }, [creditsUsed, seatCredits])

  const boughtCredits = useMemo(() => {
    return rolloverCredits + purchasedCredits
  }, [rolloverCredits, purchasedCredits])

  const remainingSeatCredits = useMemo(() => {
    return creditsUsed >= seatCredits ? 0 : seatCredits - creditsUsed
  }, [creditsUsed, seatCredits])

  const usedBoughtCredits = useMemo(() => {
    if (remainingSeatCredits > 0) {
      return 0
    }
    return Math.min(creditsUsed - seatCredits, boughtCredits)
  }, [remainingSeatCredits, creditsUsed, seatCredits, boughtCredits])

  const remainingBoughtCredits = useMemo(() => {
    return boughtCredits - usedBoughtCredits
  }, [boughtCredits, usedBoughtCredits])

  const currentPrice = creditProduct.prices?.[0]
  const creditUnitAmount = currentPrice?.unitAmount ?? 0
  const numberOfPackages = creditProduct.id ? items.get(creditProduct.id)?.quantity ?? 0 : 0

  const updateCreditQuantity = useCallback((quantity: number) => {
    if (quantity < minQuantity) {
      return
    }
    if (creditProduct.id && currentPrice?.id) {
      const newItems = new Map(items)
      if (quantity <= 0) {
        newItems.delete(creditProduct.id)
        setItems(newItems)
      } else {
        newItems.set(creditProduct.id, { price: currentPrice.id, quantity })
        setItems(newItems)
      }
    }
  }, [minQuantity, creditProduct.id, currentPrice.id, items, setItems])

  return (
    <>
      <Flex $direction='column' $gap={10}>
        <Flex $gap={16} $align='center'>
          <Caption size='SM'>Lookup Credits</Caption>
          <Paragraph size='XS'>Renews monthly</Paragraph>
        </Flex>
        <Flex $gap={16} $align='center'>
          <PercentageBar
            percentage={seatCredits === 0 ? 0 : usedSeatCredits / seatCredits * 100}
          />
          <Flex $gap={8} $align='center'>
            <Paragraph size='XS' $whiteSpace='nowrap'>{usedSeatCredits} of {seatCredits} seat credits spent </Paragraph>
            <Paragraph size='XS'>·</Paragraph>
            <Paragraph size='XS' $whiteSpace='nowrap'>{remainingSeatCredits} remaining</Paragraph>
          </Flex>
        </Flex>
        {boughtCredits > 0 &&
          <Flex $gap={16} $align='center'>
            <PercentageBar
              percentage={usedBoughtCredits / boughtCredits * 100}
            />
            <Flex $gap={8} $align='center' $flex='1 1 auto'>
              <Paragraph size='XS' $whiteSpace='nowrap'>{usedBoughtCredits} of {boughtCredits} purchased credits spent</Paragraph>
              <Paragraph size='XS'>·</Paragraph>
              <Paragraph size='XS' $whiteSpace='nowrap'>{remainingBoughtCredits} remaining</Paragraph>
            </Flex>
          </Flex>
        }
      </Flex>
      {subscribed && (
        <S.CreditPurchaseInfo>
          <Flex $flex='1 1 auto' $width='fit-content'>
            <Caption size='XS'>+ 500 Lookup Credits</Caption>
          </Flex>
          <Flex $width='fit-content' $align='center' $gap={24}>
            {creditUnitAmount && (
              <Caption size='XS'>{formatCurrency(creditUnitAmount, 0)}</Caption>
            )}
            <S.SetCreditQuantityButtonGroup>
              <Button
                $variant='raised-light'
                $height={24}
                $width={24}
                leadingIcon={Icons.minus}
                $fontSize={12}
                onClick={() => {
                  updateCreditQuantity(numberOfPackages - 1)
                }}
              />
              <S.AmountInput
                value={numberOfPackages}
                type='number'
                min={minQuantity}
                onChange={(e) => {
                  if (e.target.value) {
                    updateCreditQuantity(parseInt(e.target.value))
                  }
                }}
              />
              <Button
                $variant='raised-light'
                $height={24}
                $width={24}
                leadingIcon={Icons.plus}
                $fontSize={12}
                onClick={() => {
                  updateCreditQuantity(numberOfPackages + 1)
                }}
              />
            </S.SetCreditQuantityButtonGroup>
            <S.CreditTotal>
              <Caption size='XS'>
                Total: {formatCurrency(numberOfPackages * (creditUnitAmount ?? 0), 0)}
              </Caption>
            </S.CreditTotal>
            {subscribed && (
              <Button
                $variant='fill'
                $colorTheme='tint'
                $height={24}
                $fontSize={12}
                onClick={() => {
                  void checkoutItems()
                }}
                disabled={numberOfPackages === 0}
              >
                Buy Now
              </Button>
            )}
          </Flex>
        </S.CreditPurchaseInfo>
      )}
      <Paragraph size='XS'>Email lookups cost 2 credits. Phone number lookups cost 4 credits.</Paragraph>
    </>
  )
}
