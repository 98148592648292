import styled from 'styled-components'

export const Plan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
`

export const SwitchToAnnualPayment = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  color: ${({ theme }) => theme.colors.tintFg};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights[1]};
`

export const Discount = styled.div`
  height: 14px;
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  border-radius: 2px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.positiveBg};
  color: ${({ theme }) => theme.colors.positiveContrast};
  font-size: ${({ theme }) => theme.fontSizes[10]};
  font-weight: 500;
`

export const Link = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.fgSecondary};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeights[1]};
  text-decoration-line: underline;
`

export const ContactSales = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  gap: ${({ theme }) => theme.spacing[2]};
`

export const CurrentTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 32px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.fgTranslucent5};
  color: ${({ theme }) => theme.colors.fgSecondary};
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: 500;
`

export const NotificationBanner = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[20]}`};
  align-items: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing[8]};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const DiscountedTag = styled.div`
  display: flex;
  height: 16px;
  padding: 0px 4px;
  align-items: center;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.positiveFaded};
`

export const OriginalPrice = styled.div`
  text-decoration-line: line-through;
  color: ${({ theme }) => theme.colors.fgTertiary};
`
