import type { LinkedInAccount } from 'src/libs/api/backend/users'
import * as S from './connected-account-card.styled'
import { Button } from 'src/components/primitives/button'
import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { useMeLinkedInAccounts } from 'src/hooks/use-me-linked-in-accounts'
import { BrandIcon } from 'src/components/primitives/brand-icon'
// import { StandaloneSwitch } from '../standalone-switch'
// import { Dropdown } from 'src/components/primitives/dropdown'
// import RouteBuilder from 'src/libs/route-builder'
// import { pluralize } from 'src/libs/pluralize'
// import { When } from '../when'
// import { useSetLinkedInAccountTeamSendAsPermission } from 'src/hooks/mutations/use-set-linkedin-account-team-send-as-permission'

interface ConnectedAccountCardProps {
  account: LinkedInAccount
}

export const ConnectedAccountCard = ({ account }: ConnectedAccountCardProps): JSX.Element => {
  const { deleteAccount } = useMeLinkedInAccounts()
  // const { setLinkedInAccountTeamSendAsPermission } = useSetLinkedInAccountTeamSendAsPermission()
  // const usedInJobPositions = []

  return (
    <S.ConnectedAccount>
      <S.Inner>
        <S.AccountDetails>
          {account.linkedInProfilePictureUrl
            ? (
                <S.LinkedInAvatar>
                  <img src={account.linkedInProfilePictureUrl} alt="LinkedIn profile picture" />
                </S.LinkedInAvatar>
              )
            : (
                <BrandIcon name="linkedinOriginal" color="original" />
              )}
          <Flex $gap={16} $align="center">
            <Caption size="SM">{account.unipileUserName}</Caption>
          </Flex>
          <Flex $gap={12} $align="center" $justify="flex-end">
            <Flex $flex="1" $justify="flex-end">
              <Button
                $variant="ghost"
                $colorTheme="muted"
                ariaLabel="Disconnect LinkedIn account"
                $height={24}
                $fontSize={12}
                onClick={async () => {
                  await deleteAccount()
                }}
              >
                Disconnect
              </Button>
            </Flex>
          </Flex>
        </S.AccountDetails>
        {/* <S.AccountSettings>
          <S.AccountSettingsSection>
            <S.ConnectedAccountPermissions>
              <Flex $direction="row">
                <StandaloneSwitch
                  name="teamSendAsPermission"
                  label="Allow team to send LinkedIn messages from this account"
                  onChange={(switchState) => {
                    setLinkedInAccountTeamSendAsPermission({ linkedInAccountId: '123', teamSendAsPermission: switchState })
                  }}
                  // defaultChecked={account.teamSendAsPermission ?? false}
                  defaultChecked={false}
                  $direction="row-reverse"
                  $variant="ghost"
                />
              </Flex>
            </S.ConnectedAccountPermissions>
          </S.AccountSettingsSection>
          <S.AccountSettingsSection>
            <S.ConnectedAccountInUse>
              <When condition={usedInJobPositions.length > 0}>
                <Paragraph size="XS" $color="fgSecondary">
                  This account is used in outreach sequences for{' '}
                  <Dropdown
                    trigger={
                      <S.JobsListTrigger>
                        {pluralize(1, 'job position')}.
                      </S.JobsListTrigger>
                    }
                    items={[
                      {
                        title: 'Job position 1',
                        href: RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId: '1' })
                      }
                    ]}
                    size="small"
                  />
                </Paragraph>
              </When>
              <When condition={!usedInJobPositions.length}>
                <Paragraph size="XS" $color="fgSecondary">
                  This account is not used in any outreach sequences.
                </Paragraph>
              </When>
            </S.ConnectedAccountInUse>
          </S.AccountSettingsSection>
        </S.AccountSettings> */}
      </S.Inner>
    </S.ConnectedAccount>
  )
}
