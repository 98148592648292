interface Result {
  name: string | null
  email: string | null
}

export const cleanSenderAndEmail = (sender: string): Result => {
  if (sender.includes('<')) {
    const name = sender.split('<')[0].trim() || null
    const email = sender.split('<')[1].replace('>', '').trim() || null
    return { name, email }
  }

  return {
    name: sender || null,
    email: null
  }
}
