import { Badge } from 'src/components/primitives/badge'
import { Caption } from 'src/components/primitives/typography'
import * as S from './candidate-emails-list.styled'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { copyToClipboard } from 'src/utils/copy-to-clipboard'
import { useEffect, useState } from 'react'
import { useUpdateCandidate } from 'src/hooks/mutations/use-update-candidate'
import { EditEmail } from './edit-email'
import { IfElse } from '../if-else'
import { Flex } from 'src/components/primitives'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { Icon, Icons } from 'src/components/primitives/icon'

interface CandidateEmailsListProps {
  candidateJob: CandidateJobExpanded
}

export const CandidateEmailsList = ({ candidateJob }: CandidateEmailsListProps): JSX.Element => {
  const [isEditable, setIsEditable] = useState<string | null>(null)
  const [emailAddresses, setEmailAddresses] = useState<string[]>([])
  const { updateCandidateJob } = useUpdateCandidate(candidateJob.id)
  const openDialog = useSetAtom(openDialogAtom)

  useEffect(() => {
    if (candidateJob.candidate.emails) {
      setEmailAddresses(candidateJob.candidate.emails)
    }
    // setEmailAddresses(['test@texample.com', 'test2@texample.com', 'test3@example.com'])
  }, [candidateJob.candidate.emails])

  const handleUpdate = (emails: string[]): void => {
    try {
      updateCandidateJob({
        updatedCandidate: {
          id: candidateJob.candidate.id,
          name: candidateJob.candidate.name,
          location: candidateJob.candidate.location,
          emails
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  if (emailAddresses.length <= 0) {
    return <></>
  }

  return (
    <>
      {emailAddresses.map((email, index) => (
        <S.EmailAddress key={email}>
          <S.ContactTitle>
            <Icon name={Icons.mail} size={12} />
            <IfElse
              condition={isEditable === email}
              ifNode={
                <EditEmail
                  defaultValue={email}
                  onUpdate={(value) => {
                    const updated = [...emailAddresses]
                    updated[index] = value
                    setEmailAddresses(updated)
                    handleUpdate(updated)
                  }}
                  onCancel={() => {
                    setIsEditable(null)
                  }}
                />
              }
              elseNode={
                <Caption size="XS" $align="left" $fontWeight={400}>
                  {email}
                </Caption>
              }
            />
          </S.ContactTitle>
          <Flex $direction="row" $gap={8} $align="center" $width="auto">
            {index === 0 && (
              <Badge $variant="plain" $transform="capitalize">
                Primary
              </Badge>
            )}
            <Dropdown
              trigger={
                <Button
                  nested
                  leadingIcon={Icons.moreVertical}
                  $width={16}
                  $height={16}
                  $fontSize={12}
                  $variant="ghost"
                  $colorTheme="muted"
                  disabled={isEditable === email}
                />
              }
              items={[
                {
                  id: 'copy-email',
                  title: 'Copy',
                  icon: 'copy',
                  onSelect: async () => {
                    await copyToClipboard(email)
                  }
                },
                {
                  id: 'write-message',
                  title: 'Write message',
                  icon: 'mail',
                  onSelect: () => {
                    openDialog({ id: DialogId.WRITE_MESSAGE, payload: [candidateJob] })
                  }
                },
                {
                  id: 'separator',
                  title: 'ActionsSeparator',
                  type: 'separator'
                },
                {
                  id: 'make-primary',
                  title: 'Make primary',
                  icon: 'listOrdered',
                  isDisabled: index === 0,
                  isSelectable: index >= 1,
                  onSelect: () => {
                    const items = [...emailAddresses]
                    const item = items.splice(index, 1)[0]
                    items.unshift(item)
                    setEmailAddresses(items)
                    handleUpdate(items)
                  }
                },
                {
                  id: 'edit',
                  title: 'Edit',
                  icon: 'pen-square',
                  onSelect: () => {
                    setIsEditable(email)
                  }
                },
                {
                  id: 'remove',
                  title: 'Remove',
                  icon: 'trash',
                  variant: 'negative',
                  onSelect: () => {
                    const updated = [...emailAddresses]
                    updated.splice(index, 1)
                    setEmailAddresses(updated)
                    handleUpdate(updated)
                  }
                }
              ]}
              menuPosition="end"
              size="small"
            />
          </Flex>
        </S.EmailAddress>
      ))}
    </>
  )
}
