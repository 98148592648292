import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { updateCandidate } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded, UpdateCandidate } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  updatedCandidate: UpdateCandidate
}

interface ReturnType {
  updateCandidateJob: (args: Args) => void
}

export const useUpdateCandidate = (candidateJobId: string): ReturnType => {
  const queryClient = useQueryClient()
  const { jobId } = useParams()

  const notifyError = useSetAtom(notifyErrorAtom)
  const closeDialog = useSetAtom(closeDialogAtom)

  const mutation = useMutation({
    mutationFn: async ({ updatedCandidate }: Args) => {
      if (isNil(jobId)) {
        throw new Error('No update data')
      }

      return await updateCandidate(updatedCandidate)
    },
    onSuccess: async (data) => {
      void queryClient.setQueryData<CandidateJobExpanded>(
        [queryKeys.candidateJob, candidateJobId],
        (oldData) => {
          if (isNil(oldData)) {
            return oldData
          }
          return {
            ...oldData,
            candidate: data
          }
        }
      )
      closeDialog(DialogId.EDIT_CANDIDATE)
    },
    onError: (err) => {
      console.error(err)
      notifyError({
        message: `An error occurred when updating your candidate: ${err.message}`,
        autoClose: true
      })
    },
    onSettled: (data) => {
      void Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, candidateJobId]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidate, data?.id]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, jobId, { errored: true }]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobCounts, jobId]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJob, candidateJobId]
        })
      ])
    }
  })

  const updateCandidateJob = ({ updatedCandidate }: Args): void => {
    mutation.mutate({ updatedCandidate })
  }

  return { updateCandidateJob }
}
