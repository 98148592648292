import * as OL from 'src/components/forms/options-list/options-list.styled'
import { Caption } from '../primitives/typography'
import { Button, Flex } from '../primitives'
import { SenderSelection } from '../blocks/sender-selection'
import type { EmailAccountWithProfilePhoto } from '../blocks/sender-selection'
import type { OrgUser } from 'src/libs/api/backend/users'
import { useEffect, useMemo, useState } from 'react'
import { isNil } from 'lodash'
import { Icons } from '../primitives/icon'

interface TransferArchiveUserConfirmationProps {
  onClick: (selectedEmailAccountId: string) => void
  onCancel?: () => void
  orgUsers: OrgUser[]
  orgUser: OrgUser
}

export const TransferArchiveUserConfirmation = ({ onClick, orgUsers, orgUser, onCancel }: TransferArchiveUserConfirmationProps): JSX.Element => {
  const userEmailAccounts = useMemo(() => {
    return orgUsers.filter((curr) => curr.id !== orgUser.id).flatMap((user) => {
      return user.emailAccounts.map((emailAccount) => ({
        ...emailAccount,
        profilePhotoUrl: user.profilePhotoUrl
      }))
    }) ?? []
  }, [orgUser.id, orgUsers])
  const [selectedEmailAccount, setSelectedEmailAccount] = useState<EmailAccountWithProfilePhoto | undefined>(undefined)

  useEffect(() => {
    if (isNil(selectedEmailAccount) && userEmailAccounts.length > 0) {
      setSelectedEmailAccount(userEmailAccounts.find((emailAccount) => emailAccount.currentUserHasSendAsPermission))
    }
  }, [orgUser.emailAccounts, selectedEmailAccount, userEmailAccounts])

  return (
    <OL.OptionConfirmation
      initial={{ scale: 0.98, y: 1, opacity: 0 }}
      animate={{ scale: 1, y: 0, opacity: 1 }}
      exit={{ scale: 0.98, y: 1, opacity: 0 }}
      transition={{ type: 'spring', duration: 0.25 }}
    >
      <OL.OptionConfirmationInner>
        <OL.OptionConfirmationTitle>
          <Caption size='XS' $ellipsis>{selectedEmailAccount?.email ?? "This user's email"} is used in email sequences</Caption>
          <Caption size='XS' $color='fgSecondary' $ellipsis>Transfer them to another user before disabling.</Caption>
        </OL.OptionConfirmationTitle>
        <Flex $align='center' $gap={4} $width='auto'>
          <SenderSelection
            handleSenderChange={({ emailAccount }) => { setSelectedEmailAccount(emailAccount) }}
            selectedEmailAccount={selectedEmailAccount}
            selectedEmailAlias={null} // TODO: double check this
            selectedLinkedInAccountId={null} // TODO: fix this
            linkedInAccounts={[]} // TODO: fix this
            userEmailAccounts={userEmailAccounts}
          />
          <Button
            $variant='raised-light'
            $colorTheme='negative'
            $height={24}
            $fontSize={12}
            onClick={() => {
              if (selectedEmailAccount) {
                onClick(selectedEmailAccount.id)
              }
            }}
          >
            Transfer & Disable
          </Button>
          <Button
            ariaLabel="Cancel"
            leadingIcon={Icons.x}
            $variant='ghost'
            $colorTheme='muted'
            $height={24}
            $width={24}
            $fontSize={12}
            onClick={onCancel}
          />
        </Flex>
      </OL.OptionConfirmationInner>
    </OL.OptionConfirmation>
  )
}
