import { z } from 'zod'
import Api, { ApiError } from '../'
import type { CandidateJob } from './candidate_jobs'

export enum CustomOutreachType {
  EMAIL = 'EMAIL',
  LINKEDIN = 'LINKEDIN'
}

const buildCustomOutreachParser = z.union([
  z.object({
    type: z.literal(CustomOutreachType.EMAIL),
    subject: z.string(),
    bodyHtml: z.string(),
    sendingUserId: z.string().uuid(),
    sendingEmailAccountId: z.string().uuid(),
    sendingEmailAlias: z.string().nullable()
  }),
  z.object({
    type: z.literal(CustomOutreachType.LINKEDIN),
    subject: z.string(),
    bodyHtml: z.string(),
    sendingUserId: z.string().uuid(),
    sendingLinkedInAccountId: z.string().uuid()
  })
])

export type BuildCustomOutreach = z.infer<typeof buildCustomOutreachParser>

export interface BuildCustomOutreachParams {
  candidateJobId: CandidateJob['id']
}

export async function buildCustomOutreach ({
  candidateJobId
}: BuildCustomOutreachParams): Promise<BuildCustomOutreach> {
  const { data } = await Api.post(`/candidate_jobs/${candidateJobId}/custom_outreach/build`)
  return buildCustomOutreachParser.parse(data)
}

const emailContentSerializer = z.object({
  subject: z.string(),
  body: z.string()
})

export type CustomOutreachEmail = z.infer<typeof emailContentSerializer>

const sendCustomOutreachBodySerializer = z.object({
  candidateJobId: z.string().uuid(),
  subject: z.string(),
  bodyHtml: z.string()
})

export type SendCustomOutreachBody = z.infer<typeof sendCustomOutreachBodySerializer>

export interface SendCustomOutreachParams {
  candidateJobId: CandidateJob['id']
  subject: string
  bodyHtml: string
}

export async function sendCustomOutreach ({
  candidateJobId,
  subject,
  bodyHtml
}: SendCustomOutreachParams): Promise<void> {
  const res = await Api.post(`/candidate_jobs/${candidateJobId}/custom_outreach/send`, null, {
    subject,
    bodyHtml
  })
  if (res.status !== 200) {
    throw new ApiError(`${res.status} - Error while attempting to send custom outreach message`)
  }
}
