import styled from 'styled-components'

export const ContactTitle = styled.div`
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  align-items: center;
`

export const EmailAddress = styled.li`
  border-radius: 0.25rem;
  padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[4]}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgTertiary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[12]};
  width: 100%;
`

export const EditEmail = styled.div`
  display: inline-flex;
  flex: 1;
  height: 100%;
  form {
    display: inline-flex;
    flex: 1 1 1px;
    align-items: center;
    width: auto;
    height: 100%;
    div {
      margin: 0 !important;
      flex: 1 1 1px;
    }
    input {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      line-height: ${({ theme }) => theme.lineHeights['1.2']};
      padding: 0;
      margin: 0;
      height: auto;
      font-weight: 400;
    }
    p {
      display: none !important;
    }
  }
`
