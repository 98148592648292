import type { Background } from './types'

export const backgrounds: Record<Background, string> = {
  aiGradientFaded:
    'linear-gradient(267deg, rgba(170, 145, 240, 0.10) -0.11%, rgba(179, 193, 241, 0.10) 99.81%)',
  aiGradientPastel: 'linear-gradient(94deg, #AA91F0 6.15%, #B3C1F1 93.85%)',
  aiGradientVivid: 'linear-gradient(94deg, #8269C8 6.15%, #8B99C9 93.85%)',
  aiGradientTranslucent10: 'linear-gradient(267deg, rgba(206, 150, 239, 0.10) -0.11%, rgba(130, 146, 240, 0.10) 99.81%)',
  purpleGradientFadeOutToBottom: 'linear-gradient(180deg, rgba(252, 252, 253, 0.00) 0%, #FCFCFD 100%), linear-gradient(267deg, rgba(206, 150, 239, 0.10) -0.11%, rgba(130, 146, 240, 0.10) 99.81%), #F7F7F8'
}
