import styled from 'styled-components'

interface InboxMessageEditorProps {
  $isAiGeneratedEmail?: boolean
}

export const InboxMessageEditor = styled.div`
  position: relative;
`

export const Editor = styled.div<InboxMessageEditorProps>`
  border: dashed 1px;
  border-color: ${({ theme, $isAiGeneratedEmail }) =>
    theme.colors[$isAiGeneratedEmail ? 'tintFg' : 'borderTranslucent']};
  border-radius: 6px;
  overflow: hidden;
`

export const CloseButton = styled.div<InboxMessageEditorProps>`
  position: absolute;
  top: ${({ $isAiGeneratedEmail }) => ($isAiGeneratedEmail ? '0.85rem' : '1.1rem')};
  right: 1rem;
  display: flex;
  z-index: 2;
`

export const EditorInner = styled.div``

export const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.2rem;
`

export const AiGeneratedBanner = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  background: linear-gradient(
    90deg,
    rgba(0, 167, 255, 0.05) 0%,
    rgba(26, 180, 132, 0.05) 49.64%,
    rgba(131, 113, 242, 0.05) 100%
  );
`
