import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { GroupLabel } from './group-label'
import { Trigger } from './trigger'
import { sleep } from 'src/libs/sleep'
import { useSession } from 'src/hooks/queries/use-session'
import { isNil } from 'lodash'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import * as S from './org-jobs-dropdown.styled'
import { ChevronRight } from 'lucide-react'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Badge } from 'src/components/primitives/badge'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
import { useJobStatsQuery } from 'src/hooks/queries/use-job-stats'
import { useCallback, useMemo } from 'react'
import { Button } from 'src/components/primitives/button'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import type { JobGroupedByDepartment } from 'src/libs/api/backend/jobs'
import { useProjectsQuery } from 'src/hooks/queries/use-projects'
import { Caption } from 'src/components/primitives/typography'

interface OrgJobsDropdownProps {
  isCollapsed: boolean
}

const SPECIAL_ROUTES: Array<[RegExp, string]> = [
  [/\/inbox.*/, '/inbox/candidate_jobs'],
  [/\/sourcing.*/, '/sourcing']
]

const getTargetPath = (pathname: string, jobId: string | undefined, newJobId: string): string => {
  if (jobId && newJobId) {
    let newPath = pathname.replace(jobId, newJobId)
    for (const [regex, replacement] of SPECIAL_ROUTES) {
      if (regex.test(newPath)) {
        newPath = newPath.replace(regex, replacement)
        break
      }
    }
    return newPath
  }
  // Unlikely to reach here
  return `/jobs/${newJobId}/candidates/sourcing`
}

export const OrgJobsDropdown = ({ isCollapsed }: OrgJobsDropdownProps): JSX.Element => {
  const navigate = useNavigate()
  const { data: session } = useSession()
  const { pathname } = useLocation()
  const { isPending, data: jobsByDepartment } = useJobByDepartmentsQuery()
  const { data: jobStats } = useJobStatsQuery()
  const { data: projects } = useProjectsQuery()

  const numberOfTotalJobs = useMemo(() => {
    if (!jobsByDepartment) return 0
    return Object.values(jobsByDepartment)
      .flatMap(projectsObj => Object.values(projectsObj))
      .flat()
      .length
  }, [jobsByDepartment])

  const numberOfActiveJobs = useMemo(() => {
    if (!jobsByDepartment) return 0
    return Object.values(jobsByDepartment)
      .flatMap(projectsObj => Object.values(projectsObj))
      .flat()
      .filter((job) => !job.deleted)
      .length
  }, [jobsByDepartment])

  const { jobId } = useParams()
  const { org, logoUrl: orgLogoUrl } = session ?? {}
  const openDialog = useSetAtom(openDialogAtom)

  const JOBS_COUNT_WHEN_TO_MAKE_CREATE_BUTTON_STICKY = 10

  const handleCreateNewJobClick = useCallback(async (): Promise<void> => {
    await sleep(10)
    // openDialog(DialogId.CREATE_JOB_POSITION)
    openDialog({ id: DialogId.CREATE_NEW_JOB })
  }, [openDialog])

  const jobs = useMemo((): Array<JobGroupedByDepartment[keyof JobGroupedByDepartment][string][number]> => {
    return !isNil(jobsByDepartment)
      ? Object.values(jobsByDepartment).flatMap((projectsObj) => Object.values(projectsObj).flat())
      : []
  }, [jobsByDepartment])

  const jobDropdownItems = useMemo((): MenuItemProps[] => {
    if (isNil(org) || isNil(jobsByDepartment)) {
      return []
    }

    const result: MenuItemProps[] = []
    const allArchivedJobs: MenuItemProps[] = []

    const departments = Object.keys(jobsByDepartment)
    departments.sort((a, b) => {
      if (a === 'null') return -1
      if (b === 'null') return 1

      const deptAJobs = jobsByDepartment[a]?.null?.[0]
      const deptBJobs = jobsByDepartment[b]?.null?.[0]

      const aTitle = deptAJobs?.department?.name ?? ''
      const bTitle = deptBJobs?.department?.name ?? ''

      return aTitle.localeCompare(bTitle)
    })

    for (const key of departments) {
      const departmentJobs = jobsByDepartment[key]
      if (isNil(departmentJobs)) {
        continue
      }

      // Show empty state when there are no active jobs
      if (numberOfActiveJobs === 0) {
        result.push({
          id: crypto.randomUUID(),
          type: 'node',
          title: 'No jobs found',
          content: (
            <S.NoJobsFound>
              <Icon name="binoculars" color="fgTertiary" />
              <Caption size="XS" $color="fgTertiary">This organization has no active jobs</Caption>
            </S.NoJobsFound>
          )
        })
      }

      const deptLabel = {
        id: key,
        title: <GroupLabel org={org} orgLogoUrl={orgLogoUrl} department={departmentJobs.null?.[0]?.department ?? null} />,
        type: 'label'
      }

      const departmentHasActiveJobs = Object.values(departmentJobs)
        .flatMap(projectJobs => projectJobs)
        .some(job => !job.deleted)

      if (numberOfActiveJobs > 0 && departmentHasActiveJobs) {
        result.push(deptLabel)
      }

      for (const projectKey in departmentJobs) {
        const projectJobs = departmentJobs[projectKey]
        if (projectJobs.length === 0) continue

        const getProjectTitle = (projectKey: string): string => {
          const project = projects?.find((project) => project.id === projectKey)
          const title = project?.name ?? projectKey
          return title.length > 40 ? title.slice(0, 40) + '…' : title
        }

        if (projectKey !== 'null' || Object.keys(departmentJobs).length > 1) {
          result.push({
            id: `${key}-${projectKey}`,
            title: projectKey === 'null' ? '(No Project)' : getProjectTitle(projectKey),
            type: 'sublabel'
          })
        }

        const jobLabels = []

        for (const job of projectJobs) {
          const numCandidatesAwaitingResponse = jobStats?.[job.id]?.numCandidatesAwaitingResponse ?? 0
          const hasDeactivatedSenders = jobStats?.[job.id]?.hasDeactivatedSenders ?? false
          const hasPausedSequence = jobStats?.[job.id]?.hasPausedSequence ?? false
          const jobLabel = {
            id: job.id,
            title: job.title,
            value: job.id,
            href: getTargetPath(pathname, jobId, job.id),
            trailingIcon: (
              <Flex $gap={6} $align="center" $justify="flex-end">
                <When condition={hasDeactivatedSenders}>
                  <Button
                    nested
                    $variant="ghost"
                    $colorTheme="negative"
                    $width={12}
                    $height={12}
                    $fontSize={12}
                    leadingIcon="alert-triangle"
                    tooltip={{
                      text: 'Email account in outreach is disconnected',
                      position: 'right'
                    }}
                  />
                </When>
                <When condition={hasPausedSequence}>
                  <Button
                    nested
                    $variant="ghost"
                    $colorTheme="warning"
                    $width={12}
                    $height={12}
                    $fontSize={12}
                    leadingIcon="pause-circle"
                    tooltip={{
                      text: 'Outreach is paused',
                      position: 'right'
                    }}
                  />
                </When>
                <When condition={numCandidatesAwaitingResponse > 0}>
                  <Badge $variant="tint">{numCandidatesAwaitingResponse}</Badge>
                </When>
              </Flex>
            )
          }

          if (job.deleted) {
            allArchivedJobs.push(
              ...(allArchivedJobs.find(item => item.id === key) ? [] : [deptLabel]),
              ...(projectKey !== 'null' && !allArchivedJobs.find(item => item.id === `${key}-${projectKey}`)
                ? [{
                    id: `${key}-${projectKey}`,
                    title: getProjectTitle(projectKey),
                    type: 'sublabel'
                  }]
                : []
              ),
              {
                ...jobLabel,
                type: 'item',
                onSelect: async () => {
                  navigate(jobLabel.href)
                }
              }
            )

            // We only want to add a separator if there are more departments in the list that have archived jobs
            const isLastJobInDepartment = projectJobs.indexOf(job) === projectJobs.length - 1
            if (isLastJobInDepartment && key !== departments[departments.length - 1]) {
              const nextDepartmentWithArchivedJobs = departments.slice(departments.indexOf(key) + 1).find(nextKey => {
                return Object.values(jobsByDepartment[nextKey] ?? {})
                  .flatMap(projectJobs => projectJobs)
                  .some(job => job.deleted)
              })

              if (nextDepartmentWithArchivedJobs) {
                allArchivedJobs.push({
                  id: crypto.randomUUID(),
                  title: `Separator (${departmentJobs.null?.[0]?.department?.name})`,
                  type: 'separator'
                })
              }
            }
          } else {
            jobLabels.push({
              ...jobLabel,
              type: 'item',
              onSelect: async () => {
                navigate(jobLabel.href)
              }
            })
          }
        }

        if (jobLabels.length > 0) {
          result.push(...jobLabels)
        }
      }

      // We only want to add a separator if
      // * It is not the last department and it has active jobs
      // * Next department in the list also has some active jobs
      if (key !== departments[departments.length - 1] && departmentHasActiveJobs) {
        const nextDepartmentWithActiveJobs = departments.slice(departments.indexOf(key) + 1).find(nextKey => {
          return Object.values(jobsByDepartment[nextKey] ?? {})
            .flatMap(projectJobs => projectJobs)
            .some(job => !job.deleted)
        })

        if (nextDepartmentWithActiveJobs) {
          result.push({
            id: crypto.randomUUID(),
            title: `Separator (${departmentJobs.null?.[0]?.department?.name})`,
            type: 'separator'
          })
        }
      }
    }

    if (allArchivedJobs.length > 0) {
      result.push(
        {
          id: 'archived-job-separator',
          title: 'ArchivedJobSeparator',
          type: 'separator'
        },
        {
          id: 'archived-jobs',
          title: 'Archived jobs',
          type: 'item',
          icon: Icons.archive,
          trailingIcon: <ChevronRight size="14" />,
          variant: 'muted',
          subitems: allArchivedJobs
        },
        {
          id: 'archived-jobs-separator',
          title: 'ArchivedJobsSeparator',
          type: 'separator'
        }
      )
    }

    if (numberOfActiveJobs <= JOBS_COUNT_WHEN_TO_MAKE_CREATE_BUTTON_STICKY) {
      result.push(
        ...(!allArchivedJobs.length
          ? [{
              id: crypto.randomUUID(),
              title: 'NewJobSeparator2',
              type: 'separator'
            }]
          : []
        ),
        {
          id: 'create-new-job',
          title: 'Create new job',
          onSelect: handleCreateNewJobClick,
          type: 'item',
          icon: Icons.plus,
          variant: 'tint'
        }
      )
    }

    if (result.length > 0) {
      const lastItem = result[result.length - 1]
      if ('type' in lastItem && lastItem.type === 'separator') {
        result.pop()
      }
    }

    return result
  }, [
    handleCreateNewJobClick,
    jobId,
    jobStats,
    jobsByDepartment,
    navigate,
    numberOfActiveJobs,
    org,
    orgLogoUrl,
    pathname,
    projects
  ])

  if (isNil(org)) {
    return <></>
  }

  const selectedJob = jobs.find((job) => job.id === jobId)

  return (
    <Flex $direction="column" $gap={6}>
      <S.OrgJobsDropdown $isCollapsed={isCollapsed}>
        <Dropdown
          trigger={
            <Trigger
              isCollapsed={isCollapsed}
              isLoading={isPending}
              selectedJob={selectedJob}
              logoUrl={orgLogoUrl}
            />
          }
          menuPosition="start"
          items={jobDropdownItems}
          stickyItem={
            numberOfActiveJobs >= JOBS_COUNT_WHEN_TO_MAKE_CREATE_BUTTON_STICKY + 1
              ? (
                  {
                    id: 'create-new-job',
                    title: 'Create new job',
                    onSelect: handleCreateNewJobClick,
                    type: 'item',
                    icon: Icons.plus,
                    variant: 'tint'
                  }
                )
              : undefined
          }
          selectedValue={jobId}
          $maxHeight="38rem"
          $minWidth='14.5rem'
          $maxWidth='20rem'
          allowFilter={numberOfTotalJobs >= 8}
        />
      </S.OrgJobsDropdown>
    </Flex>
  )
}
