import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Button } from 'src/components/primitives/button'
import * as S from './automation-settings.styled'
import { useEffect, useState } from 'react'
import { When } from '../when'
import { Toggle } from 'src/components/primitives/toggle'

interface Setting {
  heading: string
  description: string
  isActive: boolean
  disabled?: boolean
  details?: React.ReactNode
  onToggle?: () => void
}

export const SettingsCard = ({
  heading,
  description,
  details,
  isActive,
  disabled = false,
  onToggle
}: Setting): JSX.Element => {
  return (
    <S.SettingsCard $isActive={isActive}>
      <S.CardMain>
        <Flex $gap={6} $direction="column">
          <Caption size="MD" $color="fgPrimary">
            {heading}
          </Caption>
          <Paragraph size="XS" $color="fgSecondary">
            {description}
          </Paragraph>
        </Flex>
      </S.CardMain>
      <S.Action>
        <Button
          $variant={isActive ? 'outline' : 'fill'}
          $colorTheme={isActive ? 'muted' : 'tint'}
          leadingIcon={isActive ? 'pause-circle' : undefined}
          $height={32}
          $width="84px"
          $align="center"
          onClick={() => {
            onToggle?.()
          }}
          disabled={disabled}
        >
          {isActive ? 'Pause' : 'Enable'}
        </Button>
      </S.Action>
      {details && <S.Details>{details}</S.Details>}
    </S.SettingsCard>
  )
}

interface DetailsBlockProps {
  columnSpan?: number
  label: string
  hiddenLabel?: boolean
  children: React.ReactNode
  defaultExpanded?: boolean
  onExpand?: (enabled: boolean) => void
  isActive: boolean
}

export const DetailsBlock = ({
  label,
  hiddenLabel = false,
  children,
  columnSpan = 1,
  defaultExpanded,
  onExpand
}: DetailsBlockProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isExpandable = !!onExpand

  useEffect(() => {
    if (defaultExpanded) {
      setIsExpanded(defaultExpanded)
    }
  }, [defaultExpanded])

  return (
    <S.DetailsBlock $columnSpan={columnSpan}>
      <When condition={isExpandable}>
        <Flex $align="center" $justify="space-between">
          <Caption size="SM" $color="fgSecondary">
            {label}
          </Caption>
          <Toggle
            name={label}
            checked={isExpanded}
            onChange={() => {
              setIsExpanded(!isExpanded)
            }}
            $toggleSize={16}
          />
        </Flex>
        <When condition={isExpanded}>{children}</When>
      </When>
      <When condition={!isExpandable}>
        <When condition={!hiddenLabel}>
          <Caption size="SM" $color="fgSecondary">
            {label}
          </Caption>
        </When>
        {children}
      </When>
    </S.DetailsBlock>
  )
}
