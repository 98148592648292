import type { Color, Shadow, Spacing } from 'src/styles/theme/types'
import * as S from './box.styled'

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type Align = 'flex-start' | 'center' | 'flex-end'
type Justify = 'flex-start' | 'center' | 'flex-end' | 'space-between'

export interface BoxStyleProps {
  $display?: 'block' | 'inline-block' | 'flex' | 'inline-flex'
  $width?: 'auto' | string
  $height?: string | Spacing
  $flexGrow?: 0 | 1
  $flexShrink?: 0 | 1
  $flexBasis?: string | number
  $flexDirection?: FlexDirection
  $flex?: string
  $align?: Align
  $justify?: Justify
  $gap?: Spacing
  $gridColumn?: string
  $background?: Color | 'transparent'
  $border?: Color | 'none'
  $borderRadius?: `${string}px`
  $shadow?: Shadow
  $padding?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
  $margin?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
}

interface BoxProps extends BoxStyleProps {
  children: React.ReactNode
  as?: 'div' | 'section' | 'header'
}

export const Box = ({
  $display = 'inline-block',
  $width = 'auto',
  $height = 'auto',
  $flexDirection = 'row',
  $align = 'flex-start',
  $justify = 'flex-start',
  $gap = 0,
  $flexGrow = 0,
  $flexShrink = 0,
  $flexBasis = '100%',
  $flex,
  $gridColumn = 'span 1',
  $background = 'transparent',
  $border = 'none',
  $borderRadius = '0px',
  $shadow,
  $padding = { top: 0, right: 0, bottom: 0, left: 0 },
  $margin = { top: 0, right: 0, bottom: 0, left: 0 },
  children,
  as = 'div'
}: BoxProps): JSX.Element => {
  return (
    <S.Box
      as={as}
      $display={$display}
      $flexGrow={$flexGrow}
      $flexShrink={$flexShrink}
      $flexBasis={$flexBasis}
      $flex={$flex}
      $flexDirection={$flexDirection}
      $align={$align}
      $justify={$justify}
      $gap={$gap}
      $width={$width}
      $height={$height}
      $gridColumn={$gridColumn}
      $background={$background}
      $border={$border}
      $borderRadius={$borderRadius}
      $padding={$padding}
      $margin={$margin}
      $shadow={$shadow}
    >
      {children}
    </S.Box>
  )
}
