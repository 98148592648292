import * as S from './ready-to-send-message.styled'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { Avatar } from 'src/components/primitives/avatar'
import { Editor } from '../editor'
import { useMemo, useState } from 'react'
import type { EmailAccount, OrgUser } from 'src/libs/api/backend/users'
import { When } from '../when'
import { Badge } from 'src/components/primitives/badge'
import { Tooltip } from 'src/components/primitives/tooltip'
import { isNil } from 'lodash'
import { CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'
import { useSetAtom } from 'jotai'
import { openDialogAtom, DialogId } from 'src/stores/dialogs'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useSession } from 'src/hooks/use-session'
import { ReadyToSendCard } from '../ready-to-send-card'
import type { ReadyToSendCardProps } from '../ready-to-send-card'

export interface HandleSendMessageArgs {
  candidateSequenceStepMessageReviewId: string
  subject: string
  body: string
}

interface ReadyToSendProps extends ReadyToSendCardProps {
  usersByUserId: Record<string, OrgUser>
  handleSendMessage: (args: HandleSendMessageArgs) => void
  sendDisabled?: boolean
  currentUserId?: string
  reconnect: (selectedEmailAccount: EmailAccount | undefined) => void
}

export const ReadyToSendMessage = ({
  candidateSequenceStepMessageReview,
  usersByUserId,
  handleToggleFavoriteStatus,
  handleSendMessage,
  sendDisabled,
  currentUserId,
  reconnect,
  openCandidateDialog
}: ReadyToSendProps): JSX.Element => {
  const { candidateJob, sequenceStep, body, compiledBody, subject, compiledSubject } = candidateSequenceStepMessageReview
  const initialMessageBody = useMemo(() => body ?? compiledBody ?? undefined, [body, compiledBody])
  const [messageBody, setMessageBody] = useState<string | undefined>(initialMessageBody)
  const [emailSubject, setEmailSubject] = useState<string | undefined>(subject ?? compiledSubject ?? undefined)

  const sendingUser = useMemo(() => {
    return usersByUserId[sequenceStep.sendingUserId] ?? {}
  }, [usersByUserId, sequenceStep.sendingUserId])
  const openDialog = useSetAtom(openDialogAtom)
  const { featureFlags } = useSession()

  const sendingUserAccount = useMemo(() => {
    return (sendingUser.emailAccounts ?? []).find((emailAccount) => emailAccount.id === sequenceStep.sendingEmailAccountId)
  }, [sendingUser.emailAccounts, sequenceStep.sendingEmailAccountId])

  const sendNowDisabled = useMemo(() => {
    return (
      !emailSubject ||
      !messageBody ||
      !sendingUserAccount?.currentUserHasSendAsPermission ||
      !sendingUserAccount?.hasAccessToken ||
      sendDisabled
    )
  }, [emailSubject, messageBody, sendingUserAccount, sendDisabled])

  const readyToSendTooltip = useMemo(() => {
    if (!sendingUserAccount?.currentUserHasSendAsPermission) {
      return 'Your teammate needs to give you permission to this account in order to send.'
    }
    if (!sendingUserAccount?.hasAccessToken) {
      return 'This email account is not connected'
    }
    if (!emailSubject) {
      return 'Please provide a subject line'
    }
    if (!messageBody) {
      return 'Please provide an email message'
    }

    if (sendDisabled) {
      return 'Outreach is paused, please enabled outreach to start sending emails'
    }

    return undefined
  }, [messageBody, emailSubject, sendDisabled, sendingUserAccount?.currentUserHasSendAsPermission, sendingUserAccount?.hasAccessToken])

  const candidateHasEmailError = useMemo(() => {
    const status = candidateSequenceStepMessageReview.candidateJob.statusDisplay?.status
    return status === CandidateJobStatus.SEARCHING_FOR_EMAIL ||
      status === CandidateJobStatus.EMAIL_NOT_FOUND ||
      status === CandidateJobStatus.MISSING_EMAIL
  }, [candidateSequenceStepMessageReview.candidateJob.statusDisplay])

  return (
    <ReadyToSendCard
      candidateSequenceStepMessageReview={candidateSequenceStepMessageReview}
      handleToggleFavoriteStatus={handleToggleFavoriteStatus}
      openCandidateDialog={openCandidateDialog}
    >
      <S.ReadyToSendSender>
        <Caption size='XS' $color='fgSecondary' $fontWeight={400}>From</Caption>
        <Flex $align="center" $gap={8}>
          <Button
            disabled={true}
            $fontSize={12}
            $height={24}
            trailingIcon={Icons.chevronsUpDownSmall}
            $variant='outline'
          >
            <Avatar
              $size={16}
              $shape='circle'
              $type="photo"
              initials={sendingUser?.name}
              photoUrl={sendingUser?.profilePhotoUrl}
              fallbackAvatar="random"
            />
            {sendingUserAccount?.email ?? ''}
          </Button>
          <When condition={!isNil(sendingUserAccount) && !sendingUserAccount.hasAccessToken}>
            <Badge
              $variant="negativeLight"
              leadingIcon="alert-triangle"
              $transform="none"
              $fontSize={12}
              $height={24}
              $padding="casual"
            >
              Email account not connected
            </Badge>
            <Button
              $height={24}
              $variant="fill"
              $colorTheme="tint"
              leadingIcon="refresh-cw"
              $fontSize={12}
              ariaLabel='Reconnect email account'
              onClick={() => {
                reconnect(sendingUserAccount)
              }}
              disabled={sendingUserAccount?.userId !== currentUserId}
              tooltip={{
                text: sendingUserAccount?.userId === currentUserId ? undefined : 'Have your teammate reconnect this email account or switch to a different sender',
                position: 'top'
              }}
            >
              Reconnect
            </Button>
          </When>
        </Flex>
      </S.ReadyToSendSender>
      <S.ReadyToSendSubject>
        <Caption size='XS' $color='fgSecondary' $fontWeight={400}>Subject</Caption>
        <input
          value={emailSubject}
          placeholder='Your subject line'
          onChange={(event) => {
            setEmailSubject(event.target.value)
          }}
        />
      </S.ReadyToSendSubject>
      <S.ReadyToSendBody>
        <Editor
          isEditable={true}
          placeholder="Compose a message…"
          $editorHeight='fit-content'
          contentType='html'
          initialContent={initialMessageBody}
          content={messageBody}
          onDataChanged={(data) => {
            setMessageBody(data)
          }}
          toolbar={
            <S.ReadyToSendToolbar>
              <Flex $align="center" $gap={12}>
                <When condition={!candidateHasEmailError}>
                  <Tooltip
                    trigger={
                      <span>
                        <Button
                          $fontSize={12}
                          $height={24}
                          $variant='flat'
                          $colorTheme='tint'
                          disabled={sendNowDisabled}
                          onClick={() => {
                            handleSendMessage({
                              candidateSequenceStepMessageReviewId: candidateSequenceStepMessageReview.id,
                              subject: emailSubject ?? '',
                              body: messageBody ?? ''
                            })
                          }}
                        >
                          Queue send
                        </Button>
                      </span>
                    }
                    triggerDisabled={sendNowDisabled}
                    disabled={!sendNowDisabled}
                  >
                    {readyToSendTooltip}
                  </Tooltip>
                </When>
                <When condition={candidateHasEmailError}>
                  <Button
                    $fontSize={12}
                    $height={24}
                    $variant='flat'
                    $colorTheme='tint'
                    leadingIcon={Icons.mailPlus}
                    onClick={() => { openDialog({ id: DialogId.EDIT_CANDIDATE, payload: candidateJob }) }}
                  >
                    Add email address
                  </Button>
                  <When condition={!!featureFlags && featureFlags?.includes(FeatureFlags.LINKEDIN_CONNECTION)}>
                    <Button
                      $fontSize={12}
                      $height={24}
                      $variant='flat'
                      $colorTheme='tint'
                      leadingIcon={Icons.linkedinOutline}
                      onClick={() => {
                        handleSendMessage({
                          candidateSequenceStepMessageReviewId: candidateSequenceStepMessageReview.id,
                          subject: emailSubject ?? '',
                          body: messageBody ?? ''
                        })
                      }}
                    >
                      Send as LinkedIn Message
                    </Button>
                  </When>
                </When>
              </Flex>
            </S.ReadyToSendToolbar>
          }
        />
      </S.ReadyToSendBody>
    </ReadyToSendCard>
  )
}
