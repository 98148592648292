import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import type { CandidateJob } from 'src/libs/api/backend/candidate_jobs'
import { buildCustomOutreach } from 'src/libs/api/backend/custom_outreach'
import type { BuildCustomOutreach } from 'src/libs/api/backend/custom_outreach'
import { useEffect, useRef } from 'react'

interface Args {
  candidateJobId: CandidateJob['id']
  isDialogOpen: boolean
}

export const useBuildCustomOutreach = ({ candidateJobId, isDialogOpen }: Args): UseQueryResult<BuildCustomOutreach> => {
  const dialogOpenedTimestamp = useRef<number>(0)

  // A bit weird maybe, but as sequence step details can change anytime (e.g. different senders)
  // we really want to make sure to refetch every time the dialog opens, hence the timestamp as query key.
  useEffect(() => {
    if (isDialogOpen) {
      dialogOpenedTimestamp.current = Date.now()
    }
  }, [isDialogOpen])

  return useQuery({
    queryKey: [queryKeys.customOutreach, candidateJobId, dialogOpenedTimestamp.current],
    queryFn: async () => await buildCustomOutreach({ candidateJobId }),
    refetchOnMount: 'always',
    enabled: !!isDialogOpen && !!candidateJobId,
    staleTime: 0,
    gcTime: 0
  })
}
