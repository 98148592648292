import type { Spacing } from './types'

export const spacing: Record<Spacing, string | number> = {
  0: 0,
  1: '0.0625rem', // 1px
  2: '0.125rem', // 2px
  4: '0.25rem', // 4px
  6: '0.375rem', // 6px
  8: '0.5rem', // 8px
  10: '0.625rem', // 10px
  12: '0.75rem', // 12px
  14: '0.875rem',
  16: '1rem', // 16px
  18: '1.125rem', // 18px
  20: '1.25rem', // 20px
  22: '1.375rem', // 22px
  24: '1.5rem', // 24px
  26: '1.625rem', // 26px
  28: '1.75rem', // 28px
  30: '1.875rem', // 30px
  32: '2rem', // 32px
  36: '2.25rem', // 36px
  40: '2.5rem', // 40px
  48: '3rem', // 48px
  56: '3.5rem', // 56px
  64: '3.8125rem',
  72: '4.5rem', // 72px
  80: '5rem',
  160: '10rem',
  9999: '9999px' // for full border-radius
}
