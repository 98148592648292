import { PriceInterval } from 'src/libs/api/backend/billing'
import type { BillingProduct, CustomerSubscription } from 'src/libs/api/backend/billing'
import * as S from './subscription-settings.styled'
import { Flex } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { NavLink } from 'react-router-dom'
import { Icon, Icons } from 'src/components/primitives/icon'
import { formatCurrency } from 'src/libs/currency'
import { Box } from 'src/components/primitives/box'
import { getProductDetails } from './billing-utils'

interface PlanProps {
  professionalProduct: BillingProduct
  customersSubscriptionsByProductId: Record<string, CustomerSubscription>
  interval: PriceInterval
}

export const Plan = ({ professionalProduct, customersSubscriptionsByProductId, interval }: PlanProps): JSX.Element | null => {
  const {
    monthlyPrice,
    productCost,
    productInterval,
    productSubscribed,
    perUsage
  } = getProductDetails({
    product: professionalProduct,
    customersSubscriptionsByProductId,
    interval
  })

  return (
    <Box
      $display='flex'
      $align='center'
      $gap={24}
      $padding={{ top: 16, right: 24, bottom: 16, left: 24 }}
      $width='100%'
      $borderRadius='6px'
      $background='bgPrimary'
      $shadow='sm'
    >
      <Flex $direction='column' $flex='1 1 auto' $width='fit-content' $gap={4}>
        <Caption size='MD'>{professionalProduct.name}</Caption>
        <NavLink to='https://www.pin.com/#pricing' target='_blank' end={true}>
          <S.Link>
            Plan details
            <Icon name={Icons.externalLink} color='fgTertiary' />
          </S.Link>
        </NavLink>
      </Flex>
      {interval === PriceInterval.year && !productSubscribed && (
        <S.DiscountedTag>
          <Caption size='2XS' $color='positiveFg'>10% OFF</Caption>
        </S.DiscountedTag>
      )}
      <Flex $gap={12} $align='center' $width='fit-content'>
        {interval === PriceInterval.year && !productSubscribed && (
          <S.OriginalPrice>
            <Caption size='XL' $fontWeight={400} $color='fgTertiary'>{formatCurrency(Number(monthlyPrice?.unitAmount ?? 0))}</Caption>
          </S.OriginalPrice>
        )}
        <Caption size='XL'>{formatCurrency(Number(productCost))}</Caption>
        <Flex $direction='column' $align='flex-start' $width='80px'>
          <Paragraph size='XS'>{perUsage}</Paragraph>
          <Paragraph size='XS'>per {productInterval}</Paragraph>
        </Flex>
      </Flex>
      {productSubscribed && (
        <S.CurrentTag>Current</S.CurrentTag>
      )}
    </Box>
  )
}
