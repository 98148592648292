import { useChannel } from 'ably/react'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import { CandidatePhoneNumberLookupStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'

interface UseCandidateDetailsChannelProps {
  candidateDetailsChannel: string
  candidateId?: string
  candidateJobId: string
  setInProgress: (inProgress: boolean) => void
}

export const useInvalidateCandidateAfterPhoneLookup = ({
  candidateDetailsChannel,
  candidateId,
  candidateJobId,
  setInProgress
}: UseCandidateDetailsChannelProps) => {
  useChannel(
    { channelName: candidateDetailsChannel, skip: !candidateDetailsChannel },
    EVENT_TYPE.CANDIDATES_UPDATE,
    (message) => {
      const { ids, phoneNumberLookupStage } = message.data
      const idsSet = new Set((ids as string[]) ?? [])

      if (candidateId && idsSet.has(candidateId)) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateActivities, candidateId]
        })
      }

      if (phoneNumberLookupStage) {
        const inProgress = phoneNumberLookupStage === CandidatePhoneNumberLookupStage.IN_PROGRESS
        setInProgress(inProgress)

        if (phoneNumberLookupStage === CandidatePhoneNumberLookupStage.FOUND) {
          void queryClient.invalidateQueries({
            queryKey: [queryKeys.customerSubscriptions]
          })
        }

        void queryClient.setQueryData([queryKeys.candidateJob, candidateJobId], (oldData: CandidateJobExpanded) => {
          return {
            ...oldData,
            candidate: { ...oldData.candidate, phoneNumberLookupStage }
          }
        })

        if (!inProgress) {
          void queryClient.invalidateQueries({
            queryKey: [queryKeys.candidateJob, candidateJobId]
          })
        }
      }
    }
  )
}
